import axiosInstance from "../config/axios/axios.config";

export const getMWStatsApi = (query) => {
  return axiosInstance.get(`/analytics?${query}`);
};

export const getEmployeeMetrics = () => {
  return axiosInstance.get(`/analytics/employee`);
};

export const getMroChartDataApi = (query) => {
  return axiosInstance.get(`/analytics/mro-chart-data`, { params: query });
};

//Spare part consupmtion service

export const getInventoryStats = () => {
  return axiosInstance.get(`/analytics/inventory-stats`);
};
export const getInventoryCategoryStats = () => {
  return axiosInstance.get(`/analytics/inventory-category-stats`);
};

export const getInventoryConsumptionStats = (id, query) => {
  return axiosInstance.get(`/analytics/inventory-consumption-stats/${id}`, {
    params: query,
  });
};

export const getStoreMapData = (query,download) => {
  return axiosInstance.get(`/inventory/store-map/all`,{params: query,...(download && { responseType: "blob"}),});
};
