import React, { useContext, useEffect, useRef, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Empty,
  Flex,
  Image,
  Input,
  Pagination,
  Popconfirm,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
  message,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import AddEditInventory from "./AddEditInventory";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";
import {
  deleteInventoryApi,
  getInventoryCategoriesListApi,
  getInventoryList,
} from "../../services/inventory.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  PAGESIZE,
  createInventory,
  deleteInventory,
  editInventory,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import {
  debounce,
  deleteCond,
  editCond,
  formatNumber,
  scrollToTopTable,
  tableComponents,
} from "../../helpers/utility";
import ImportInventory from "./ImportInventory";
import "./inventory.css";
import locationIcon from "../../assets/images/inventory/locationIcon.svg";

const InventoryList = ({
  allStores,
  viewType,
  selectedStore,
  setTotalInventories,
  refresh,
  setRefresh,
}) => {
  const tableRef = useRef(null);
  const { userDetails } = useContext(UserContext);
  const [inventories, setInventories] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [currInventory, setCurrInventory] = useState(null);
  // const [refresh, setRefresh] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [showImportModal, setShowImportModal] = useState(false);
  const [selectedStoreDetails, setSelectedStoreDetails] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  useEffect(() => {
    fetchCategoriesList();
  }, []);
  useEffect(() => {
    setSelectedStoreDetails(
      allStores.find((store) => store?._id === selectedStore)
    );
  }, [selectedStore, allStores]);
  // const [selectedSorter, setSelectedSorter] = useState(undefined);
  // const [filters, setFilters] = useState({
  //   typeObj: [],
  //   location: undefined,
  // });
  const fetchCategoriesList = async () => {
    try {
      const resp = await getInventoryCategoriesListApi();
      setCategories(
        resp?.data?.map((category) => ({
          label: category?.name,
          value: category?._id,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchInventoryList = async () => {
    let query = {
      page: pageNumber,
    };
    if (searchValue) {
      query = { ...query, name: searchValue };
    }
    query.category = selectedCategory;
    // filters?.typeObj?.length > 0 && (query.typeObj = filters?.typeObj);
    // filters?.location !== "" && (query.locationId = filters?.location);
    // if (selectedSorter) {
    //   query.sort_by = selectedSorter;
    //   query.sort_order = "desc";
    // }
    if (viewType && viewType !== "items") {
      query.store = selectedStore;
    }

    setLoading(true);
    try {
      const res = await getInventoryList(query);
      setInventories(res?.data);
      setTotalInventories(res?.data?.totalInventoryItems);
    } catch (error) {
      console.error("Error while fetching data:", error);
      setInventories(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchInventoryList();
      scrollToTopTable(tableRef);
    };
    fetchData();
  }, [pageNumber, refresh, searchValue, selectedCategory]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [selectedStore]);

  const refreshPage = (source = "") => {
    if (source === "search" && pageNumber > 1) {
      setPageNumber(1);
    } else if (
      source === "delete" &&
      inventories?.inventories?.length === 1 &&
      pageNumber > 1
    ) {
      setPageNumber((prev) => prev - 1);
    } else {
      setRefresh((prev) => !prev);
    }
  };

  const deleteInventoryFunc = async (id) => {
    try {
      const resp = await deleteInventoryApi(id);
      if (resp) {
        message.success(resp?.data?.message);
        refreshPage("delete");
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
    if (
      e?.target?.value?.trim()?.length === 0 ||
      e?.target?.value?.trim()?.length > 1
    ) {
      refreshPage("search");
    }
  };

  const tableColumns = [
    {
      title: "Spare part code",
      dataIndex: "code",
      key: "code",
      fixed: "left",
      width: viewType === "stores" ? 250 : 150,
      render: (val, record) => (
        <Space>
          {val}
          {(Number(record?.available_quantity) <= 0 ||
            Number(record?.available_quantity) <
              Number(record?.low_stock_threshold)) && (
            <Tag className={tagStyles.smTag} color="red">
              Low Stock
            </Tag>
          )}
        </Space>
      ),
    },

    {
      title: "Spare part name",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 200,
      render: (val) => (val?.name ? val?.name : "-"),
    },
    {
      title: "High value",
      dataIndex: "high_value",
      key: "high_value",
      width: 120,
      render: (val) =>
        val ? <Tag color="green">Yes</Tag> : <Tag color="blue">No</Tag>,
    },
    ...(viewType && viewType !== "items"
      ? [
          {
            title: "Available quantity",
            dataIndex: "available_quantity",
            key: "available_quantity",
            width: 150,
            render: (val) => (val || val === 0 ? val : "-"),
          },
          {
            title: "Low stock threshold",
            dataIndex: "low_stock_threshold",
            key: "low_stock_threshold",
            width: 150,
            render: (val) => (val || val === 0 ? val : "-"),
          },
        ]
      : [
          {
            title: "Total quantity",
            dataIndex: "total_available_quantity",
            key: "total_available_quantity",
            width: 130,
            render: (val) => (val || val === 0 ? (val < 0 ? 0 : val) : "-"),
          },
        ]),

    ,
    {
      title: "Cost per piece",
      dataIndex: "cost",
      key: "cost",
      width: 150,
      render: (val) =>
        val || val === 0
          ? `₹ ${formatNumber(Number(val?.toFixed(2)) || 0)}`
          : "-",
    },
    ...(
      editInventory.includes(userDetails?.activeOrg?.role)
        ? [{
            title: "Actions",
            dataIndex: "action",
            key: "action",
            width: 120,
            fixed: "right",
            render: (_, record) => (
              <Flex gap={12} align="center" justify="flex-start">
                {editCond(
                  editInventory,
                  record?.creator,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) && (
                  <EditOutlined
                    onClick={() => {
                      setCurrInventory(record);
                      setShowDrawer(true);
                    }}
                  />
                )}
                
                {/* {deleteCond(
                  deleteInventory,
                  record?.creator,
                  userDetails?.activeOrg?.role,
                  userDetails?._id
                ) &&
                  (!viewType || viewType === "items") && (
                    <Popconfirm
                      icon={null}
                      description="Are you sure to delete this inventory?"
                      onConfirm={(e) => {
                        e.stopPropagation();
                        deleteInventoryFunc(record?._id);
                      }}
                      onCancel={(e) => e.stopPropagation()}
                    >
                      <DeleteOutlined />
                    </Popconfirm>
                  )} */}
              </Flex>
            ),
          }]
        : []
    )
    
  ];

  return (
    <>
      <Row gutter={[24, 18]} style={{ marginTop: 0 }}>
        <Col span={24}>
          <Table
            ref={tableRef}
            title={() => (
              <div
                align="center"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Flex
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {viewType === "stores" ? (
                    <>
                      <Space>
                        <Tag
                          className={tagStyles.mdTag}
                          color="blue"
                          icon={
                            <Image
                              src={locationIcon}
                              width={12}
                              preview={false}
                            />
                          }
                        >
                          {selectedStoreDetails?.location?.name
                            .slice(0, 1)
                            .toUpperCase() +
                            selectedStoreDetails?.location?.name.slice(1)}
                        </Tag>
                      </Space>
                      <Space>
                        <Tag className={tagStyles.mdTag} color="green">
                          High Value:{" "}
                          {selectedStoreDetails?.highValuedInventoryCount || 0}
                        </Tag>
                        <Tag className={tagStyles.mdTag} color="volcano">
                          Low Value:{" "}
                          {selectedStoreDetails?.lowValuedInventoryCount || 0}
                        </Tag>
                        <Tag className={tagStyles.mdTag} color="purple">
                          Total Cost: ₹{" "}
                          {formatNumber(
                            selectedStoreDetails?.totalInventoryCost || 0
                          )}
                        </Tag>
                      </Space>
                    </>
                  ) : (
                      <Flex
                        style={{
                        width: "100%",
                        justifyContent: "flex-end",
                        marginRight: "10px",
                      }}
                    >
                      <Select
                        style={{ width: 200, textAlign: "left" }}
                        size="large"
                        placeholder="Select category"
                        options={categories}
                        onChange={(value) => {
                          setSelectedCategory(value);
                        }}
                        onClear={() => {
                          setSelectedCategory("");
                        }}
                        allowClear
                        value={selectedCategory}
                      />
                    </Flex>
                  )}
                </Flex>
                <Space>
                  <div style={{ position: "relative" }}>
                    <Input.Search
                      size="large"
                      onChange={debounce(handleSearch, 500)}
                      placeholder="Search by inventory name or code"
                      prefix={
                        <SearchOutlined
                          style={{ fontSize: 14, color: "#667085" }}
                        />
                      }
                      style={{ width: "320px", fontSize: 14 }}
                      className="custom-input-search"
                    />
                  </div>
                  {createInventory.includes(userDetails?.activeOrg?.role) &&
                    (viewType == "" || viewType == "items") && (
                      <>
                        <Button
                          id="create-mro"
                          type="primary"
                          className={btnStyles.mdBtn}
                          onClick={() => {
                            setCurrInventory(null);
                            setShowDrawer(true);
                          }}
                        >
                          + Add Inventory
                        </Button>
                        <Button
                          id="create-mro"
                          type="primary"
                          className={btnStyles.mdBtn}
                          onClick={() => {
                            setShowImportModal(true);
                          }}
                        >
                          Import Inventory
                        </Button>
                      </>
                    )}
                  {showImportModal && (
                    <ImportInventory
                      stores={allStores}
                      showImportModal={showImportModal}
                      setShowImportModal={setShowImportModal}
                      reloadPage={refreshPage}
                      type="inventory"
                    />
                  )}
                </Space>
              </div>
            )}
            scroll={{
              x: 1200,
              y: "65vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No inventory found</Typography>}
                />
              ),
            }}
            pagination={false}
            bordered
            columns={tableColumns}
            dataSource={inventories?.inventoryItems || []}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            components={tableComponents}
          />
        </Col>
        {inventories?.totalInventoryItems > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={inventories?.totalInventoryItems}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
      {showDrawer && (
        <AddEditInventory
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          currInventory={currInventory}
          setRefresh={setRefresh}
          viewType={viewType}
          selectedStore={selectedStore}
        />
      )}
    </>
  );
};

export default InventoryList;
