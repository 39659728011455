import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Empty,
  Image,
  Input,
  message,
  Pagination,
  Result,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import {
  getAllAssetsForListViewApi,
  getAllAssetsForListDownloadApi,
  getAssetBreakdownDownloadApi,
} from "../../services/assets.services";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserProvider";
import {
  convertToUTCFormat,
  debounce,
  formatHours,
  formatNumber,
  tableComponents,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { PAGESIZE } from "../../constants/defaultKeys";
import sortIcon from "../../assets/images/sortIcon1.svg";
import { CopyOutlined, SearchOutlined } from "@ant-design/icons";
import { getTaskTypeApi } from "../../services/organisation.services";
import dayjs from "dayjs";
import "./Dashboard.css";

const { RangePicker } = DatePicker;


const AssetsTableView = ({}) => {
  const [assets, setAssets] = useState([]);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState(undefined);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(2, "month"),
    end_date: dayjs(),
  });
  

  // const [mroTypes, setMroTypes] = useState([]);
  // const fetchMROTypes = async () => {
  //   try {
  //     const resp = await getTaskTypeApi();
  //     const transformedData = resp.data.map((item) => ({
  //       label: item.name,
  //       value: item._id,
  //     }));
  //     setMroTypes(transformedData);
  //   } catch (error) {
  //     console.error("Error fetching MRO types:", error);
  //   }
  // };

  useEffect(() => {
    // fetchMROTypes();
    let params = {
      page: pageNumber,
    };
    if (searchValue) {
      params.name = searchValue;
    }
    if (selectedSorter) {
      params.sort_by = selectedSorter;
      params.sort_order = "desc";
    }

    if (dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        dateFilter.start_date.startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await getAllAssetsForListViewApi(params);
        setAssets(res?.data);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [pageNumber, refresh]);

  useEffect(() => {
    if (pageNumber === 1) {
      setRefresh((prev) => !prev);
    } else {
      setPageNumber(1);
    }
  }, [searchValue, selectedSorter, dateFilter]);

  const handleAssetsDownload = async () => {
    let query = { download: true };
    if (dateFilter?.start_date) {
      query.start_date = convertToUTCFormat(
        dateFilter.start_date.startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      query.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
    }

    try {
      setDownloading(true);

      const res = await getAllAssetsForListDownloadApi(query);

      const url = window.URL.createObjectURL(new Blob([res?.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = "assets.xlsx";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (err) {
    } finally {
      setDownloading(false);
    }
  };

  const columns = [
    {
      title: "Asset code",
      dataIndex: "code",
      key: "code",
      width: 110,
      fixed: "left",
      render: (val, record) =>
        val ? (
          <Typography
            style={{
              color: "#1677ff",
              cursor: "pointer",
              textDecoration: "none",
            }}
            onMouseOver={(e) => (e.target.style.textDecoration = "underline")}
            onMouseOut={(e) => (e.target.style.textDecoration = "none")}
            onClick={() => {
              navigate(`/assets/${record?._id}`, {
                state: { source: "/assets?type=table" },
              });
            }}
          >
            {val}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: "Asset name",
      dataIndex: "name",
      key: "name",
      width: 310,
      fixed: "left",
      render: (val) => <div>{val}</div>,
    },
    {
      title: "Asset location",
      dataIndex: "location",
      key: "location",
      width: 200,
      render: (val) => <>{val?.name}</>,
    },
    {
      title: "Asset downtime",
      dataIndex: "totalDowntime",
      key: "totalDowntime",
      width: 180,
      render: (val) => {
        let durationObj = formatHours(val ? val : 0);
        return (
          <Tag className={tagStyles.smRndTag} color="error">
            {durationObj?.days ? `${durationObj?.days} days ` : ""}
            {`${durationObj?.hours} hrs`}
            {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
          </Tag>
        );
      },
    },
    {
      title: "MROs created",
      dataIndex: "mroo",
      key: "mroo",
      children: [
        {
          title: "Mechanical",
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 120,
          render: (val) => (val?.Mechanical ? val?.Mechanical : 0),
        },
        {
          title: "Electrical",
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 120,
          render: (val) => (val?.Electrical ? val?.Electrical : 0),
        },
        {
          title: "All Others",
          dataIndex: "mroTypeCounts",
          key: "mroTypeCounts",
          width: 120,
          render: (val) => (val?.Others ? val?.Others : 0),
        },
      ],
    },
    {
      title: "WOs created",
      dataIndex: "workorderCount",
      key: "workorderCount",
      width: 120,
    },
    {
      title: "PMs created",
      dataIndex: "maintenanceCount",
      key: "maintenanceCount",
      width: 120,
    },
    {
      title: "Spare part expenditure",
      dataIndex: "inventoryExpenditure",
      key: "inventoryExpenditure",
      width: 160,
      render: (val) =>
        val || val === 0
          ? `₹ ${formatNumber(Number(val?.toFixed(2)) || 0)}`
          : "-",
    },
    {
      title: "SOPs",
      dataIndex: "sopCodes",
      key: "sopCodes",
      width: 150,
      render: (val) => {
        return (
          <>
            {val?.length > 0 ? (
              <div
                className="flex-start"
                style={{ flexWrap: "wrap", gap: "2px" }}
              >
                {val?.map((eachSOP, index) => (
                  <a
                    href={`/procedures/${eachSOP?._id}`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {" "}
                    {eachSOP?.code} {index + 1 != val?.length && ","}
                  </a>
                ))}
              </div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Overall Equipment Effectiveness (OEE)",
      dataIndex: "oee",
      width: 160,
      key: "oee",
      render: (val) => (val ? `${(val * 100).toFixed(2)} %` : "-"),
    },
    {
      title: "Mean Time To Repair (MTTR)",
      dataIndex: "mttr",
      key: "mttr",
      width: 180,
      render: (val) => {
        let durationObj = formatHours(val ? val : 0);
        return (
          <Tag className={tagStyles.smRndTag} color="geekblue">
            {durationObj?.days ? `${durationObj?.days} days ` : ""}
            {`${durationObj?.hours} hrs`}
            {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
          </Tag>
        );
      },
    },
    {
      title: "Mean Time Between Failure (MTBF)",
      dataIndex: "mtbf",
      key: "mtbf",
      width: 180,
      render: (val) => {
        let durationObj = formatHours(val && val > 0 ? val : 0);
        return (
          <Tag className={tagStyles.smRndTag} color="geekblue">
            {durationObj?.days ? `${durationObj?.days} days ` : ""}
            {`${durationObj?.hours} hrs`}
            {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
          </Tag>
        );
      },
    },
  ];
  const handleSearch = (e) => {
    setSearchValue(e?.target?.value);
  };

  const sortOptions = [
    {
      label: "MRO type",
      value: "mro_type",
      children: [
        {
          label: "Mechanical",
          value: "Mechanical",
        },
        {
          label: "Electrical",
          value: "Electrical",
        },
        {
          label: "All Others",
          value: "Others",
        },
      ],
      // children: mroTypes,
    },
    {
      label: "Spare part expenditure",
      value: "inventory",
    },
    {
      label: "WOs created",
      value: "workorder",
    },
    {
      label: "PMs created",
      value: "maintenance",
    },
    {
      label: "Asset downtime",
      value: "downtime",
    },
  ];

  return (
    <>
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <div style={{ position: "relative" }}>
            <Cascader
              style={{ width: 240, textAlign: "left" }}
              className="custom-select"
              placeholder="Sort by"
              displayRender={(labels) => labels.join(" - ")}
              options={sortOptions}
              onChange={(value) => {
                setSelectedSorter(
                  value?.length > 0 ? value[value?.length - 1] : undefined
                );
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 8,
                top: 6,
              }}
            >
              <Image
                src={sortIcon}
                width={18}
                height={18}
                alt="sorter"
                preview={false}
              />
            </div>
          </div>
        </Space>
      </Col>
      <Col
        span={10}
        offset={4}
        style={{ textAlign: "right" }}
        // style={{ justifyContent: "right", display: "flex", gap: "12px" }}
      >
        <Space>
          <Button
            type="primary"
            onClick={() => handleAssetsDownload()}
            loading={downloading}
          >
            Download Asset Report
          </Button>
          <RangePicker
            format="DD-MMM-YYYY"
            value={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(dates, dateString) => {
              setDateFilter({
                start_date: dates?.[0],
                end_date: dates?.[1],
              });
            }}
          />
        </Space>
      </Col>
      <Col span={24}>
        <Table
          title={() => (
            <Space
              align="center"
              style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
            >
              <Input.Search
                size="large"
                onChange={debounce(handleSearch, 500)}
                placeholder="Search by asset name or code"
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
            </Space>
          )}
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No assets found</Typography>}
              />
            ),
          }}
          pagination={false}
          bordered
          scroll={{
            x: "max-content",
            y: "50vh",
          }}
          style={{scrollbarColor: "red"}}
          // onRow={(item) => {
          //   return {
          //     onClick: (e) => {
          //       navigate(`/assets/${item?._id}`, {
          //         state: { source: "/assets?type=table" },
          //       });
          //     },
          //     style: { cursor: "pointer" },
          //   };
          // }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          columns={columns}
          dataSource={assets?.assetsData}
          components={tableComponents}
        />
      </Col>
      {assets?.totalAssets > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={assets?.totalAssets}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default AssetsTableView;
