import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row, Table, Tag, Typography } from "antd";
import React, { Children, useCallback, useEffect, useState } from "react";
import { tableComponents } from "../../../helpers/utility";
import { getAllocationListApi } from "../../../services/inventory.services";
import TableFilters from "../TableFilters";

const NotReturned = () => {
  const [notReturnedData, setNotReturnedData] = useState([]);
  const [totoalNotReturned, setTotoalNotReturned] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  useEffect(() => {
    fetchNotReturnedData();
  }, [selectedFilters]);

  const fetchNotReturnedData = async () => {
    try {
      let query = {
        page: page,
        filterType: "not-returned",
        store_id: selectedFilters?.store,
        inventory_id: selectedFilters?.inventory,
      };
      const resp = await getAllocationListApi(query);
      setNotReturnedData(resp?.data?.allocations || []);
      setTotoalNotReturned(resp?.data?.totalAllocations || 0);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownload = useCallback(async () => {
    try {
      let query = {
        download: true,
        filterType: "not-returned",
        store_id: selectedFilters?.store,
        inventory_id: selectedFilters?.inventory,
      };

      const resp = await getAllocationListApi(query,true);
      const url = window.URL.createObjectURL(new Blob([resp.data]));

     const a = document.createElement("a");
      a.href =  url;
      a.download = "NotReturnedInventory.xlsx";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    }
  }, [selectedFilters]);

  const tableColumns = [

    {
      title: "Item code",
      dataIndex: "inventory",
      key: "inventory ",
      width: "150px",
      render: (value) => {
        return <Typography>{value?.code || "-"}</Typography>;
      },
    },
    {
      title: "Item",
      dataIndex: "inventory",
      key: "inventory",
      width: "300px",
      render: (value) => {
        return (
          <Flex gap={10}>
            <Typography>{value?.name || "-"}</Typography>
            
          </Flex>
        );
      },
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: "250px",
      render: (value) => {
        return <Typography>{value?.name || "-"}</Typography>;
      },
    },
    {
      title: "Issued Quantity",
      dataIndex: "issued_quantity",
      key: "issued_quantity",
      render: (value) => {
        return <Typography>{value || 0} </Typography>;
      },
    },
    {
      title: "Returned Quantity",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      render: (value) => {
        return <Typography>{value || 0} </Typography>;
      },
    },
    {
      title: "Total Used Quantity",
      dataIndex: "total_used_quantity",
      key: "total_used_quantity",
      render: (value) => {
        return <Typography>{value}</Typography>;
      },
    },
    {
      title: "Not Returned",
      dataIndex: "not_returned",
      key: "not_returned",

      children: [
        {
          title: "Total Count",
          dataIndex: "not_returned_quantity",
          key: "not_returned",
          width: "200px",
          render: (value, record) => {
            return (
              <Typography>
                {(record?.issued_quantity || 0) -
                  (record?.returned_quantity || 0) -
                  (record?.total_used_quantity || 0)}
              </Typography>
            );
          },
        },
        {
          title: "Total Cost",
          dataIndex: "total_cost",
          key: "total_cost",
          width: "200px",

          render: (value, record) => {
            return (
              <Typography>
                ₹{" "}
                {record?.inventory?.cost *
                  ((record?.issued_quantity || 0) -
                    (record?.returned_quantity || 0) -
                    (record?.total_used_quantity || 0)) || 0}
              </Typography>
            );
          },
        },
      ],
    },
  ];
  return (
    <Row gutter={[24, 12]}>
      <Col
        span={24}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <Flex gap={10} align="center">
          <Button type="text" onClick={() => window.history.back()}>
            <LeftOutlined />
          </Button>
          <Typography>Not Returned Inventory List</Typography>
        </Flex>
        <TableFilters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleDownload={handleDownload}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={tableColumns}
          components={tableComponents}
          dataSource={notReturnedData}
          pagination={false}
          scroll={{ x: 768 }}
          bordered
        />
      </Col>
    </Row>
  );
};

export default NotReturned;
