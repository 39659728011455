import {
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Col,
  Popover,
  Input,
  DatePicker,
  Select,
  Skeleton,
  Pagination,
  Space,
  Button,
  Image,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Maintanance.css";
import {
  workOrderstatusList,
  capitalizeFirstLetter,
  priorityOptions,
  debounce,
  convertUTCToLocalFormat,
  displayFilterTag,
  convertToUTCFormat,
  tableComponents,
  scrollToTopTable,
  displayTaskType,
  getSearchDefaultValue,
  formatNumber,
  displayScheduleType,
} from "../../helpers/utility";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import { UserContext } from "../../context/UserProvider";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { getAllJobsApi } from "../../services/maintenance.services";
import dayjs from "dayjs";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import {
  INSPECTION,
  PAGESIZE,
  SOP,
  frequencyOptions,
} from "../../constants/defaultKeys";
import PmFilters from "./PmFilters";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import { getItem, removeItem, setItem } from "../../helpers/localStorage";

const { RangePicker } = DatePicker;

const JobsTable = ({ viewType, setViewType, type }) => {
  const tableRef = useRef(null);
  const [jobs, setJobs] = useState(null);
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(2, "month"),
    end_date: dayjs(),
  });
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
    subTypeObj: [],
    priorities: [],
    verification: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(undefined);
  const [selectedFreq, setSelectedFreq] = useState(null);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setDateFilter({
        start_date: locStorageData?.dateFilter?.start_date
          ? dayjs(locStorageData?.dateFilter?.start_date)
          : null,
        end_date: locStorageData?.dateFilter?.end_date
          ? dayjs(locStorageData?.dateFilter?.end_date)
          : null,
      });
      setFilters({
        asset: locStorageData?.filters?.asset || undefined,
        production_line: locStorageData?.filters?.production_line || undefined,
        subTypeObj: locStorageData?.filters?.subTypeObj || [],
        priorities: locStorageData?.filters?.priorities || [],
        verification: locStorageData?.filters?.verification || undefined,
      });
      locStorageData?.selectedStatus &&
        setSelectedStatus(locStorageData?.selectedStatus);
      locStorageData?.selectedFreq &&
        setSelectedFreq(locStorageData?.selectedFreq);
      locStorageData?.search && setSearch(locStorageData?.search);
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      setSearch(null);
      setDateFilter({
        start_date: dayjs().subtract(2, "month"),
        end_date: dayjs(),
      });
      setFilters({
        asset: undefined,
        production_line: undefined,
        subTypeObj: [],
        priorities: [],
        verification: undefined,
      });
      setSelectedStatus(undefined);
      setSelectedFreq(null);
    }
  }, [type]);

  const columns = [
    {
      title: `${type == INSPECTION ? "Schedule" : "PM"} code`,
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
      render: (val, record) => <>{record?.maintenance?.code || "-"}</>,
    },
    {
      title: "Job date",
      dataIndex: "occurrence_date",
      key: "occurrence_date",
      width: 140,
      render: (val, record) => (
        <>
          {record?.occurrence_date
            ? dayjs(convertUTCToLocalFormat(record?.occurrence_date)).fromNow()
            : "-"}
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 250,
      render: (val, record) => <>{record?.maintenance?.name || "-"}</>,
    },
    {
      title: "Type",
      dataIndex: "subTypeObj",  
      key: "subTypeObj",
      width: 140,
      render: (val, record) =>
        record?.maintenance?.subTypeObj?.name
          ?  type == INSPECTION ? displayScheduleType(record?.maintenance?.subTypeObj?.name) : displayTaskType(record?.maintenance?.subTypeObj?.name)
          : "-",
    },
    {
      title: "Asset/ Production line",
      dataIndex: "asset",
      key: "asset",
      width: 250,
      render: (val, record) => (
        <>
          {record?.maintenance?.asset?.name ||
            record?.maintenance?.production_line?.name ||
            "-"}
        </>
      ),
    },
    {
      title: "Location",
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {record?.maintenance?.asset?.location?.name ||
            record?.maintenance?.production_line?.location?.name ||
            "-"}
        </>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 140,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: "Assigned to",
      title: <Typography className={"tableHeader"}>Assigned to</Typography>,
      dataIndex: "assigned_users",
      key: "assigned_users",
      width: 210,
      render: (val, record) => {
        const list = [
          ...(record?.assigned_users || []),
          ...(record?.assigned_groups || []),
        ];

        return (
          <>
            {list?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {list?.slice(0, 2)?.map((group, index) => (
                  <Typography key={index}>
                    {group?.first_name || group?.name}
                    {list?.length > 1 ? "," : ""}
                  </Typography>
                ))}
                {list?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{
                          flexDirection: "column",
                          gap: "6px",
                          alignItems: "flex-start",
                        }}
                      >
                        {list?.map((group, index) => (
                          <Typography key={index}>
                            {group?.first_name || group?.name}
                          </Typography>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{list?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <div
                className="flex-center"
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                -
              </div>
            )}
          </>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (val, record) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == val
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                style={{
                  minWidth: "80px",
                  textAlign: "left",
                }}
                color={value?.color}
              >
                {value?.label}{" "}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "Verification",
      width: 140,
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      render: (val, record) => (
        <div className="flex-center" style={{ justifyContent: "flex-start" }}>
          <Tag
            className={tagStyles.smTag}
            color={val ? "success" : "error"}
            style={{ minWidth: "90px", border: "0px" }}
          >
            {val ? "Verified" : "Not verified"}
          </Tag>
        </div>
      ),
    },
    {
      title:"Total Expenditure",
      dataIndex:"total_inventory_cost",
      key:"total_inventory_cost",
      width:160,
      render:(val, record)=>{
        return  `₹ ${ val ? formatNumber(val) : 0}`
      }
    }
  ];

  const getAllJobs = async (data, clearLocStorage) => {
    let params = { page: data?.pageNumber };
    if (data?.search) {
      params.name = data?.search;
    }
    if (type) {
      params.type = type;
    }
    if (data?.selectedStatus) {
      params.status =
        data?.selectedStatus === "today" ? ["open"] : [data?.selectedStatus];
    }
    if (data?.dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        data?.dateFilter.start_date.startOf("day")
      );
    }
    if (data?.dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(
        data?.dateFilter.end_date.endOf("day")
      );
    }
    data?.filters?.asset && (params.asset = data?.filters?.asset);
    data?.filters?.production_line &&
      (params.production_line = data?.filters?.production_line);
    data?.filters?.subTypeObj?.length > 0 &&
      (params.subTypeObj = data?.filters?.subTypeObj);
    data?.filters?.priorities?.length > 0 &&
      (params.priority = data?.filters?.priorities);
    data?.filters?.verification &&
      (params.mark_as_verified =
        data?.filters?.verification === "verified" ? true : false);
    data?.selectedFreq && (params.repeat = data?.selectedFreq);

    try {
      setLoading(true);
      const resp = await getAllJobsApi(params);
      setJobs(resp?.data);
    } catch (error) {
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  useEffect(() => {
    if (viewType == "jobs") {
      const fetchData = async (data, status = false) => {
        await getAllJobs(data, status);
        scrollToTopTable(tableRef);
      };

      let locStorageData = getItem("applied_filters", true);
      if (locStorageData) {
        fetchData(
          {
            ...locStorageData,
            dateFilter: {
              start_date: locStorageData?.dateFilter?.start_date
                ? dayjs(locStorageData?.dateFilter?.start_date)
                : null,
              end_date: locStorageData?.dateFilter?.end_date
                ? dayjs(locStorageData?.dateFilter?.end_date)
                : null,
            },
          },
          true
        );
      } else {
        fetchData({
          dateFilter: dateFilter,
          filters: filters,
          selectedStatus: selectedStatus,
          selectedFreq: selectedFreq,
          search: search,
          pageNumber: pageNumber,
        });
      }
      // fetchData();
    }
  }, [
    viewType,
    userDetails?.currFacility,
    // dateFilter,
    // search,
    // selectedAsset,
    type,
    pageNumber,
    refresh,
  ]);

  // useEffect(() => {
  //   setCount((prev) => ({ ...prev, jobs: jobs?.length }));
  // }, [jobs]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [search, dateFilter, filters, selectedStatus, selectedFreq]);

  const saveJobFilters = () => {
    let dataObj = {
      dateFilter: dateFilter,
      filters: filters,
      selectedStatus: selectedStatus,
      selectedFreq: selectedFreq,
      search: search,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const statusOptions = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Upcoming",
      value: "open",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "In Progress",
      value: "in-progress",
    },
    {
      label: "On Hold",
      value: "on-hold",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ];

  return (
    <>
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <Button
            style={{ color: "#6941C6" }}
            className={btnStyles.smBtn}
            type="default"
            icon={
              <Image
                src={filterActiveIcon}
                width={18}
                height={18}
                alt="filter"
                preview={false}
              />
            }
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </Button>
          <Select
            className={inputStyles.smSelect}
            style={{ width: 180 }}
            placeholder="Status"
            allowClear
            options={statusOptions}
            value={selectedStatus}
            onChange={(e) => {
              if (e == "today") {
                setDateFilter({
                  start_date: dayjs(),
                  end_date: dayjs(),
                });
              } else if (selectedStatus === "today") {
                setDateFilter({
                  start_date: dayjs().subtract(2, "month"),
                  end_date: dayjs(),
                });
              }
              setSelectedStatus(e);
            }}
          />
        </Space>
        {showFilterModal && (
          <PmFilters
            type={type === INSPECTION ? "Inspection" : "PM"}
            viewType={"job"}
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            assets={assets}
            productionLines={productionLines}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </Col>
      <Col offset={4} span={10} style={{ textAlign: "right" }}>
        <RangePicker
          id="pm-jobs-filter"
          format="DD-MMM-YYYY"
          allowClear={false}
          value={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(dates, dateString) => {
            setDateFilter({
              start_date: dates?.[0],
              end_date: dates?.[1],
            });
            selectedStatus === "today" && setSelectedStatus(undefined);
          }}
        />
      </Col>
      {(filters?.asset ||
        filters?.production_line ||
        filters?.subTypeObj?.length > 0 ||
        filters?.priorities?.length > 0 ||
        filters?.verification) && (
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            flexWrap: "wrap",
          }}
        >
          {filters?.asset && displayFilterTag("Asset", "purple")}
          {filters?.production_line &&
            displayFilterTag("Production Line", "purple")}
          {filters?.subTypeObj?.length > 0 &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Type`,
              "volcano",
              filters?.subTypeObj?.length
            )}
          {filters?.priorities?.length > 0 &&
            displayFilterTag(
              "Priority",
              "magenta",
              filters?.priorities?.length
            )}
          {filters?.verification &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Verification`,
              "orange"
            )}
          <Typography
            style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
            onClick={() =>
              setFilters({
                asset: undefined,
                production_line: undefined,
                subTypeObj: [],
                priorities: [],
                verification: undefined,
              })
            }
          >
            Clear All
          </Typography>
        </Col>
      )}
      <Col span={24}>
        <Table
          ref={tableRef}
          title={() => (
            <Space
              align="center"
              style={{
                width: "100%",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <Space align="center" style={{ gap: 0 }}>
                <Typography style={{ marginRight: 8 }}>Frequency</Typography>
                {frequencyOptions?.map((item) => (
                  <Tag
                    style={{
                      cursor:
                        item?.value === selectedFreq ? "default" : "pointer",
                      backgroundColor:
                        item?.value === selectedFreq ? "#f9f0ff" : "#f0f9ff",
                      borderRadius: 4,
                    }}
                    className={tagStyles.mdTag}
                    color={item?.value === selectedFreq ? "purple" : "default"}
                    onClick={() => setSelectedFreq(item?.value)}
                  >
                    {item?.label}
                    {item?.value === selectedFreq && (
                      <CloseOutlined
                        style={{ fontSize: 12 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedFreq(null);
                        }}
                      />
                    )}
                  </Tag>
                ))}
              </Space>
              <Input.Search
                size="large"
                defaultValue={getSearchDefaultValue()}
                onChange={debounce(handleSearch, 500)}
                placeholder={`Search by ${
                  type == INSPECTION ? "Schedule" : "PM"
                } code`}
                prefix={
                  <SearchOutlined style={{ fontSize: 16, color: "#667085" }} />
                }
                style={{ width: "300px" }}
                className="custom-input-search"
              />
            </Space>
          )}
          key={`${type}_jobs`}
          scroll={{
            x: 1200,
            y: "60vh",
          }}
          onRow={(item) => {
            return {
              onClick: (e) => {
                // !item?.children?.length > 0 &&
                //   navigate(
                //     `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                //       item?._id
                //     }/job`
                //   );
                if (!item?.children?.length > 0) {
                  navigate(
                    `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                      item?._id
                    }/job`
                  );
                  saveJobFilters();
                }
              },
            };
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={<Typography>No Jobs's found</Typography>}
              />
            ),
          }}
          rowClassName={"rowClassName"}
          bordered
          columns={columns}
          dataSource={jobs?.occurrences || []}
          pagination={false}
          components={tableComponents}
        />
      </Col>
      {jobs?.totalOccurrences > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={jobs?.totalOccurrences}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default JobsTable;
