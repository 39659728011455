import { Button, Col, Form, Modal, Row, Select } from "antd";
import React, { useEffect, useRef } from "react";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

const AuditLogFilters = ({
  showFilterModal,
  setShowFilterModal,
  assets,
  productionLines,
  filters,
  setFilters,
  taskTypes,
}) => {
  const [form] = Form.useForm();
  const formRef = useRef();

  useEffect(() => {
    if (showFilterModal) {
      form.setFieldsValue(filters);
    }
  }, [showFilterModal, filters]);

  const onFinish = (values) => {
    setShowFilterModal(false);
    setFilters({
      asset: values?.asset,
      production_line: values?.production_line,
      taskType: values?.taskType,
    });
  };

  return (
    <>
      <Modal
        title="Filter"
        width={"50%"}
        open={showFilterModal}
        onCancel={() => setShowFilterModal(false)}
        maskClosable={false}
        footer={
          <Button
            className={btnStyles.mdBtn}
            type="primary"
            onClick={() => formRef?.current?.submit()}
          >
            Apply
          </Button>
        }
      >
        <Form layout="vertical" form={form} ref={formRef} onFinish={onFinish}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Asset" name={"asset"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type asset name"
                  allowClear
                  showSearch={true}
                  filterOption={(inputValue, option) =>
                    option.name
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                  options={assets}
                  onChange={(e) =>
                    form.setFieldValue("production_line", undefined)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Production Line" name={"production_line"}>
                <Select
                  className={inputStyles.smSelect}
                  style={{ width: "100%" }}
                  placeholder="Type production line name"
                  allowClear
                  showSearch={true}
                  optionFilterProp="label"
                  options={productionLines}
                  onChange={(e) => form.setFieldValue("asset", undefined)}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Task Type" name={"taskType"}>
                <Select className={inputStyles.smSelect} style={{ width: "100%" }} placeholder="Type task type" allowClear showSearch={true} options={taskTypes} />
              </Form.Item>
            </Col>
           
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AuditLogFilters;
