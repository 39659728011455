import { DownloadOutlined, FileExcelOutlined } from "@ant-design/icons";
import {
  Button,
  Modal,
  Select,
  Space,
  Spin,
  TreeSelect,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useState } from "react";
import { importInventoryApi } from "../../services/inventory.services";
// import inputStyles from "../../assets/styles/Custom/Input.module.css";

const { Dragger } = Upload;

const ImportInventory = ({
  stores,
  showImportModal,  
  setShowImportModal,
  reloadPage,

}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);

  const props = {
    beforeUpload: (file) => {
      const fileType = file.type === "text/csv";
      if (!fileType) {
        message.error(`${file.name} is not a valid file`);
      }
      return fileType ? true : Upload.LIST_IGNORE;
    },
    onChange: async (info) => {
      if (info.file.status === "done") {
        setLoading(true);
        await importInventoryApi(selectedStore, {
          file: info?.file?.originFileObj,
        })
          .then((res) => {
            setData({
              failedCases: res?.data?.failedCases || [],
              sucessMsg: res?.data?.message,
            });
            reloadPage();
          })
          .catch((error) => {
            setData({ errorMsg: error?.response?.data?.message });
          })
          .finally(() => {
            setLoading(false);
          });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed`);
      }
    },
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const sampleFileUrl =
    process.env.REACT_APP_PUBLIC_ENV === "PRODUCTION"
      ? "https://cmms-prod-public-resorces.s3.ap-south-1.amazonaws.com/sample_inventory_upload.csv"
      : "https://cmms-dev-pub-resources.s3.ap-south-1.amazonaws.com/sample_inventory_upload_dev.csv";

  return (
    <Modal
      title={
        <Space style={{ width: "94%", justifyContent: "space-between" }}>
          {"Import Inventory"}
          <Button
            type="primary"
            ghost
            icon={<DownloadOutlined />}
            href={sampleFileUrl }
            download={"sample_inventory_file.csv"}
          >
            Sample CSV File
          </Button>
        </Space>
      }
      open={showImportModal}
      width={600}
      footer={null}
      onCancel={() => {
        setShowImportModal(false);
      }}
      maskClosable={false}
    >
      {data ? (
        <>
          <Space direction="vertical">
            <Typography.Text
              type={data?.sucessMsg ? "success" : "danger"}
              style={{ fontSize: 16 }}
            >
              {data?.sucessMsg || data?.errorMsg}
            </Typography.Text>
            {data?.failedCases?.length > 0 && (
              <>
                <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                  Failed rows :
                </Typography>
                <Space direction="vertical" style={{ gap: 4 }}>
                  {data?.failedCases?.map((f) => (
                    <Typography>{f}</Typography>
                  ))}
                </Space>
              </>
            )}
          </Space>
        </>
      ) : (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Typography>Select Store</Typography>
          <Select
            disabled={loading}
            style={{ width: "520px" }}
            placeholder={"store"}
            showSearch
            onChange={(value) => setSelectedStore(value)}
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            options={stores?.map((store) => ({
              label: store?.name,
              value: store?._id,
            })) || [] }
            
          />

          {selectedStore && (
            <Spin spinning={loading} tip="Uploading...">
              <Dragger
                style={{ width: "520px", marginTop: 16 }}
                {...props}
                accept=".csv"
                maxCount={1}
                showUploadList={false}
                customRequest={dummyRequest}
              >
                <p className="ant-upload-drag-icon">
                  <FileExcelOutlined />
                </p>
                <p className="ant-upload-text">
                  <span style={{ color: "#406AFF" }}>Click to Upload </span> or
                  drag and drop csv files
                </p>
                <p className="ant-upload-text">
                  Please include column names as
                  <br />
                  (name, code, category, cost, available_quantity,
                  <br />
                  low_stock_threshold, high_value)
                </p>
                <br />
                <p className="ant-upload-text">
                  <span style={{ fontWeight: 600 }}>Note: </span>
                  <span style={{ fontWeight: 600 }}>"category"</span> must be valid <br/>
               <n/>   <span style={{ fontWeight: 600 }}>"high_value"</span> should be yes or no
                </p>
              </Dragger>
            </Spin>
          )}
        </Space>
      )}
    </Modal>
  );
};

export default ImportInventory;
