export const facilities = [
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1138619489376 14.2620392951494,80.111182237472 14.2551311888769,80.1214713569074 14.2516817458371,80.1224725580428 14.256649248936,80.1232624079145 14.2586901875872,80.1138619489376 14.2620392951494)))",
    content: `
        Name - ABC Plant
        Type - Assembly
        No of Employees - 540
        No of Assets - 1200
        No of Production Lines - 6
        Operational status - Active
      `,
  },
];

export const locations = [
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1191984803884 14.2571393944321,80.1198394049319 14.2569208974286,80.118819752249 14.2541095693171,80.1181351283047 14.2542989333867,80.1191984803884 14.2571393944321)))",
    content: `
        Name - Main Assembly Line
        Type - Production Hall
        Floor Area(sqft) - 150000 sqft
        No of Assets - 50
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1200724684023 14.2567897992265,80.1207133929459 14.2566732674913,80.1197374396636 14.2537454076446,80.1190091163187 14.2540221705157,80.1200724684023 14.2567897992265)))",
    content: `
        Name - Warehouse 1
        Type - Storage Facility
        Floor Area(sqft) - 100000 sqft
        No of Assets - 30
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.120990155817 14.2564839034216,80.1217913114964 14.2562217070174,80.1207425258797 14.2533812459721,80.1200142025347 14.2536143094425,80.120990155817 14.2564839034216)))",
    content: `
        Name - Research Lab
        Type - Laboratory
        Floor Area(sqft) - 50000 sqft
        No of Assets - 20
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.116649348681 14.2602566183485,80.1166639151479 14.2603731500837,80.1171737414894 14.2601692195471,80.1167804468831 14.2587854051917,80.116212354674 14.2589602027945,80.116649348681 14.2602566183485)))",
    content: `
        Name - Office Block A
        Type - Office Building
        Floor Area(sqft) - 200000 sqft
        No of Assets - 70
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1173922384929 14.2600672542788,80.1179166313012 14.260038121345,80.117523336695 14.2585086423206,80.1169261115521 14.2587562722579,80.1173922384929 14.2600672542788)))",
    content: `
        Name - Parking Lot 1
        Type - Outdoor Parking
        Floor Area(sqft) - 50000 sqft
        No of Assets - 10
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1181788277054 14.2598778902091,80.1187177869807 14.2597322255401,80.1182079606392 14.2584649429199,80.1177127007646 14.2584649429199,80.1181788277054 14.2598778902091)))",
    content: `
        Name - Green Space
        Type - Recreation Area
        Floor Area(sqft) - 75000 sqft
        No of Assets - 5
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1199145727004 14.2593091991439,80.1201802422601 14.2592357904497,80.1198271813978 14.2582115643839,80.1195510248818 14.258319929599,80.1199145727004 14.2593091991439)))",
    content: `
        Name - Security Office
        Type - Administrative
        Floor Area(sqft) - 20000 sqft
        No of Assets - 15
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1207045900754 14.2591134426262,80.1212673900638 14.2589736165421,80.1208898596368 14.2576277904829,80.1202886074753 14.2578130600443,80.1207045900754 14.2591134426262)))",
    content: `
        Name - Warehouse 2
        Type - Storage Facility
        Floor Area(sqft) - 90000 sqft
        No of Assets - 25
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.121470880442 14.258882756493,80.1219662455485 14.2587311693566,80.1215899846206 14.2573993680866,80.1210729642089 14.257567196702,80.1211081540798 14.2577756290145,80.121470880442 14.258882756493)))",
    content: `
        Name - Assembly Line 2
        Type - Production Hall
        Floor Area(sqft) - 120000 sqft
        No of Assets - 40
      `,
  },
  {
    type: "MultiPolygon",
    wkt: "MULTIPOLYGON(((80.1221313672506 14.2587041002251,80.122702792559 14.2585329659806,80.122312354547 14.2571841801209,80.1217172095386 14.2573858335209,80.1221313672506 14.2587041002251)))",
    content: `
        Name - Testing Facility
        Type - Quality Control
        Floor Area(sqft) - 70000 sqft
        No of Assets - 18
      `,
  },
];

export const stores = [
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1153307968569 14.2606878448562))",
    content: `
        Name - Steel & Aluminium Storage
        Type - Raw Materials
        No of SKU - 500
        Total Inventory Value - $2 million
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1156000864709 14.2594122624742))",
    content: `
        Name - Employee Check-in Point
        Type - Security
        No of Scanners - 3
        Peak Hour Traffic - 200 employees/hr
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1183780214362 14.2566485006464))",
    content: `
        Name - Assembly Line Control Panel
        Type - Production Control
        No of Consoles - 2
        Active Status - Operational
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1148572494383 14.2564282263345))",
    content: `
        Name - Fire Hydrant 1
        Type - Safety Equipment
        Capacity - 5000 liters
        Last Inspection - Jan 2025
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1179647956657 14.2550926047468))",
    content: `
        Name - Main Power Generator
        Type - Electrical Backup
        Capacity - 500 kW
        Fuel Type - Diesel
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1178490417947 14.2545405478239))",
    content: `
        Name - Quality Check Station
        Type - Inspection
        No of Stations - 4
        Avg. Processing Time - 15 min per unit
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1143501732624 14.2598252611327))",
    content: `
        Name - Visitor Reception
        Type - Administrative
        Seating Capacity - 20
        Security Clearance Level - Medium
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1128315899924 14.2576945614256))",
    content: `
        Name - Parking Entrance
        Type - Vehicle Access
        No of Lanes - 2
        Avg. Vehicle Flow - 500 cars/day
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.113921591063 14.2566181853684))",
    content: `
        Name - Water Treatment Plant
        Type - Utility
        Capacity - 10000 liters/hr
        Compliance - ISO 14001
      `,
  },
  {
    type: "MultiPoint",
    wkt: "MULTIPOINT((80.1168373439268 14.2548333086154))",
    content: `
        Name - Hazardous Material Storage
        Type - Chemical Storage
        Safety Rating - High
        Compliance - OSHA Certified
      `,
  },
];
