import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  Empty,
  Flex,
  Result,
  Row,
  Skeleton,
  Space,
  Table,
  Typography,
  Form,
  message,
  Pagination,
  Tag,
  Select,
  DatePicker,
} from "antd";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import { useNavigate } from "react-router-dom";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  scrollToTopTable,
  tableComponents,
} from "../../../helpers/utility";
import IssuedToForm from "./issuedToForm";
import {
  createAllocationApi,
  downloadInventoryRequestApi,
  getAllocationListApi,
  getStoreListApi,
  getWOPMListApi,
  updateAllocationApi,
} from "../../../services/inventory.services";
import dayjs from "dayjs";
import { getAllUsersApi } from "../../../services/users.services";
import ReturnToForm from "./returnToForm";
import { getItem, removeItem, setItem } from "../../../helpers/localStorage";
import { PAGESIZE } from "../../../constants/defaultKeys";
import ImportAllocations from "../ImportInventory";
const { RangePicker } = DatePicker;

const InventoryAllocations = () => {
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState("");
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [selectedAllocation, setSelectedAllocation] = useState({});
  const [allocations, setAllocations] = useState([]);
  const [totalAllocations, setTotalAllocations] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedWoPm, setSelectedWoPm] = useState(null);
  const [searchWoPm, setSearchWoPm] = useState("");
  const [allPMWO, setAllPMWO] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month").startOf("day"),
    end_date: dayjs().endOf("day"),
  });
  const [selectedStore, setSelectedStore] = useState("");
  const [allStores, setAllStores] = useState([]);
  const [showImportModal, setShowImportModal] = useState(false);
  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (searchWoPm) {
      fetchPMWO({ name: searchWoPm });
    } else {
      fetchPMWO({ limit: 25 });
    }
  }, [searchWoPm]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) setPageNumber(locStorageData.pageNumber);
  }, []);

  const fetchStores = async () => {
    try {
      const res = await getStoreListApi();
      let stores = res?.data?.map((s) => ({
        label: s?.name,
        value: s?._id,
      }));
      setAllStores(stores);
    } catch (error) {
      setAllStores([]);
    }
  };

  const fetchPMWO = async (query) => {
    try {
      const res = await getWOPMListApi(query);
      const PMWO = [
        ...(res?.data?.workorders ?? []).map((wo) => ({ ...wo, type: "wo" })),
        ...(res?.data?.occurrences ?? []).map((pm) => ({ ...pm, type: "pm" })),
      ];

      setAllPMWO(
        PMWO.map((wo) => ({
          label: (
            <div>
              {wo.type === "wo" ? (
                <Tag color="blue">{wo.code}</Tag>
              ) : (
                <div
                  style={{
                    padding: "0px 0px",
                    margin: 0,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Tag color="green">{wo.code}</Tag>
                  <Typography.Text>
                    {dayjs(convertUTCToLocalFormat(wo.occurrence_date)).format(
                      "MMM D"
                    )}
                  </Typography.Text>
                </div>
              )}
            </div>
          ),
          value: `${wo.type}-${wo._id}`,
          type: wo.type,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async (data, status = false) => {
      await fetchAllAllocations(data, status);
      scrollToTopTable(tableRef);
    };
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setPageNumber(locStorageData.pageNumber);
      fetchData(locStorageData, true);
    } else {
      fetchData({ pageNumber });
    }
  }, [pageNumber, selectedWoPm, dateFilter, selectedStore]);

  useEffect(() => {
    fetchAllUsers();
  }, [showDrawer]);

  const saveAllocationFilters = () => {
    let dataObj = {
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const fetchAllAllocations = useCallback(async (data, clearLocStorage) => {
    try {
      setLoading(true);
      let query = {
        // high_value: true,
        page: data?.pageNumber,
        store_id: selectedStore,
      };
      if (dateFilter?.start_date) {
        query.start_date = convertUTCToLocalFormat(
          dateFilter.start_date.startOf("day")
        );
      }
      if (dateFilter?.end_date) {
        query.end_date = convertUTCToLocalFormat(
          dateFilter.end_date.endOf("day")
        );
      }
      if (selectedWoPm) {
        const taskType = selectedWoPm?.split("-");

        if (taskType[0] === "wo") {
          query["workorder"] = taskType[1];
        } else {
          query["occurrence"] = taskType[1];
        }
      }

      const res = await getAllocationListApi(query);
      setAllocations(
        res?.data?.allocations.map((item) => {
          // const totalUsedQuantity = item?.assets_list?.reduce(
          //   (acc, curr) => acc + curr?.used_quantity,
          //   0
          // );
          return {
            ...item,
            balance_quantity: calculateBalance(
              item?.issued_quantity,
              item?.total_used_quantity,
              item?.returned_quantity
            ),
          };
        })
      );
      setTotalAllocations(res?.data?.totalAllocations || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  }, [selectedStore, selectedWoPm, dateFilter]);

  const calculateBalance = (
    issuedQuantity = 0,
    totalUsedQuantity = 0,
    returnedQuantity = 0
  ) => {
    return issuedQuantity - totalUsedQuantity - returnedQuantity;
  };

  const tableColumns = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: 150,
      fixed: "left",
      render: (val) => <span>{val?.name ? val?.name : "-"}</span>,
    },
    {
      title: "Spare part name",
      dataIndex: "inventory",
      key: "inventory",
      width: 200,
      fixed: "left",
      render: (val) => <span>{val?.name ? val?.name : "-"}</span>,
    },
    {
      title: "Spare part code",
      dataIndex: "inventory",
      key: "inventory",
      width: 200,
      fixed: "left",
      render: (val) => <span>{val?.code ? val?.code : "-"}{val?.high_value ? <Tag style={{marginLeft: 10}} color="success">High value</Tag> : ""}</span>,
    },
    {
      title: "Task code",
      dataIndex: "task_code",
      key: "task_code",
      width: 150,
      fixed: "left",
      render: (val, record) =>
        record?.workorder?.code ? (
          <Tag color="blue">{record?.workorder?.code}</Tag>
        ) : (
          <Space direction="vertical" style={{ gap: 2 }}>
            <Tag color="green">{record?.occurrence?.code}</Tag>
            <span style={{ fontSize: 12 }}>
              {record?.occurrence?.occurrence_date
                ? dayjs(
                    convertUTCToLocalFormat(record?.occurrence?.occurrence_date)
                  ).format("DD MMM hh:mm A")
                : "-"}
            </span>
          </Space>
        ),
    },
    {
      title: "Assets",
      dataIndex: "assets_list",
      key: "assets_list",
      width: 250,
      render: (val) => {
        return val.map((item, index) => (
          <span key={index}>
            {item?.asset?.name}
            {index !== val.length - 1 && ", "}
          </span>
        ));
      },
    },
    {
      title: "Issued to",
      dataIndex: "issued_to",
      key: "issued_to",
      width: 180,
      render: (val) => (
        <span>
          {val?.first_name || ""} {val?.last_name || ""}
        </span>
      ),
    },
    {
      title: "Used by",
      dataIndex: "creator",
      key: "creator",
      width: 180,
      render: (val, record) => {
        const usedBy = record?.is_allocation
          ? `${record?.issued_to?.first_name || ""} ${
              record?.issued_to?.last_name || ""
            }`
          : `${val?.first_name || ""} ${val?.last_name || ""}`;
        return <span>{usedBy}</span>;
      },
    },
    {
      title: "Used dates",
      dataIndex: "used_dates",
      key: "used_dates",
      width: 250,
      render: (val, record) => {
        return  record?.assets_list?.length > 0 ? record?.assets_list?.map((item, index) => (
          <span key={index}>
            {dayjs(convertUTCToLocalFormat(item?.used_date)).format("DD MMM YYYY")}
            {index !== record?.assets_list?.length - 1 && ", "}
          </span>
        )) : "-"
      },
    },
    {
      title: "Issued date",
      dataIndex: "issued_date",
      key: "issued_date",
      width: 150,
      render: (val) => (
        <span>{val ? dayjs(convertUTCToLocalFormat(val)).fromNow() : "-"}</span>
      ),
    },
    {
      title: "Issued quantity",
      dataIndex: "issued_quantity",
      key: "issued_quantity",
      width: 150,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title: "Total used quantity",
      dataIndex: "total_used_quantity",
      key: "total_used_quantity",
      width: 180,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title :"Returned date",
      dataIndex: "returned_date",
      key: "returned_date",
      width: 150,
      render: (val) => (
        <span>{val ? dayjs(convertUTCToLocalFormat(val)).fromNow() : "-"}</span>
      ),
    },
    {
      title: "Returned quantity",
      dataIndex: "returned_quantity",
      key: "returned_quantity",
      width: 150,
      render: (val) => <span>{val || 0}</span>,
    },
    {
      title: "Quantity to be returned ",
      dataIndex: "balance_quantity",
      key: "balance_quantity",
      width: 150,
      render: (val, record) => (
        <span>{val <= 0 ? <Tag>0</Tag> : <Tag color="red">{val}</Tag>} </span>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: 200,
      fixed: "right",
      render: (_, record) => (
        <Space>
          {record?.balance_quantity > 0 && (
            <Button
              size="medium"
              onClick={() => {
                setDrawerType("return");
                setShowDrawer(true);
                setSelectedAllocation(record);
              }}
            >
              Returned from
            </Button>
          )}
        </Space>
      ),
    },
  ];
  const fetchAllUsers = async () => {
    try {
      const res = await getAllUsersApi();
      setUsers(
        res?.data?.users?.map((user) => ({
          label: user.first_name + " " + (user.last_name || ""),
          value: user._id,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setShowDrawer(false);
    form1.resetFields();
    form2.resetFields();
    setDrawerType("");
    setSelectedAllocation({});
    saveAllocationFilters();
  };

  const onFinishIssue = async (values) => {
    let updatedInventoryList = [];
    const inventorySet = new Set();
    for (let item of values.inventory_list) {
      if (inventorySet.has(item.inventory)) {
        message.error("Duplicate inventory are not allowed.");
        return;
      }
      inventorySet.add(item.inventory);
      updatedInventoryList.push({
        inventory: item.inventory,
        issued_quantity: item.issued_quantity,
        inventory_name: item?.inventory_name,
      });
    }

    let payload = {
      ...values,
      inventory_list: updatedInventoryList,
      issued_date: values?.issued_date
        ? convertToUTCFormat(values?.issued_date)
        : "",
    };
    const taskType = values?.task_id?.split("-");

    if (taskType[0] === "wo") {
      payload["workorder"] = taskType[1];
    } else {
      payload["occurrence"] = taskType[1];
    }

    delete payload?.task_id;
    // payload["issued_date"] = convertToUTCFormat(values?.issued_date);
    payload["is_allocation"] = true;

    let query = { actionType: "issue_inventory" };

    try {
      const res = await createAllocationApi(query, payload);
      if (res) {
        message.success("Allocation created successfully");
      }
      fetchAllAllocations();
      onClose();
    } catch (error) {
      if (error?.response?.status == 410) {
        message.warning(
          `${error?.response?.data?.message} is direcly used in this work order.`
        );
      } else {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const onFinishReturn = async (values) => {
    if (selectedAllocation?.balance_quantity < values?.returned_quantity) {
      message.error(
        "Returned quantity cannot be greater than balance quantity"
      );
      return;
    }
    const payload = {
      ...values,
      returned_date: values?.returned_date
        ? convertToUTCFormat(values?.returned_date)
        : "",
    };
    let query = { actionType: "return_inventory" };

    try {
      const res = await updateAllocationApi(
        selectedAllocation?._id,
        query,
        payload
      );
      if (res) {
        message.success("Allocation returned successfully");
        fetchAllAllocations();
        onClose();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleDownload = async () => {
    try {
      let query = {}
      const response = await downloadInventoryRequestApi(query);
      if(response){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = "allocations.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleImport = () => {
    setShowImportModal(true);
  };

  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>
            Allocations {`(${totalAllocations})`}
          </Typography>
        </Flex>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Space>
            {/* <Button onClick={handleImport}>Import</Button>
            <Button onClick={handleDownload}>Download</Button> */}
            <RangePicker
              format="DD-MMM-YYYY"
              value={[dateFilter.start_date, dateFilter.end_date]}
              onChange={(dates, dateString) => {
                setDateFilter({
                  start_date: dates?.[0],
                  end_date: dates?.[1],
                });
              }}
              allowClear={false}
              style={{ width: 300 }}
            />
          {showImportModal &&  <ImportAllocations
              stores={allStores}
              showImportModal={showImportModal}
              setShowImportModal={setShowImportModal}
              reloadPage={fetchAllAllocations}
            />}
          </Space>
        </Col>
        <Col span={24}>
          <Table
            title={() => (
              <Flex justify="flex-end" gap={16}>
                <Select
                  size="large"
                  style={{ width: 300 }}
                  showSearch
                  onSearch={(value) => setSearchWoPm(value)}
                  placeholder="Select WO / PM"
                  filterOption={false}
                  allowClear
                  options={allPMWO}
                  onSelect={(value) => setSelectedWoPm(value)}
                  onClear={() => {
                    setSelectedWoPm("");
                    setSelectedWoPm(null);
                  }}
                />
                <Select
                  size="large"
                  placeholder="Select store"
                  options={allStores}
                  onChange={(value) => setSelectedStore(value)}
                  allowClear
                  onClear={() => setSelectedStore("")}
                  style={{ width: 200 }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                />
                <Button
                  size="large"
                  type="primary"
                  onClick={() => {
                    setDrawerType("issue");
                    setShowDrawer(true);
                  }}
                >
                  Issue Inventory
                </Button>
              </Flex>
            )}
            size="large"
            ref={tableRef}
            columns={tableColumns}
            dataSource={allocations?.map((item) => ({
              ...item,
              key: item._id,
            }))}
            pagination={false}
            scroll={{ x: "max-content", y: "60vh" }}
            loading={
              loading
                ? { spinning: true, indicator: <Skeleton active /> }
                : false
            }
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No allocations found</Typography>}
                />
              ),
            }}
            bordered
            components={tableComponents}
          />
        </Col>
        {totalAllocations > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={totalAllocations}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}

        {drawerType === "issue" ? (
          <IssuedToForm
            onClose={onClose}
            showDrawer={showDrawer}
            form1={form1}
            onFinish={onFinishIssue}
            users={users}
          />
        ) : (
          <ReturnToForm
            selectedAllocation={selectedAllocation}
            showDrawer={showDrawer}
            form2={form2}
            onClose={onClose}
            onFinish={onFinishReturn}
          />
        )}
      </Row>
    </CustomLayout>
  );
};

export default InventoryAllocations;
