import React, { useContext, useEffect, useState } from "react";
import CustomCard from "../../Blocks/CustomCard";
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Upload,
  message,
} from "antd";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { createFileLists, generateSOP, getAsset, getAssets } from "./helper";
import { UserContext } from "../../../context/UserProvider";
import CommonUpload from "../../Blocks/CommonUpload";
import {
  convertToFormData,
  deleteDocs,
  renameFile,
} from "../../../helpers/utility";
import {
  CloseOutlined,
  FilePdfTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import { ONPREMISE, SOPTYPES } from "../../../constants/defaultKeys";
import "../SOP.css";
import { getSopFileContext } from "../../../services/sops.services";
import axios from "axios";

const InitialQuestions = ({
  sopMetaData,
  contentType,
  setsopMetaData,
  setEnableAI,
  enableAI,
  sopContent,
  setSopContent,
  setSopReady,
}) => {
  const [dummyForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [existingSOPs, setExistingSops] = useState(null);
  const [assets, setAssets] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState([]);
  const [open, setOpen] = useState(false);
  const [assetDocs, setAssetDocs] = useState([]);
  const [fileLists, setFileLists] = useState([]);
  const { userDetails } = useContext(UserContext);
  const storageType = userDetails?.activeOrg?.organization?.settings?.storage;
  const [emailModal, setEmailModal] = useState(false);
  const [existingSopLoading, setExistingSopLoading] = useState(false);

  useEffect(() => {
    getAssets(setAssets);
  }, []);

  useEffect(() => {
    const newFileLists = createFileLists(assetDocs, storageType);
    setFileLists(newFileLists);
    setsopMetaData((prev) => ({ ...prev, asset_docs: assetDocs }));
  }, [assetDocs]);

  useEffect(() => {
    setAssetDocs(selectedAsset?.asset_docs?.product_manuals);
  }, [selectedAsset]);

  const onUpload = async (resp) => {
    if (!resp) return;

    if (resp?.path) {
      const updatedFiles = [...(assetDocs || []), resp?.path];
      setAssetDocs(updatedFiles);
    }
  };

  const onDelete = (e) => {
    deleteDocs(false, e?.name, storageType);
    setAssetDocs(assetDocs?.filter((id) => id != e?.name));
  };

  const uploadExistingSop = async (info, key) => {
    try {
      setExistingSopLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL_DOMAIN}/cmms-api/extract-sop-context/`,
        convertToFormData(info),
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      
      
      if (resp) {
        console.log("resp", resp.data.response);
        setExistingSops({
          name: info?.file?.name,
          context: resp?.data?.response,
        });
      }
      
    } catch (error) {
    } finally {
      setExistingSopLoading(false);
    }
  };

  const onUploadChange = (info) => {
    const { status } = info.file;
    if (status !== "uploading") {
      uploadExistingSop(info);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const beforeUpload = (file) => {
    const fileSize = file.size / (1024 * 1024);
    if (fileSize > 5) {
      message.error(`File too large ${fileSize?.toFixed(0)} mb, max 5mb`);
      return Upload.LIST_IGNORE;
    }
    if (file.type !== "application/pdf") {
      message.error("Cannot upload this file");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <CustomCard>
      <ConfigProvider
        theme={{
          components: {
            Form: {
              verticalLabelMargin: 2,
              labelFontSize: 14,
            },
          },
        }}
      >
        <Form
          layout="vertical"
          form={dummyForm}
          initialValues={{ ...sopMetaData, exisOrNewSOP: "1" }}
          style={{ minHeight: "70vh", width: "100%" }}
          disabled={loading}
          onFinish={(values) => {
            if (enableAI) {
              if (sopContent?.length > 0) {
                setOpen(true);
              } else {
                generateSOP(
                  existingSOPs ? [existingSOPs] : [],
                  storageType,
                  dummyForm,
                  assetDocs,
                  selectedAsset,
                  setLoading,
                  setSopContent,
                  setsopMetaData,
                  setSopReady,
                  setEmailModal,
                  setEnableAI,
                  contentType
                );
              }
            } else {
              setSopReady(true);
            }
          }}
        >
          <div
            className="flex-start"
            style={{
              marginBottom: "24px",
              width: "100%",
              gap: "24px",
              position: "relative",
            }}
          >
            <Form.Item
              label="Procedure type"
              className="formItem"
              name={"typeOfProc"}
              style={{ width: "50%" }}
              rules={[
                { required: true, message: "Please select procedure type" },
              ]}
            >
              <Select
                placeholder="Select procedure type"
                onSelect={(e) => {
                  setsopMetaData((prev) => ({ ...prev, type: e }));
                }}
                onChange={(value) => {
                  setsopMetaData((prev) => ({
                    ...prev,
                    type: value,
                  }));
                  dummyForm.setFieldValue("troubleshooting_issue", "");
                }}
                className={inputStyles.mdInput}
                options={SOPTYPES}
              />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.typeOfProc !== currentValues.typeOfProc
              }
              style={{ width: "50%" }}
              className="dummyItem"
            >
              {({ getFieldValue }) => {
                const showAdditionalFields = getFieldValue("typeOfProc");
                return (
                  showAdditionalFields == "Repair" && (
                    <Form.Item
                      name="troubleshooting_issue"
                      label={`Enter issue`}
                      rules={
                        showAdditionalFields == "Repair"
                          ? [{ required: true, message: "Please enter issue" }]
                          : []
                      }
                      style={{ width: "100%" }}
                    >
                      <Input
                        onChange={(e) =>
                          setsopMetaData((prev) => ({
                            ...prev,
                            troubleshooting_issue: e.target.value,
                          }))
                        }
                        className={inputStyles.smInput}
                        placeholder="Enter issue"
                        options={assets}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )
                );
              }}
            </Form.Item>
          </div>

          <Form.Item className="formItem" name="exisOrNewSOP">
            <Radio.Group
              style={{ width: "100%" }}
              onChange={(e) => {
                setExistingSops(null);
                if (e.target.value == "2" || e.target.value == "3") {
                  setEnableAI(true);
                } else {
                  setEnableAI(false);
                }
                dummyForm.resetFields([
                  "exisOrNewAsset",
                  "additionalAIDOCs",
                  "asset",
                  "mail",
                ]);
                setsopMetaData((prev) => ({ ...prev, asset: "" }));
                dummyForm.setFieldValue("asset", null);
                setSelectedAsset({});
              }}
            >
              <Row gutter={4}>
                <Col span={8}>
                  <Radio value={"1"}>Create a new procedure manually</Radio>
                </Col>
                <Col span={8}>
                  <Radio value={"2"}>Create a new procedure using AI</Radio>
                </Col>
                <Col span={8}>
                  <Radio value={"3"}>Upload existing procedure</Radio>
                </Col>
              </Row>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.exisOrNewSOP !== currentValues.exisOrNewSOP
            }
            className="dummyItem"
          >
            {({ getFieldValue }) => {
              const showAdditionalFields = getFieldValue("exisOrNewSOP");
              return showAdditionalFields == "3" ? (
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    alignItems: "center",
                    gap: 24,
                  }}
                >
                  <Upload
                    name="file"
                    multiple={false}
                    showUploadList={false}
                    maxCount={1}
                    className="assetsUpload"
                    accept={[".pdf"]}
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    customRequest={dummyRequest}
                  >
                    <Button
                      loading={existingSopLoading}
                      className={btnStyles.mdBtn}
                    >
                      {"Upload existing SOP"}
                      <UploadOutlined />
                    </Button>
                  </Upload>
                  {existingSOPs && (
                    <Space
                      align="center"
                      style={{
                        border: "1px solid #7369f466",
                        padding: "4px 8px",
                        borderRadius: 6,
                      }}
                    >
                      <FilePdfTwoTone
                        twoToneColor="#d92d20"
                        style={{ fontSize: 16 }}
                      />
                      <Typography>{existingSOPs?.name}</Typography>
                      <CloseOutlined onClick={() => setExistingSops(null)} />
                    </Space>
                  )}
                </div>
              ) : (
                <Form.Item
                  name="asset"
                  label={`Asset name ${
                    showAdditionalFields == "1" ? "(Optional)" : ""
                  }`}
                  rules={
                    showAdditionalFields == "2"
                      ? [{ required: true, message: "Please select asset" }]
                      : []
                  }
                  style={{ width: "50%" }}
                >
                  <Select
                    className={inputStyles.smSelect}
                    placeholder="Select asset"
                    showSearch={true}
                    filterOption={(inputValue, option) =>
                      option.name
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    options={assets}
                    onChange={(id) => {
                      dummyForm.resetFields(["additionalAIDOCs"]);
                      getAsset(id, setSelectedAsset);
                      setsopMetaData((prev) => ({ ...prev, asset: id }));
                    }}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          {Object.keys(selectedAsset)?.length > 0 && (
            <div
              justify="flex-start"
              gap={6}
              className="assetContainer"
              style={{
                flexDirection: "column",
                width: "50%",
              }}
            >
              {[
                {
                  title: "Make",
                  value: selectedAsset?.make || "-",
                },
                {
                  title: "Model",
                  value: selectedAsset?.model || "-",
                },
              ].map((each) => {
                return (
                  <Typography
                    style={{
                      width: "100%",
                      display: "flex",
                      color: "rgba(53, 56, 205, 1)",
                    }}
                  >
                    <span style={{ width: "60px" }}>{each.title}: </span>
                    <span>{each.value ?? "-"}</span>
                  </Typography>
                );
              })}
            </div>
          )}

          <Form.Item
            className="dummyItem"
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.exisOrNewAsset !== currentValues.exisOrNewAsset ||
              prevValues.exisOrNewSOP !== currentValues.exisOrNewSOP
            }
          >
            {({ getFieldValue }) => {
              const showAdditionalFields = getFieldValue("exisOrNewSOP");
              return (
                showAdditionalFields == "2" &&
                Object.keys(selectedAsset)?.length > 0 && (
                  <>
                    <Flex
                      vertical
                      gap={12}
                      style={{
                        borderRadius: "8px",
                        width: "100%",
                        margin: "auto",
                      }}
                    >
                      <Typography>
                        Add Documents for AI to use in creating{" "}
                        {dummyForm.getFieldValue("procType")} procedure for this
                        asset :
                      </Typography>

                      {fileLists?.length > 0 && (
                        <Flex style={{ gap: "6px", flexDirection: "column" }}>
                          {fileLists?.map((eachFile) => {
                            const fileName =
                              storageType == ONPREMISE
                                ? eachFile?.name?.slice(0, 40)
                                : renameFile(eachFile?.name)?.slice(0, 40);
                            return (
                              <a
                                href={eachFile.url}
                                target="_blank"
                                style={{ color: "#101828" }}
                              >
                                <div
                                  className={"fileListItem"}
                                  style={{
                                    width: "fit-content",
                                    marginTop: "0px",
                                    gap: "24px",
                                  }}
                                >
                                  <span style={{ fontSize: "12px" }}>
                                    {fileName}
                                  </span>
                                  <CloseOutlined
                                    style={{ fontSize: "10px" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setAssetDocs(
                                        assetDocs?.filter(
                                          (id) => id != eachFile?.name
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              </a>
                            );
                          })}
                        </Flex>
                      )}

                      <Form.Item
                        shouldUpdate={(prevValues, currentValues) =>
                          prevValues.additionalAIDOCs !==
                          currentValues.additionalAIDOCs
                        }
                        style={{ width: "500px" }}
                        className="dummyItem"
                      >
                        {({ getFieldValue }) => {
                          const showAdditionalFields =
                            getFieldValue("additionalAIDOCs");
                          return (
                            true && (
                              <CommonUpload
                                dragger={false}
                                documents={assetDocs}
                                onDelete={onDelete}
                                onUpload={onUpload}
                                buttonText="Add documents"
                                showFilelist={false}
                              />
                            )
                          );
                        }}
                      </Form.Item>
                    </Flex>
                  </>
                )
              );
            }}
          </Form.Item>
        </Form>
        <Form.Item style={{}}>
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => dummyForm.submit()}
            htmlType="submit"
            className={btnStyles.mdBtn}
            loading={loading}
          >
            {loading ? "Generating..." : " Proceed"}
          </Button>
        </Form.Item>
      </ConfigProvider>
    </CustomCard>
  );
};

export default InitialQuestions;
