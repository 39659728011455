import {
  Card,
  Col,
  Flex,
  List,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";

import { Bar, BarChart, LabelList, ResponsiveContainer, XAxis } from "recharts";
import { getInventoryInsightsApi } from "../../../../services/inventory.services";
import dayjs from "dayjs";
import { convertUTCToLocalFormat } from "../../../../helpers/utility";


const InventoryInsigts = ({ existingInventoryList }) => {
  const [insightDetails, setInsightDetails] = useState();
  const [selectedInventory, setSelectedInventory] = useState(null);

  useEffect(() => {
    if (!selectedInventory) return;
    fetchInsightDetails();
  }, [selectedInventory]);

  const fetchInsightDetails = async () => {
    try {
      const resp = await getInventoryInsightsApi(selectedInventory);
      setInsightDetails(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const constructGraphData = (data) => {
    try {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const graphData = [];

      const currentDate = new Date();
      for (let i = 11; i >= 0; i--) {
        const date = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - i,
          1
        );
        const monthIndex = date.getMonth() + 1;
        const year = date.getFullYear();
        const monthName = months[date.getMonth()];

        const record = data.find(
          (each) => each?.month === monthIndex && each?.year === year
        );
        graphData.push({
          month: monthName,
          total_used_quantity: record ? record?.totalUsedQuantity : 0,
        });
      }

      return graphData;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card title="Insights" style={{ marginTop: "10px", marginBottom: "10px" }}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Select
            allowClear
            onClear={() => setSelectedInventory(null)}
            value={selectedInventory}
            placeholder="Select Inventory"
            style={{ width: "100%" }}
            onChange={(e) => setSelectedInventory(e)}
            options={existingInventoryList}
          />
        </Col>
        {selectedInventory && (
          <>
            <Col span={24}>
              <Typography.Text strong>
                {` ${
                  insightDetails?.totalUsedQuantityAllAssets || 0
                } spare parts are used in last 3 months`}
              </Typography.Text>
            </Col>
            {insightDetails?.assets && insightDetails?.assets?.length !== 0 && (
              <Col span={24}>
                <List
                  size="small"
                  header={
                    <div>
                      <Typography.Text strong>
                        Used for the following assets :
                      </Typography.Text>{" "}
                    </div>
                  }
                  footer={null}
                  dataSource={insightDetails?.assets}
                  renderItem={(item) => (
                    <List.Item>
                      <Space>{item?.asset?.name}</Space>
                      <Space>{item?.totalUsedQuantity}</Space>
                    </List.Item>
                  )}
                />
              </Col>
            )}
            <Col span={24}>
              <Typography.Text strong>
                {" "}
                {"Usage trends in last 1 year"}
              </Typography.Text>
              <ResponsiveContainer width={"100%"} height={250}>
                <BarChart
                  data={constructGraphData(insightDetails?.usageChartData)}
                  margin={{
                    top: 25,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey="month" />
                  {/* <YAxis /> */}
                  <Tooltip />

                  <Bar dataKey="total_used_quantity" fill="#8884d8">
                    {" "}
                    <LabelList dataKey="total_used_quantity" position="top" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Col>
            <Col span={24}>
              <Flex
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "4px",
                  gap: 6,
                }}
              >
                <Typography.Text strong>
                  How frequently this is being purchased ?
                </Typography.Text>
                <Typography.Text>
                  {insightDetails?.last_purchased_date
                    ? `Last time it was purchased on ${dayjs(
                        convertUTCToLocalFormat(
                          insightDetails?.last_purchased_date
                        )
                      ).format("MMM D YYYY")}`
                    : "No purchase history available"}
                </Typography.Text>
              </Flex>
            </Col>
          </>
        )}
      </Row>
    </Card>
  );
};

export default InventoryInsigts;
