import { DeleteOutlined, EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Flex, Popconfirm, Tag, Typography } from "antd";
import React, { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { deleteCond, editCond } from "../../../helpers/utility";
import { deletePM, editPM, INSPECTION } from "../../../constants/defaultKeys";
import { UserContext } from "../../../context/UserProvider";
import { deleteMaintanance, deleteOccurence } from "./helper";

const Header = ({
  maintanaceDetails,
  edit,
  type,
  setEdit,
  source,
  assetCode,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const { userDetails } = useContext(UserContext);

  return (
    <>
      {!params?.id ? (
        <Typography className={typoStyles.h6}>
          <LeftOutlined
            style={{ marginRight: "6px" }}
            onClick={() =>
              navigate(
                params?.type
                  ? `/${
                      type == INSPECTION ? "inspections" : "maintenances"
                    }?type=job`
                  : `/${
                      type == INSPECTION ? "inspections" : "maintenances"
                    }?type=conf`
              )
            }
          />
          {params?.id
            ? type === INSPECTION
              ? "Update Schedule"
              : "Update Preventive Maintenance"
            : type === INSPECTION
            ? "Create Schedule"
            : "Create Preventive Maintenance"}
        </Typography>
      ) : (
        <Flex justify="space-between">
          <Typography
            className={typoStyles.h6}
            style={{
              gap: "6px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <LeftOutlined
              style={{ marginRight: "6px" }}
              onClick={() =>
                navigate(
                  source
                    ? source
                    : params?.type
                    ? `/${
                        type == INSPECTION ? "inspections" : "maintenances"
                      }?type=job`
                    : `/${
                        type == INSPECTION ? "inspections" : "maintenances"
                      }?type=conf`,
                  { state: { assetCode: assetCode } }
                )
              }
            />
            {maintanaceDetails?.code}
            {maintanaceDetails?.mark_as_verified && (
              <Tag className={tagStyles.mdRndTag} color="green">
                Verified
              </Tag>
            )}
          </Typography>
          <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {deleteCond(
              deletePM,
              maintanaceDetails?.creator?._id,
              userDetails?.activeOrg?.role,
              userDetails?._id
            ) && (
              <Popconfirm
                icon={null}
                description={`Are you sure to delete this ${
                  type == INSPECTION ? "inspection" : "maintenance" 
                } ${params?.type ? " job" : ""}? `}
                onConfirm={(e) => {
                  console.log("params", params , type);
                  if (params?.type) {
                    deleteOccurence(params, navigate, type);
                  } else {
                    deleteMaintanance(params, navigate, type);
                  }
                }}
              >
                <Button className={btnStyles.mdBtn}>
                  <span className="hiddenBelowTab">Delete</span>
                  <DeleteOutlined style={{ margin: "0px" }} />
                </Button>
              </Popconfirm>
            )}

            {!edit &&
              !maintanaceDetails?.mark_as_verified &&
              editCond(
                editPM,
                maintanaceDetails?.creator?._id,
                userDetails?.activeOrg?.role,
                userDetails?._id
              ) && (
                <Button
                  className={btnStyles.mdBtn}
                  onClick={() => setEdit(true)}
                  type="primary"
                >
                  <span className="hiddenBelowTab">Edit</span>
                  <EditOutlined style={{ margin: "0px" }} />
                </Button>
              )}
          </div>
        </Flex>
      )}
    </>
  );
};

export default Header;
