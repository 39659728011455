import React, { useEffect, useRef, useState } from "react";
import CustomLayout from "../../Layout";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Flex,
  Input,
  Pagination,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";

import typoStyles from "../../../assets/styles/Custom/Typography.module.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
const { RangePicker } = DatePicker;
dayjs.extend(relativeTime);

import {
  convertUTCToLocalFormat,
  debounce,
  getSearchDefaultValue,
  scrollToTopTable,
  tableComponents,
} from "../../../helpers/utility";
import {
  CheckCircleOutlined,
  CloseOutlined,
  LogoutOutlined,
  PauseCircleOutlined,
  RetweetOutlined,
  SearchOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  getIndentRequestListApi,
  getStoreListApi,
} from "../../../services/inventory.services.js";
import "../inventory.css";
import btnStyles from "../../../assets/styles/Custom/Button.module.css";
import { PAGESIZE } from "../../../constants/defaultKeys.js";

const IntendRequest = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const tableRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [allStores, setAllStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "month").startOf("day"),
    end_date: dayjs().endOf("day"),
  });
  useEffect(() => {
    fetchAllStores();
  }, []);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const fetchAllStores = async () => {
    try {
      const res = await getStoreListApi();
      setAllStores(
        res?.data?.map((store) => ({ label: store.name, value: store._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllIntendRequest = async () => {
    setLoading(true);
    try {
      let query = {
        page: pageNumber,
        store: selectedStore,
        status: selectedStatus,
        start_date: convertUTCToLocalFormat(
          dateFilter?.start_date.startOf("day")
        ),
        end_date: convertUTCToLocalFormat(dateFilter?.end_date.endOf("day")),
      };
      if (search) {
        query.name = search;
      }
      const res = await getIndentRequestListApi(query);
      setTableData(res?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchAllIntendRequest();
      scrollToTopTable(tableRef);
    };
    fetchData();
  }, [pageNumber, refresh, search, dateFilter, selectedStore, selectedStatus]);
  const statusOptions = [
    { label: "Open", value: "open" },
    { label: "Approved", value: "approved" },
    { label: "Received", value: "received" },
    { label: "On Hold", value: "on-hold" },
    { label: "Rejected", value: "rejected" },
    { label: "Dispatched", value: "dispatched" },
  ];

  const tagColors = {
    open: "processing",
    approved: "success",
    received: "gold",
    "on-hold": "volcano",
    rejected: "red",
    dispatched: "purple",
  };
  const tableColumns = [
    {
      title: "Request ID",
      dataIndex: "code",
      key: "code",
      width: 120,
      render: (val) => <Tag color="blue">{val}</Tag>,
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: 200,

      render: (val) => (val?.name ? val?.name : "-"),
    },
    {
      title: "Items",
      dataIndex: "inventory_list",
      key: "inventory_list",
      render: (val, record) => {
        if (!val || val.length === 0) return " - ";

        const items = val.map(
          (each) => each?.inventory?.name || each?.inventory_text || " - "
        );
        const displayedItems = items.slice(0, 3).join(", ");

        return items.length > 3 ? `${displayedItems} ...` : displayedItems;
      },
    },
    {
      title: "Requested By",
      dataIndex: "creator",
      key: "creator",
      render: (val) =>
        val ? `${val?.first_name || ""} ${val?.last_name || ""}` : "-",
    },
    {
      title: "Requested at",
      dataIndex: "created_at",
      key: "created_at",
      render: (val) =>
        val ? dayjs(convertUTCToLocalFormat(val)).fromNow() : "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 200,
      render: (val) => (
        <Tag
          icon={
            val === "open" ? (
              <UnlockOutlined />
            ) : val === "approved" ? (
              <RetweetOutlined />
            ) : val === "dispatched" ? (
              <LogoutOutlined />
            ) : val === "received" ? (
              <CheckCircleOutlined />
            ) : val === "on-hold" ? (
              <PauseCircleOutlined />
            ) : (
              <CloseOutlined />
            )
          }
          style={{ padding: "4px 8px", minWidth: "100px" }}
          color={tagColors[val?.toLowerCase()] || "default"}
        >
          {val.charAt(0).toUpperCase() + val.slice(1)}
        </Tag>
      ),
    },
  ];
  return (
    <CustomLayout
      header={
        <Flex justify="space-between">
          <Typography className={typoStyles.h6}>{`Indent Requests (${
            tableData?.totalIndentRequests || 0
          }) `}</Typography>
        </Flex>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}>
           

            <RangePicker
              format="DD-MMM-YYYY"
              value={[dateFilter.start_date, dateFilter.end_date]}
              onChange={(dates, dateString) => {
                setDateFilter({
                  start_date: dates?.[0],
                  end_date: dates?.[1],
                });
              }}
              allowClear={false}
              style={{ width: 300 }}
            />
          </Flex>
        </Col>
        <Col span={24}>
          <Table
            size="large"
            ref={tableRef}
            title={() => (
              <Space
                align="center"
                style={{
                  width: "100%",
                  justifyContent: "end",
                  gap: 16,
                }}
              >
                 <Select
                 size="large"
              showSearch
              style={{ width: 250 }}
              placeholder="Select Store"
              options={allStores}
              onChange={(val) => {
                setSelectedStore(val);
              }}
              filterOption={(input, option) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              allowClear
              onClear={() => {
                setSelectedStore(null);
              }}
            />
            <Select
            size="large"
              style={{ width: 150 }}
              placeholder="Select Status"
              options={statusOptions}
              onChange={(val) => {
                setSelectedStatus(val);
              }}
              allowClear
              onClear={() => {
                setSelectedStatus(null);
              }}
            />
                <Input.Search
                  size="large"
                  defaultValue={getSearchDefaultValue()}
                  onChange={debounce(handleSearch, 500)}
                  placeholder={`Search by request id`}
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                <Button
                  type="primary"
                  size="medium"
                  className={btnStyles.mdBtn}
                  onClick={() => {
                    navigate("/indent-requests/create");
                  }}
                >
                  {" "}
                  Raise an Indent
                </Button>
              </Space>
            )}
            columns={tableColumns}
            dataSource={tableData?.indentRequests}
            pagination={false}
            rowClassName
            scroll={{
              x: 1200,
              y: "60vh",
            }}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No request's found</Typography>}
                />
              ),
            }}
            bordered
            components={tableComponents}
            onRow={(item) => {
              return {
                onClick: (e) => {
                  navigate(`/indent-requests/${item?._id}`, {});
                },
                style: { cursor: "pointer" },
              };
            }}
          />
        </Col>
        {tableData?.totalIndentRequests > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={tableData?.totalIndentRequests}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default IntendRequest;
