import { Tag, message } from "antd";
import { CLOUD, ORGANISATION } from "../../../constants/defaultKeys";
import { getCookie } from "../../../helpers/localStorage";
import {
  generateRandomString,
  getAssetsPath,
  reverseCapitalizeWords,
} from "../../../helpers/utility";
import { sectionOptionsList } from "./AddElem/addElemHelper";
import {
  getAllAssetsApi,
  getAssetByIdApi,
} from "../../../services/assets.services";
import axios from "axios";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";

export const generateSOP = async (
  existingSOPs,
  storageType,
  dummyForm,
  assetDocs,
  selectedAsset,
  setLoading,
  setSopContent,
  setsopMetaData,
  setSopReady,
  setEmailModal,
  setEnableAI,
  contentType
) => {
    console.log("existingSOPs", existingSOPs);
  setLoading(true);

  if (!selectedAsset && existingSOPs?.length === 0) {
    setLoading(false);
    return;
  }

  let data = {};
  const type = dummyForm.getFieldValue("typeOfProc");
  console.log("existingSOPs", existingSOPs);
  if (existingSOPs?.length > 0) {
    data = {
      sop_type: type == "Repair" ? "Troubleshooting" : type,
      type: "existing-sop",
      issue: dummyForm.getFieldValue("troubleshooting_issue") || "",
      context: existingSOPs?.[0]?.context,
    };
  } else if (assetDocs?.length > 0) {
    data = {
      type: "related-docs",
      pdf_docs: assetDocs,
      make: selectedAsset?.make,
      model: selectedAsset?.model,
      asset_name: selectedAsset?.name,
      source: storageType === CLOUD ? "cloud" : "on-premise",
      org_id: getCookie(ORGANISATION),
      sop_type: type == "Repair" ? "Troubleshooting" : type,
      issue: dummyForm.getFieldValue("troubleshooting_issue") || "",
    };
  } else {
    data = {
      type: "make-model",
      make: selectedAsset?.make,
      model: selectedAsset?.model,
      asset_name: selectedAsset?.name,
      sop_type: type == "Repair" ? "Troubleshooting" : type,
      issue: dummyForm.getFieldValue("troubleshooting_issue") || "",
    };
  }

  try {
    const url = `${process.env.REACT_APP_BASE_URL_DOMAIN}/cmms-api/generate-sop/`;
    const resp = await axios.post(url, data);

    const newQues = resp?.data?.data?.procedures?.map((eachQ) => {
      const parentId = generateRandomString(12);
      const optionsList = sectionOptionsList[eachQ?.heading?.toLowerCase()];

      return {
        id: parentId,
        procedures: eachQ?.procedures?.map((eachPro) => {
          const updatedOptions = Object.keys(optionsList).reduce(
            (acc, option) => {
              acc[option] = {
                enable: [],
                helperText: eachPro.choices[reverseCapitalizeWords(option)],
                images: [],
                color: optionsList[option]?.color,
              };
              return acc;
            },
            {}
          );

          return {
            id: generateRandomString(12),
            type: "section",
            parentId,
            question: eachPro?.procedure,
            things: updatedOptions,
            options: Object.keys(optionsList) || ["Yes", "No"],
            images: [],
            answers: [],
          };
        }),
        type: "section",
        title: eachQ?.heading,
        answers: [],
      };
    });

    setsopMetaData((prev) => ({
      ...prev,
      name: resp?.data?.data?.title,
      description: resp?.data?.data?.description,
      usage_data: resp?.data?.usage_data,
    }));

    setSopContent(newQues);
    if (resp?.data?.data?.error) {
      message.error(resp?.data?.data?.error);
    }
    if (newQues) setSopReady(true);
  } catch (error) {
    message.error(error?.response?.data?.message || "Something went wrong!!");
  } finally {
    setLoading(false);
  }
};

export const createFileLists = (files, storageType) => {
  const fileList = files?.map((doc, index) => {
    return {
      uid: index,
      name: doc,
      status: "done",
      url: getAssetsPath(doc, storageType),
    };
  });
  return fileList;
};
export const getAssets = async (setAssets) => {
  try {
    const resp = await getAllAssetsApi();
    setAssets(
      resp?.data?.assets?.map(({ name, _id, code }) => ({
        value: _id,
        name: name,
        label: (
          <div
            style={{
              gap: 8,
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag className={tagStyles.smTag} color="blue">
              {code}
            </Tag>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {name}
            </span>
          </div>
        ),
      })) || []
    );
  } catch (error) {}
};

export const getAsset = async (id, setSelectedAsset) => {
  try {
    const resp = await getAssetByIdApi(id);
    setSelectedAsset(resp?.data);
  } catch (error) {}
};
