import React, { useContext, useEffect, useRef, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  Image,
  Input,
  Pagination,
  Result,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import {
  getMroBreakdownReportApi,
  getMroList,
} from "../../services/work-orders.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import {
  convertToUTCFormat,
  convertUTCToLocalFormat,
  debounce,
  displayFilterTag,
  displayTaskType,
  formatHours,
  getSearchDefaultValue,
  scrollToTopTable,
  tableComponents,
  workOrderstatusList,
} from "../../helpers/utility";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { SearchOutlined } from "@ant-design/icons";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { PAGESIZE } from "../../constants/defaultKeys";
import MroFilters from "./MroFilters";
import sortIcon from "../../assets/images/sortIcon1.svg";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import {
  getAllAssetsApi,
  getAssetBreakdownDownloadApi,
} from "../../services/assets.services";
import { UserContext } from "../../context/UserProvider";
import { getItem, removeItem, setItem } from "../../helpers/localStorage";

const { RangePicker } = DatePicker;

const MRO = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [search, setSearch] = useState("");
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(2, "month"),
    end_date: dayjs(),
  });
  const [refresh, setRefresh] = useState(false);
  const [selectedSorter, setSelectedSorter] = useState(undefined);
  const [filters, setFilters] = useState({
    asset: undefined,
    severity: undefined,
    typeObj: [],
    status: undefined,
  });
  const [assets, setAssets] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const { userDetails } = useContext(UserContext);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchMroList = async (data, clearLocStorage) => {
    let query = { page: data?.pageNumber };
    data?.search && (query.name = data?.search);
    if (data?.dateFilter?.start_date) {
      query.start_date = convertToUTCFormat(
        data?.dateFilter.start_date.startOf("day")
      );
    }
    if (data?.dateFilter?.end_date) {
      query.end_date = convertToUTCFormat(
        data?.dateFilter.end_date.endOf("day")
      );
    }
    data?.filters?.asset && (query.asset = data?.filters?.asset);
    data?.filters?.severity && (query.severity = data?.filters?.severity);
    data?.filters?.status && (query.status = data?.filters?.status);
    data?.filters?.typeObj?.length > 0 &&
      (query.typeObj = data?.filters?.typeObj);
    if (data?.selectedSorter) {
      query.sort_by = data?.selectedSorter;
      query.sort_order = "desc";
    }

    setLoading(true);
    try {
      const res = await getMroList(query);
      setData(res?.data);
    } catch (error) {
      setData(null);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  useEffect(() => {
    fetchAssets();
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setDateFilter({
        start_date: locStorageData?.dateFilter?.start_date
          ? dayjs(locStorageData?.dateFilter?.start_date)
          : null,
        end_date: locStorageData?.dateFilter?.end_date
          ? dayjs(locStorageData?.dateFilter?.end_date)
          : null,
      });
      setFilters({
        asset: locStorageData?.filters?.asset || undefined,
        severity: locStorageData?.filters?.severity || undefined,
        typeObj: locStorageData?.filters?.typeObj || [],
        status: locStorageData?.filters?.status || undefined,
      });
      locStorageData?.selectedSorter &&
        setSelectedSorter(locStorageData?.selectedSorter);
      locStorageData?.search && setSearch(locStorageData?.search);
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  useEffect(() => {
    const fetchData = async (data, status = false) => {
      await fetchMroList(data, status);
      scrollToTopTable(tableRef);
    };
    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      fetchData(
        {
          ...locStorageData,
          dateFilter: {
            start_date: locStorageData?.dateFilter?.start_date
              ? dayjs(locStorageData?.dateFilter?.start_date)
              : null,
            end_date: locStorageData?.dateFilter?.end_date
              ? dayjs(locStorageData?.dateFilter?.end_date)
              : null,
          },
        },
        true
      );
    } else {
      fetchData({
        dateFilter: dateFilter,
        filters: filters,
        selectedSorter: selectedSorter,
        search: search,
        pageNumber: pageNumber,
      });
    }
  }, [pageNumber, refresh]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [search, dateFilter, filters, selectedSorter]);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const handleBreakdownDownload = async () => {
    let query = {};
    if (dateFilter?.start_date) {
      query.start_date = convertToUTCFormat(
        dateFilter.start_date.startOf("day")
      );
    }
    if (dateFilter?.end_date) {
      query.end_date = convertToUTCFormat(dateFilter.end_date.endOf("day"));
    }
    try {
      setDownloading(true);
      const res = await getMroBreakdownReportApi(query);
      const url = window.URL.createObjectURL(new Blob([res?.data]));

      const a = document.createElement("a");
      a.href = url;
      a.download = "mro-break-down-report.xlsx";
      document.body.appendChild(a);
      a.click();

      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  const saveMroFilters = () => {
    let dataObj = {
      dateFilter: dateFilter,
      filters: filters,
      selectedSorter: selectedSorter,
      search: search,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  const tableColumns = [
    {
      title: "MRO code",
      dataIndex: "code",
      key: "code",
      width: 120,
      fixed: "left",
      render: (val, record) =>
        val ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() => {
              navigate(`/mro/${record?._id}`);
              saveMroFilters();
            }}
          >
            {val}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: "WO code",
      dataIndex: "workorders",
      key: "workorders",
      width: 120,
      fixed: "left",
      render: (values) =>
        values?.[0]?.code ? (
          <Typography
            style={{ color: "#1677ff", cursor: "pointer" }}
            onClick={() => {
              navigate(`/work-orders/${values?.[0]?._id}`, {
                state: { source: `/mro` },
              });
              saveMroFilters();
            }}
          >
            {values?.[0]?.code}
          </Typography>
        ) : (
          "-"
        ),
    },
    {
      title: "MRO breakdown severity",
      dataIndex: "severity",
      key: "severity",
      width: 150,
      render: (val) =>
        val ? (
          <Tag
            className={tagStyles.mdRndDotTag}
            style={{ borderRadius: 16, marginTop: 10 }}
            color={val === "critical" ? "error" : "warning"}
          >
            {val === "critical" ? "Critical" : "Non Critical"}
          </Tag>
        ) : (
          "-"
        ),
    },
    {
      title: "MRO breakdown time",
      dataIndex: "mro_duration",
      key: "mro_duration",
      width: 200,
      render: (val, record) => {
        if (record?.severity) {
          if (record?.severity === "critical") {
            if (record?.workorders?.[0]?.status === "closed") {
              let durationObj = formatHours(val ? val : 0);
              return (
                <Tag className={tagStyles.smRndTag} color="error">
                  {durationObj?.days ? `${durationObj?.days} days ` : ""}
                  {`${durationObj?.hours} hrs`}
                  {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
                </Tag>
              );
            } else {
              return <Typography>MRO is not yet resolved</Typography>;
            }
          } else {
            return <Typography>Not a breakdown</Typography>;
          }
        } else {
          return "-";
        }
      },
    },
    {
      title: "WO completion time",
      dataIndex: "workorders",
      key: "workorders",
      width: 200,
      render: (values) => {
        if (values?.[0]?.started_at && values?.[0]?.ended_at) {
          let start = dayjs(convertUTCToLocalFormat(values?.[0]?.started_at));
          let end = dayjs(convertUTCToLocalFormat(values?.[0]?.ended_at));
          let durationInHours = end.diff(start, "hour", true);
          let durationObj = formatHours(durationInHours);

          return (
            <Tag className={tagStyles.smRndTag} color="blue">
              {durationObj?.days ? `${durationObj?.days} days ` : ""}
              {`${durationObj?.hours} hrs`}
              {!durationObj?.days ? ` ${durationObj?.minutes} mins` : ""}
            </Tag>
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Type",
      dataIndex: "typeObj",
      key: "typeObj",
      width: 140,
      render: (val) => (val?.name ? displayTaskType(val?.name) : "-"),
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      width: 250,
      render: (val) => val?.name ?? "-",
    },
    {
      title: "MRO description",
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (val) => (val ? val : "-"),
    },
    {
      title: "MRO audio",
      dataIndex: "file",
      key: "file",
      width: 280,
      render: (val) =>
        val ? (
          <audio controls style={{ width: "100%" }}>
            <source src={val} type="audio/mpeg" />
          </audio>
        ) : (
          "-"
        ),
    },
    {
      title: "MRO created by",
      dataIndex: "creator",
      key: "creator",
      width: 150,
      render: (values) =>
        values?.first_name || values?.last_name
          ? `${values?.first_name || ""} ${values?.last_name || ""}`
          : "-",
    },
    {
      title: "MRO created at",
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).fromNow()
            : "-"}
        </>
      ),
    },
    // {
    //   title: "MRO expected to start at",
    //   dataIndex: "start_date",
    //   key: "start_date",
    //   width: 160,
    //   render: (val, record) => (
    //     <>
    //       {val
    //         ? dayjs(convertUTCToLocalFormat(val)).fromNow()
    //         : "-"}
    //     </>
    //   ),
    // },
    {
      title: "WO started at",
      dataIndex: "workorders",
      key: "workorders",
      width: 160,
      render: (values, record) => (
        <>
          {values?.[0]?.started_at
            ? dayjs(convertUTCToLocalFormat(values?.[0]?.started_at)).fromNow()
            : "-"}
        </>
      ),
    },
    {
      title: "WO closed at",
      dataIndex: "workorders",
      key: "workorders",
      width: 160,
      render: (values, record) => (
        <>
          {values?.[0]?.ended_at
            ? dayjs(convertUTCToLocalFormat(values?.[0]?.ended_at)).fromNow()
            : "-"}
        </>
      ),
    },
    {
      title: "MRO resolved at",
      dataIndex: "ended_at",
      key: "ended_at",
      width: 160,
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).fromNow()
            : "-"}
        </>
      ),
    },
    {
      title: "WO status",
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) => {
        const value = workOrderstatusList?.filter(
          (status) => status?.value == values?.[0]?.status
        )?.[0];
        return (
          <>
            {value?.label ? (
              <Tag
                className={tagStyles.smTag}
                color={value?.color}
                style={{
                  minWidth: "80px",
                  textAlign: "left",
                }}
              >
                {value?.label}
              </Tag>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "WO closed by",
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) =>
        values?.[0]?.closed_by?.first_name || values?.[0]?.closed_by?.last_name
          ? `${values?.[0]?.closed_by?.first_name || ""} ${
              values?.[0]?.closed_by?.last_name || ""
            }`
          : "-",
    },
    {
      title: "WO verification",
      title: <Typography className={"tableHeader"}>WO verification</Typography>,
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) => (
        <Tag
          className={tagStyles.smTag}
          color={values?.[0]?.mark_as_verified ? "success" : "error"}
          style={{ minWidth: "90px", border: "0px" }}
        >
          {values?.[0]?.mark_as_verified ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: "WO verified by",
      dataIndex: "workorders",
      key: "workorders",
      width: 140,
      render: (values) =>
        values?.[0]?.verified_by?.first_name ||
        values?.[0]?.verified_by?.last_name
          ? `${values?.[0]?.verified_by?.first_name || ""} ${
              values?.[0]?.verified_by?.last_name || ""
            }`
          : "-",
    },
    {
      title: "MRO status",
      dataIndex: "status",
      key: "status",
      width: 140,
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val === "resolved" ? "#87d068" : "#f50"}
          style={{
            minWidth: "95px",
            textAlign: "left",
          }}
        >
          {val === "resolved" ? "Resolved" : "Not Resolved"}
        </Tag>
      ),
    },
    {
      title: "MRO verification",
      dataIndex: "mark_as_verified",
      key: "mark_as_verified",
      width: 140,
      render: (val) => (
        <Tag
          className={tagStyles.smTag}
          color={val ? "success" : "error"}
          style={{ minWidth: "90px", border: "0px" }}
        >
          {val ? "Verified" : "Not Verified"}
        </Tag>
      ),
    },
    {
      title: "MRO verified by",
      dataIndex: "verifier",
      key: "verifier",
      width: 140,
      render: (val, record) =>
        record?.mark_as_verified && (val?.first_name || val?.last_name)
          ? `${val?.first_name || ""} ${val?.last_name || ""}`
          : "-",
    },
  ];

  const sortOptions = [
    {
      label: "MRO breakdown time",
      value: "mro_duration",
    },
  ];

  return (
    <CustomLayout
      header={
        <Typography className={typoStyles.h6}>
          MROs - Maintenance, Repair & Operations ({data?.totalMros || 0})
        </Typography>
      }
    >
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <Space
            style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
          >
            <Button
              style={{
                color: "#6941C6",
              }}
              className={btnStyles.smBtn}
              type="default"
              icon={
                <Image
                  src={filterActiveIcon}
                  width={18}
                  height={18}
                  alt="filter"
                  preview={false}
                />
              }
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
            <div style={{ position: "relative" }}>
              <Select
                style={{
                  width: 220,
                }}
                className="custom-select"
                placeholder="Sort by"
                allowClear
                value={selectedSorter}
                options={sortOptions}
                onChange={(e) => setSelectedSorter(e)}
              />
              <div
                style={{
                  position: "absolute",
                  left: 8,
                  top: 6,
                }}
              >
                <Image
                  src={sortIcon}
                  width={18}
                  height={18}
                  alt="sorter"
                  preview={false}
                />
              </div>
            </div>
          </Space>
          {showFilterModal && (
            <MroFilters
              showFilterModal={showFilterModal}
              setShowFilterModal={setShowFilterModal}
              assets={assets}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Col>
        <Col offset={4} span={10} style={{ textAlign: "right" }}>
          <Space>
            <Button
              type="primary"
              onClick={() => handleBreakdownDownload()}
              loading={downloading}
            >
              Download Breakdown Report
            </Button>
            <RangePicker
              id="mro-filter"
              format="DD-MMM-YYYY"
              value={[dateFilter.start_date, dateFilter.end_date]}
              onChange={(dates, dateString) => {
                setDateFilter({
                  start_date: dates?.[0],
                  end_date: dates?.[1],
                });
              }}
            />
          </Space>
        </Col>
        {(filters?.asset ||
          filters?.typeObj?.length > 0 ||
          filters?.severity ||
          filters?.status) && (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 6,
              flexWrap: "wrap",
            }}
          >
            {filters?.asset && displayFilterTag("Asset", "purple")}
            {filters?.typeObj?.length > 0 &&
              displayFilterTag("MRO Type", "volcano", filters?.typeObj?.length)}
            {filters?.severity && displayFilterTag("MRO Severity", "magenta")}
            {filters?.status && displayFilterTag("MRO Status", "blue")}
            <Typography
              style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
              onClick={() =>
                setFilters({
                  asset: undefined,
                  severity: undefined,
                  typeObj: [],
                  status: undefined,
                })
              }
            >
              Clear All
            </Typography>
          </Col>
        )}
        <Col span={24}>
          <Table
            ref={tableRef}
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "flex-end", gap: 16 }}
              >
                <Input.Search
                  size="large"
                  defaultValue={getSearchDefaultValue()}
                  onChange={debounce(handleSearch, 500)}
                  placeholder="Search by MRO code"
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                {(userDetails?.activeOrg?.role === "admin" ||
                  userDetails?.activeOrg?.group_types?.length === 0 ||
                  userDetails?.activeOrg?.group_types?.includes(
                    "Production"
                  )) && (
                  <Button
                    id="create-mro"
                    type="primary"
                    className={btnStyles.mdBtn}
                    onClick={() => navigate("/mro/create")}
                  >
                    + Create MRO
                  </Button>
                )}
              </Space>
            )}
            scroll={{
              x: 1200,
              y: "55vh",
            }}
            locale={{
              emptyText: loading ? (
                <Skeleton active />
              ) : (
                <Result
                  icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  title={<Typography>No MROs found</Typography>}
                />
              ),
            }}
            loading={
              loading
                ? {
                    spinning: false,
                    indicator: null,
                  }
                : false
            }
            bordered
            columns={tableColumns}
            dataSource={data?.mros || []}
            pagination={false}
            components={tableComponents}
          />
        </Col>
        {data?.totalMros > PAGESIZE && (
          <Col span={24} className="text-center">
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={data?.totalMros}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
      </Row>
    </CustomLayout>
  );
};

export default MRO;
