import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { convertUTCToLocalFormat } from "../../../helpers/utility";

const ReturnToForm = ({
  selectedAllocation,
  showDrawer,
  form2,
  onClose,
  onFinish,
}) => {
  const disableDate = (current) => {
    const now = dayjs();
    const issuedDate = selectedAllocation?.issued_date 
      ? dayjs(convertUTCToLocalFormat(selectedAllocation.issued_date))
      : null;

    // Disable future dates
    if (current.isAfter(now, 'day')) {
      return true;
    }

    // Disable past dates (before issued date)
    if (issuedDate) {
      return current.isBefore(issuedDate, 'day');
    }

    return false;
  };

  const disableTime = (selectedDate) => {
    const now = dayjs();
    const selectedDay = selectedDate.startOf('day');
    const nowDay = now.startOf('day');
    const issuedDate = selectedAllocation?.issued_date 
      ? dayjs(convertUTCToLocalFormat(selectedAllocation.issued_date))
      : null;
  
    if (!issuedDate) {
      return {
        disabledHours: () => range(0, 23),
        disabledMinutes: () => range(0, 59)
      };
    }
  
    const issuedDay = issuedDate.startOf('day');
  
    // Disable all times for future dates (after today)
    if (selectedDay.isAfter(nowDay)) {
      return {
        disabledHours: () => range(0, 23),
        disabledMinutes: () => range(0, 59)
      };
    }
  
    // Disable all times if selected date is before issued date
    if (selectedDay.isBefore(issuedDay)) {
      return {
        disabledHours: () => range(0, 23),
        disabledMinutes: () => range(0, 59)
      };
    }
  
    // Disable times before issued time if selected date is the issued date
    if (selectedDay.isSame(issuedDay)) {
      return {
        disabledHours: () => {
          const issuedHour = issuedDate.hour();
          return range(0, issuedHour - 1);
        },
        disabledMinutes: (hour) => {
          if (hour === issuedDate.hour()) {
            const issuedMinute = issuedDate.minute();
            return range(0, issuedMinute - 1);
          }
          return [];
        }
      };
    }
  
    // Disable future times when selected date is today
    if (selectedDay.isSame(nowDay)) {
      return {
        disabledHours: () => {
          const currentHour = now.hour();
          return range(currentHour + 1, 23);
        },
        disabledMinutes: (hour) => {
          if (hour === now.hour()) {
            const currentMinute = now.minute();
            return range(currentMinute + 1, 59);
          }
          return [];
        }
      };
    }
  
    return {};
  };
  
  // Helper function to generate ranges
  function range(start, end) {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
  }
  
  
  return (
    <Drawer
      width={500}
      title="Returned from"
      onClose={onClose}
      open={showDrawer}
      footer={
        <Flex gap={10} justify="flex-end">
          <Button onClick={onClose}>Close</Button>
          <Button
            type="primary"
            form="issue-form"
            onClick={() => form2.submit()}
          >
            Submit
          </Button>
        </Flex>
      }
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>Store</Typography.Text>
            <Typography.Text>{selectedAllocation?.store?.name}</Typography.Text>
          </Flex>
        </Col>
        <Col span={24}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>Task</Typography.Text>
            {selectedAllocation?.occurrence?.code ? (
              <Space direction="horizontal" size={10}>
                <Tag color="blue">{selectedAllocation?.occurrence?.code}</Tag>
                <Typography.Text>
                  {dayjs(
                    convertUTCToLocalFormat(
                      selectedAllocation?.occurrence?.occurrence_date
                    )
                  ).format("YYYY-MM-DD")}
                </Typography.Text>
              </Space>
            ) : (
              <Tag color="blue" style={{ width: "fit-content" }}>
                {selectedAllocation?.workorder?.code}
              </Tag>
            )}
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Issued To
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.issued_to?.first_name}{" "}
              {selectedAllocation?.issued_to?.last_name || ""}
            </Typography.Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            {" "}
            <Typography.Text style={{ fontSize: 12 }}>
              Issued date
            </Typography.Text>
            <Typography.Text>
              {" "}
              {selectedAllocation?.issued_date
                ? dayjs(
                    convertUTCToLocalFormat(selectedAllocation?.issued_date)
                  ).format("DD MMM hh:mm A")
                : "-"}
            </Typography.Text>
          </Flex>
        </Col>

        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Spare part
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.inventory?.name || "-"}
            </Typography.Text>
          </Flex>
        </Col>
        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Issued quantity
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.issued_quantity}
            </Typography.Text>
          </Flex>
        </Col>

        {selectedAllocation?.returned_date && (
          <Col span={12}>
            <Flex gap={10} vertical>
              <Typography.Text style={{ fontSize: 12 }}>
                Last returned date
              </Typography.Text>
              <Typography.Text>
                {selectedAllocation?.returned_date
                  ? dayjs(
                      convertUTCToLocalFormat(selectedAllocation?.returned_date)
                    ).format("DD MMM hh:mm A")
                  : "-"}
              </Typography.Text>
            </Flex>
          </Col>
        )}
        {selectedAllocation?.returned_quantity && (
          <Col span={12}>
            <Flex gap={10} vertical>
              <Typography.Text style={{ fontSize: 12 }}>
                Returned quantity
              </Typography.Text>
              <Typography.Text>
                {selectedAllocation?.returned_quantity || "-"}
              </Typography.Text>
            </Flex>
          </Col>
        )}

        <Col span={12}>
          <Flex gap={10} vertical>
            <Typography.Text style={{ fontSize: 12 }}>
              Balance quantity
            </Typography.Text>
            <Typography.Text>
              {selectedAllocation?.balance_quantity || "-"}
            </Typography.Text>
          </Flex>
        </Col>
      </Row>
      <Form
        form={form2}
        layout="vertical"
        onFinish={onFinish}
        style={{ marginTop: 20 }}
        initialValues={{
          returned_date: dayjs(),
        }}
      >
        <Form.Item
          label="Returned date"
          name="returned_date"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  callback();
                } else {
                  callback("Please select a date");
                }
              },
            },
          ]}
        >
          <DatePicker
            format="YYYY-MM-DD HH:mm"
            // onChange={() => form1.setFieldsValue({ issue_time: null })}
            placeholder="Select start date & time"
            size="medium"
            showTime={{
              defaultValue: dayjs(),
            }}
            allowClear={false}
            style={{
              width: "100%",
            }}
            disabledDate={disableDate}
            disabledTime={disableTime}
          />
        </Form.Item>
        <Form.Item
          label="Returned quantity"
          name="returned_quantity"
          rules={[
            {
              validator: (_, value, callback) => {
                if (value && value !== "") {
                  if (/^[0-9]+$/.test(value)) {
                    if (Number(value) <= 0) {
                      callback("Please enter a valid quantity greater than 0");
                    } else if (
                      Number(value) > selectedAllocation?.balance_quantity
                    ) {
                      callback(
                        "Returned quantity cannot be greater than balance quantity"
                      );
                    } else {
                      callback();
                    }
                  } else {
                    callback("Please enter a valid number");
                  }
                } else {
                  callback("Please enter quantity");
                }
              },
            },
          ]}
        >
          <Input
            placeholder="Enter returned quantity"
            style={{ width: "100%" }}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ReturnToForm;
