import React, { useCallback, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import {
  Col,
  DatePicker,
  Flex,
  message,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import {
  convertUTCToLocalFormat,
  debounce,
  tableComponents,
} from "../../helpers/utility";
import {
  getInventoryList,
  getInventoryLogsApi,
  getStoreListApi,
} from "../../services/inventory.services";
import dayjs from "dayjs";
import { PAGESIZE } from "../../constants/defaultKeys";

const { RangePicker } = DatePicker;
const InventoryLogs = () => {
  const [searchValue, setSearchValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [inventoryLogs, setInventoryLogs] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(1, "week").startOf("day"),
    end_date: dayjs().endOf("day"),
  });
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  useEffect(() => {
    fetchStores();
  }, []);
  // useEffect(() => {
  //   if (pageNumber === 1) {
  //     setRefresh((prev) => !prev);
  //   } else {
  //     setPageNumber(1);
  //   }
  // }, [selectedInventory]);

  const fetchInventoryOptions = useCallback(
    debounce(async (query) => {
      try {
        const res = await getInventoryList(query);
        setOptions(
          res?.data?.inventoryItems?.map((item) => ({
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography.Text>{item?.name}</Typography.Text>{" "}
                <Tag color="blue"> {item?.code}</Tag>
              </div>
            ),
            value: item?._id,
            name: item?.name,
            code: item?.code,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchValue) {
      fetchInventoryOptions({ name: searchValue });
    } else {
      fetchInventoryOptions({ page: 1 });
    }
  }, [searchValue]);

  useEffect(() => {
    console.log(selectedInventory);
    setPageNumber(1);
    setRefresh((prev) => !prev);
  }, [selectedInventory, selectedStore, dateFilter]);

  useEffect(() => {
    fetchInventoryLogs();
  }, [refresh, pageNumber]);

  const fetchStores = async () => {
    try {
      const resp = await getStoreListApi();
      const storeData =
        resp?.data?.map((store) => ({
          label: store?.name,
          value: store?._id,
        })) || [];
      setStoreList(storeData);
    } catch (error) {
      console.error("Error fetching stores:", error);
      setStoreList([]);
    }
  };
  const fetchInventoryLogs = async () => {
    console.log("selectedInventory", selectedInventory);
    try {
      let query = {
        page: pageNumber,
        inventory: selectedInventory,
        store: selectedStore,
      };
      if (dateFilter?.start_date) {
        query.start_date = convertUTCToLocalFormat(
          dateFilter.start_date.startOf("day")
        );
      }
      if (dateFilter?.end_date) {
        query.end_date = convertUTCToLocalFormat(
          dateFilter.end_date.endOf("day")
        );
      }
      const res = await getInventoryLogsApi(query);
      if (res?.data) {
        setInventoryLogs(res?.data);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const tableColumns = [
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: "200px",
      render: (val) => val?.name || "-",
    },
    {
      title: "Spare part name",
      dataIndex: "spare_part_name",
      key: "spare_part_name",
      width: "200px",
      render: (_, record) => record?.inventory?.name || "-",
    },
    {
      title: "Spare part code",
      dataIndex: "inventory",
      key: "inventory",
      width: 200,
      fixed: "left",
      render: (val) => <span>{val?.code ? val?.code : "-"}{val?.high_value ? <Tag style={{marginLeft: 10}} color="success">High value</Tag> : ""}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      width: "130px",
      render: (val) => {
        return (
          <div>
            {val ? (
              <Tag size="large" color="blue">
                {val.toUpperCase()}
              </Tag>
            ) : (
              " - "
            )}
          </div>
        );
      },
    },
    {
      title: "User",
      dataIndex: "action_taken_by",
      key: "action_taken_by",
      width: "250px",

      render: (val) => {
        return (
          <div>
            {val?.first_name || " - "} {val?.last_name || ""}
          </div>
        );
      },
    },
    {
      title: "Timestamp",
      dataIndex: "created_at",
      key: "created_at",
      width: "160px",

      render: (val) => {
        return (
          <div>{val ? dayjs(convertUTCToLocalFormat(val)).fromNow() : "-"}</div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "message",
      key: "message",
      width: "270px",
      render: (val) => {
        return <div>{val || "-"}</div>;
      },
    },
  ];

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 35,
            paddingRight: "30px",
          }}
        >
          <Flex gap={30}>
            <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
              Logs
            </Typography>
          </Flex>
        </div>
      }
    >
      <Row gutter={[16, 16]}>
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 10,
          }}
        >
          <Space>
            <Select
              size="middle"
              allowClear
              showSearch
              placeholder="Search Store"
              optionFilterProp="name"
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
              onSelect={(selectedValue) => setSelectedStore(selectedValue)}
              style={{ width: 200 }}
              options={storeList}
              onChange={setSelectedStore}
              onClear={() => {
                setSelectedStore(null);
                // setInventoryLogs(null);
              }}
            />
            <Select
              size="middle"
              allowClear
              showSearch
              placeholder="Search Inventory"
              optionFilterProp="name"
              filterOption={(input, option) =>
                (option?.name ?? "").toLowerCase().includes(input.toLowerCase())
              }
              onSelect={(selectedValue) => setSelectedInventory(selectedValue)}
              style={{ width: 300 }}
              options={options}
              onSearch={setSearchValue}
              onChange={setSelectedInventory}
              // onSearch={fetchInventoryOptions}
              // notFoundContent={searchValue ? "No Inventory found" : null}
              onClear={() => {
                setSearchValue("");

                setSelectedInventory(null);
                // setInventoryLogs(null);
              }}
            />
          </Space>

          <RangePicker
            format="DD-MMM-YYYY"
            value={[dateFilter.start_date, dateFilter.end_date]}
            onChange={(dates, dateString) => {
              setDateFilter({
                start_date: dates?.[0],
                end_date: dates?.[1],
              });
            }}
          />
        </Col>
        {/* {inventoryLogs?.inventoryLogs && */}
        <Col span={24}>
          <Table
            bordered
            scroll={{
              x: 1000,
              y: "70vh",
            }}
            columns={tableColumns}
            dataSource={inventoryLogs?.inventoryLogs || []}
            components={tableComponents}
            pagination={false}
          />
        </Col>
        {inventoryLogs?.totalInventoryLogs > PAGESIZE && (
          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              current={pageNumber}
              pageSize={PAGESIZE}
              total={inventoryLogs?.totalInventoryLogs}
              onChange={(e) => setPageNumber(e)}
              showSizeChanger={false}
            />
          </Col>
        )}
        {/* } */}
      </Row>
    </CustomLayout>
  );
};

export default InventoryLogs;
