import {
  Button,
  Card,
  Form,
  Input,
  Modal,
  message,
  Popconfirm,
  Table,
  Flex,
} from "antd";
import React, { useEffect, useState } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import {
  addInventoryCategoryApi,
  deleteInventoryCategoryApi,
  getInventoryCategoriesListApi,
  updateInventoryCategoryApi,
} from "../../services/inventory.services";
import dayjs from "dayjs";
import {
  convertUTCToLocalFormat,
  tableComponents,
} from "../../helpers/utility";
const CategoryList = ({ setTotalCategories }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [categories, setCategories] = useState();
  const [refreshCategories, setRefreshCategories] = useState(false);

  useEffect(() => {
    fetchCategoriesList();
  }, [refreshCategories]);

  const fetchCategoriesList = async () => {
    try {
      const resp = await getInventoryCategoriesListApi();
      setCategories(resp?.data || []);
      setTotalCategories(resp?.data?.length || 0);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const tableColumns = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "name",
      render: (val) => (val ? val : "-"),
    },
    {
      title: " Inventory Count",
      dataIndex: "inventoryCount",
      key: "inventoryCount",
      width: 250,
      render: (val) => (val ? val : 0),
    },
    {
      title: "Created By",
      dataIndex: "creator",
      key: "creator",
      render: (val) =>
        val?.first_name
          ? `${val?.first_name || ""} ${val?.last_name || ""}`
          : "-",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (val) =>
        val ? dayjs(convertUTCToLocalFormat(val)).fromNow() : "-",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Flex gap={15}>
          <EditOutlined onClick={() => handleEdit(record)} />
          <Popconfirm
            title="Are you sure you want to delete this category?"
            onConfirm={() => handleDelete(record?._id)}
          >
            <DeleteOutlined />
          </Popconfirm>
        </Flex>
      ),
    },
  ];

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    form.resetFields();

    setIsModalOpen(false);
    setEdit(false);
    setSelectedCategory(null);
  };

  const handleEdit = (item) => {
    setEdit(true);
    setSelectedCategory(item);
    form.setFieldsValue({ name: item.name });
    showModal();
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteInventoryCategoryApi(id);
      if (res) {
        message.success("Category deleted successfully!");
        setRefreshCategories((prev) => !prev);
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      message.error(error?.response?.data?.message);
    }
  };

  const handleSubmit = async (values) => {
    try {
      if (edit && selectedCategory) {
        const response = await updateInventoryCategoryApi(
          selectedCategory?._id,
          values
        );
        if (response) {
          message.success("Category updated successfully!");
          setRefreshCategories((prev) => !prev);
        }
      } else {
        const response = await addInventoryCategoryApi(values);
        if (response) {
          message.success("Category added successfully!");
          setRefreshCategories((prev) => !prev);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      console.error("Error adding category:", error);
    } finally {
      handleCancel();
    }
  };

  return (
    <Card style={{ width: "100%" }} styles={{ body: { padding: 0 } }}>
      <Table
        columns={tableColumns}
        bordered
        pagination={false}
        title={() => (
          <div style={{ textAlign: "right", paddingTop: "5px" }}>
            <Button
              className={btnStyles.mdBtn}
              type="primary"
              onClick={() => {
                setEdit(false);
                showModal();
              }}
            >
              + Add Category
            </Button>
          </div>
        )}
        size="medium"
        dataSource={categories}
        components={tableComponents}
      />
      <Modal
        title={edit ? "Edit Category" : "Add Category"}
        footer={null}
        open={isModalOpen}
        onCancel={handleCancel}
      >
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Form.Item
            label="Category Name"
            name="name"
            rules={[{
              validator: (_, value, callback) => {
                if (!value) callback("Please provide category name!");
                else if (value?.trim()?.length < 3)
                  return callback("Provide a name of at least 3 alphabets");
                else if (value?.trim()?.length > 30)
                  return callback("Provide a name of less than 30 alphabets");
                else if (value?.trim()?.length === 0)
                  return callback("Please provide category name!");
                else callback();
              }
             }]}
          >
            <Input placeholder="Enter Category Name" />
          </Form.Item>
          <Form.Item style={{ textAlign: "right" }}>
            <Button
              type="default"
              style={{ marginRight: "10px" }}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              {edit ? "Update" : "Submit"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default CategoryList;
