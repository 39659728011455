import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  createAllocationApi,
  getAllocationListApi,
  getInventoryList,
  getStoreListApi,
  updateAllocationApi,
} from "../../../services/inventory.services";
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { formatNumber } from "../../../helpers/utility";
import tagStyles from "../../../assets/styles/Custom/Tag.module.css";
import inputStyles from "../../../assets/styles/Custom/Input.module.css";

const SparePartList = ({
  productionLines,
  plId,
  assetId,
  orderId,
  type,
  setAllocationLength,
  isClosed,
}) => {
  
  const [allocationList, setAllocationList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [currEntry, setCurrEntry] = useState(null);
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [addNewEntry, setAddNewEntry] = useState(false);
  // const [selectedAllocation, setSelectedAllocation] = useState(null);
  const [storesList, setStoresList] = useState([]);
  const allocations = Form.useWatch("allocations", addForm) || [];
  const [inventoryOptions, setInventoryOptions] = useState([]);
  const [selectedPlAssets, setSelectedPlAssets] = useState([]);
  const [invAllocationMap, setInvAllocationMap] = useState({});
  const [currEditQuantity, setCurrEditQuantity] = useState(0);
  const [assetMap, setAssetMap] = useState({});

  useEffect(() => {
    fetchStores();
  }, []);

  useEffect(() => {
    if (plId) {
      let plObject = productionLines?.filter((i) => i?.value === plId)?.[0];
      setSelectedPlAssets(
        plObject?.assets?.map((p) => ({
          label: p?.asset?.name,
          value: p?.asset?._id,
        }))
      );
    } else {
      setSelectedPlAssets([]);
    }
  }, [plId, productionLines]);

  const fetchStores = async () => {
    try {
      const res = await getStoreListApi();
      let stores = res?.data?.map((s) => ({
        label: s?.name,
        value: s?._id,
      }));
      setStoresList(stores);
    } catch (error) {
      setStoresList([]);
    }
  };

  const fetchInventoryList = async (store) => {
    try {
      const res = await getInventoryList({ store });
      let inventories = res?.data?.inventoryItems?.map((s) => ({
        label: s?.name,
        value: s?._id,
        cost: s?.cost,
        available_quantity: s?.available_quantity,
        high_value: s?.high_value,
      }));
      return inventories;
    } catch (error) {
      return [];
    }
  };

  const handleStoreChange = async (storeId, index) => {
    const inventories = await fetchInventoryList(storeId);
    setInventoryOptions((prev) => ({
      ...prev,
      [storeId]: inventories,
    }));
    addForm.setFieldsValue({
      allocations: [
        ...addForm.getFieldValue("allocations").map((item, i) =>
          i === index
            ? {
                ...item,
                available_quantity: undefined,
                inventory: undefined,
                total_cost: undefined,
              }
            : item
        ),
      ],
    });
  };

  useEffect(() => {
    const fetchAndSetInventory = async () => {
      const updatedInventory = {};
      for (let i = 0; i < allocations.length; i++) {
        const store = allocations[i]?.store;
        if (store && !inventoryOptions[store]) {
          updatedInventory[store] = await fetchInventoryList(store);
        }
      }
      setInventoryOptions((prev) => ({ ...prev, ...updatedInventory }));
    };

    fetchAndSetInventory();
  }, [allocations]);

  useEffect(() => {
    if (currEntry) {
      editForm.setFieldsValue({
        used_quantity: currEntry?.used_quantity,
        total_cost:
          Number(currEntry?.used_quantity || 0) *
          Number(currEntry?.inventory?.cost || 0),
      });
    }
  }, [currEntry]);

  const fetchAllocationList = async () => {
    let query = {};
    if (type === "WO") {
      query = { ...query, workorder: orderId };
    } else if (type === "PM") {
      query = { ...query, occurrence: orderId };
    }

    try {
      const resp = await getAllocationListApi(query);

      let allocations = [],
        allocationMap = {},
        issuedStores = [],
        storeIds = [],
        assetTempMap = {};

      resp?.data?.allocations?.map((item) => {
        let issued_remaining =
          (item?.issued_quantity || 0) -
          (item?.total_used_quantity || 0) -
          (item?.returned_quantity || 0);

        let balance_quantity =
          item?.available_quantity +
          (issued_remaining > 0 ? issued_remaining : 0);
        let allocationObj = {
          _id: item?._id,
          store: item?.store,
          inventory: item?.inventory,
          issued_quantity: issued_remaining > 0 ? issued_remaining : 0,
        };

        if (allocationMap[item?.inventory?._id]) {
          if (!allocationMap[item?.inventory?._id][item?.store?._id]) {
            allocationMap[item.inventory._id] = {
              ...allocationMap[item.inventory._id],
              [`${item?.store?._id}`]: { alloc_id: item?._id },
            };
          }
        } else {
          allocationMap = {
            ...allocationMap,
            [`${item?.inventory?._id}`]: {
              [`${item?.store?._id}`]: {
                alloc_id: item?._id,
                balance_quantity: issued_remaining > 0 ? issued_remaining : 0,
              },
            },
          };
        }

        item?.assets_list?.forEach((s) => {
          allocations.push({
            ...allocationObj,
            asset: s?.asset,
            used_quantity: s?.used_quantity,
            balance_quantity: balance_quantity + (s?.used_quantity || 0),
          });

          const asset_Id = s?.asset?._id;
          const inventoryId = item?.inventory?._id;
          const storeId = item?.store?._id;

          if (asset_Id && storeId && inventoryId) {
            if (!assetTempMap[asset_Id]) {
              assetTempMap[asset_Id] = {};
            }
            if (!assetTempMap[asset_Id][storeId]) {
              assetTempMap[asset_Id][storeId] = [];
            }
            if (!assetTempMap[asset_Id][storeId].includes(inventoryId)) {
              assetTempMap[asset_Id][storeId].push(inventoryId);
            }
          }

          let inventoryStoreMap =
            allocationMap[item?.inventory?._id] ??
            (allocationMap[item?.inventory?._id] = {});

          let storeMap =
            inventoryStoreMap[item?.store?._id] ??
            (inventoryStoreMap[item?.store?._id] = {});

          if (storeMap["alloc_id"]) {
            if (!storeMap[s?.asset?._id]) {
              storeMap[s?.asset?._id] = s?.used_quantity;
            }
          }
        });

        if (item?.assets_list?.length === 0 && !plId && assetId) {
          const inventoryId = item?.inventory?._id;
          const storeId = item?.store?._id;

          if (!assetTempMap[assetId]) {
            assetTempMap[assetId] = {};
          }
          if (!assetTempMap[assetId][storeId]) {
            assetTempMap[assetId][storeId] = [];
          }
          if (!assetTempMap[assetId][storeId].includes(inventoryId)) {
            assetTempMap[assetId][storeId].push(inventoryId);
          }
        }

        if (item?.is_allocation) {
          let inventoryObj = {
            label: item?.inventory?.name,
            value: item?.inventory?._id,
            balance_quantity: balance_quantity,
          };
          if (!storeIds.includes(item?.store?._id)) {
            storeIds.push(item?.store?._id);
            issuedStores.push({
              label: item?.store?.name,
              value: item?.store?._id,
              inventories: [inventoryObj],
            });
          } else {
            let storeIndex = issuedStores?.findIndex(
              (st) => st.value == item?.store?._id
            );
            if (storeIndex != -1) {
              issuedStores[storeIndex].inventories.push(inventoryObj);
            }
          }
        }
      });

      setAllocationList(allocations);

      setAssetMap(assetTempMap);
      console.log("assetMap", assetTempMap);

      setAllocationLength(allocations.length || 0);

      setInvAllocationMap(allocationMap);

      addForm.setFieldsValue({
        allocations: resp?.data?.allocations
          ?.filter((item) => !item?.assets_list?.length)
          ?.map((item) => ({
            _id: item?._id,
            store: item?.store?._id,
            type: "existing",
            inventory: item?.inventory?._id,
            used_quantity:
              item?.issued_quantity - item?.returned_quantity || "",
            available_quantity:
              item?.available_quantity +
                ((item?.issued_quantity || 0) -
                  (item?.total_used_quantity || 0) -
                  (item?.returned_quantity || 0)) || 0,
            total_cost:
              (Number((item?.issued_quantity - item?.returned_quantity )|| 0) *
              Number(item?.inventory?.cost || 0)).toFixed(2),
          })),
      });
    } catch (error) {
      console.log(error);
    }
  };
  const refreshContent = () => {
    setRefresh((prev) => !prev);
  };

  useEffect(() => {
    if (orderId) {
      fetchAllocationList();
    }
  }, [orderId, refresh]);

  const handleQuantityChange = (value, cost) => {
    setCurrEditQuantity(value);
    editForm.setFieldsValue({
      used_quantity: value,
      total_cost: (Number(value) || 0) * Number(cost),
    });
  };

  const handleEditFinish = async (values) => {
    let query = { actionType: "use_inventory" };

    let store_id = currEntry?._id ? currEntry?.store?._id : values?.store;
    let inventory_id = currEntry?._id
      ? currEntry?.inventory?._id
      : values?.spare_part;
    let asset_id = plId
      ? currEntry?._id
        ? currEntry?.asset?._id
        : values?.asset
      : assetId;

    let updatePayload = {
      asset: asset_id,
      used_quantity: values?.used_quantity,
    };
    let allocationId =
      invAllocationMap?.[inventory_id]?.[store_id]?.["alloc_id"];
    try {
      const res = await updateAllocationApi(allocationId, query, updatePayload);

      if (res?.data) {
        message.success(res?.data?.message);
        refreshContent();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setCurrEntry(null);
    }
  };

  const handleOnFinish = async (values) => {
    try {
      const query = { actionType: "use_inventory" };
      const payload = values?.allocations[addNewEntry];
      let asset_id = plId ? payload?.asset : assetId;
      let allocationId =
        invAllocationMap?.[payload?.inventory]?.[payload?.store]?.["alloc_id"];
      if (payload?.type === "existing" || allocationId) {
        const updatePayload = {
          asset: asset_id,
          used_quantity: payload?.used_quantity,
        };
        const resp = await updateAllocationApi(
          allocationId,
          query,
          updatePayload
        );
        if (resp) {
          message.success("Allocation updated successfully");
          setRefresh((prev) => !prev);
          setCurrEntry(null);
        } else {
          message.error("Something went wrong");
        }
        return;
      }

      let createPayload = {
        store: payload?.store,
        asset: asset_id,
        is_allocation: false,
        inventory_list: [
          {
            inventory: payload?.inventory,
            used_quantity: payload?.used_quantity,
          },
        ],
      };
      if (type === "WO") {
        createPayload.workorder = orderId;
      } else {
        createPayload.occurrence = orderId;
      }
      const resp = await createAllocationApi(query, createPayload);
      if (resp) {
        message.success("Allocation created successfully");
        setRefresh((prev) => !prev);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      console.log(error);
    } finally {
    }
  };

  const renderListItem = (item) => {
    return (
      <List.Item>
        <Row gutter={[16, 0]} style={{ width: "100%" }}>
          {plId && (
            <Col span={4}>
              <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                {item?.asset?.name}
              </div>
            </Col>
          )}
          <Col span={5}>
            <div style={{ wordBreak: "break-word", whiteSpace: "normal" }}>
              {item?.store?.name}
            </div>
          </Col>
          <Col span={5}>
            <Space direction="vertical" style={{ gap: 2 }}>
              <Typography.Text
                style={{ wordBreak: "break-word", whiteSpace: "normal" }}
              >
                {item?.inventory?.name}
              </Typography.Text>
              {item?._id === currEntry?._id &&
                item?.asset?._id === currEntry?.asset?._id && (
                  <Tag className={tagStyles.smRndTag} color="geekblue">
                    {`Available: ${item?.balance_quantity || 0}`}
                  </Tag>
                )}
            </Space>
          </Col>

          <Col span={4}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Form.Item
                name="used_quantity"
                style={{ width: "100%" }}
                rules={[
                  {
                    validator: (_, value, callback) => {
                      if (value && value !== "") {
                        if (/^[0-9]+$/.test(value)) {
                          if (Number(value) > Number(item?.balance_quantity)) {
                            callback(
                              "Used quantity cannot be more than available quantity"
                            );
                          } else {
                            callback();
                          }
                        } else {
                          callback("Please enter a valid number");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  className={inputStyles.smInput}
                  placeholder="Enter quantity"
                  onChange={(e) => {
                    const value = e?.target?.value;
                    handleQuantityChange(value, currEntry?.inventory?.cost);
                    // if (
                    //   currEntry?.issued_quantity &&
                    //   currEntry?.issued_quantity !== 0 &&
                    //   value > currEntry?.issued_quantity
                    // ) {

                    //   message.warning(
                    //     "Used quantity cannot be more than issued quantity",
                    //     5
                    //   );
                    // }
                  }}
                />
              </Form.Item>
            ) : (
              `${formatNumber(Number(item?.used_quantity) || 0)}`
            )}
          </Col>
          <Col span={4}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Form.Item name="total_cost" style={{ width: "100%" }}>
                <Input
                  style={{ width: "100%" }}
                  className={inputStyles.smInput}
                  placeholder="Total cost"
                  disabled={true}
                />
              </Form.Item>
            ) : (
              `₹ ${formatNumber(
                Number(
                  (
                    Number(item?.used_quantity || 0) *
                    Number(item?.inventory?.cost || 0)
                  ).toFixed(2)
                )
              )}`
            )}
          </Col>
          <Col span={2}>
            {item?._id === currEntry?._id &&
            item?.asset?._id === currEntry?.asset?._id ? (
              <Space style={{ width: "100%", gap: 8 }}>
                {(() => {
                  if (
                    currEntry?.issued_quantity &&
                    currEntry?.issued_quantity !== 0 &&
                    currEditQuantity > currEntry?.issued_quantity
                  ) {
                    return (
                      <Popconfirm
                        title={`You are using more than the issued quantity(${currEntry?.issued_quantity}). Do you want to continue?`}
                        onConfirm={() => editForm.submit()}
                      >
                        <Button
                          type="link"
                          style={{ textAlign: "center" }}
                          icon={
                            <CheckOutlined
                              style={{
                                fontSize: 20,
                                color: "green",
                                cursor: "pointer",
                              }}
                            />
                          }
                        />
                      </Popconfirm>
                    );
                  }

                  return (
                    <Button
                      type="link"
                      style={{ textAlign: "center" }}
                      icon={
                        <CheckOutlined
                          style={{
                            fontSize: 20,
                            color: "green",
                            cursor: "pointer",
                          }}
                        />
                      }
                      onClick={() => editForm.submit()}
                    />
                  );
                })()}

                <Button
                  type="link"
                  onClick={() => setCurrEntry(null)}
                  icon={
                    <CloseOutlined
                      style={{ fontSize: 20, color: "red", cursor: "pointer" }}
                    />
                  }
                />
              </Space>
            ) : (
              (
                <EditOutlined
                  style={{ fontSize: 16, cursor: "pointer" }}
                  onClick={() => {
                    setCurrEntry(item);
                    setCurrEditQuantity(item?.used_quantity);
                  }}
                />
              )
            )}
          </Col>
        </Row>
      </List.Item>
    );
  };
  const renderCheckIcon = (name, index, selectedStore) => {
    const selectedInventory = addForm.getFieldValue([
      "allocations",
      name,
      "inventory",
    ]);
    const selectedInventoryDetails = (
      inventoryOptions[selectedStore] || []
    ).find((item) => item.value === selectedInventory);

    const allocationId =
      invAllocationMap?.[selectedInventory]?.[selectedStore]?.alloc_id;
    const selectedAsset = plId
      ? addForm.getFieldValue(["allocations", name, "asset"])
      : assetId;
    const obj = invAllocationMap?.[selectedInventory]?.[selectedStore];

    const currentQuantity = addForm.getFieldValue([
      "allocations",
      name,
      "used_quantity",
    ]);

    const getPopconfirmMessage = (text) => (
      <div
        style={{ maxWidth: 250, whiteSpace: "normal", wordWrap: "break-word" }}
      >
        {text}
      </div>
    );

    if (!allocationId && selectedInventoryDetails?.high_value) {
      return (
        <Popconfirm
          title={getPopconfirmMessage(
            "This is a high-value spare part that has not been issued to you. Please ensure it is issued before proceeding. Would you like to continue?"
          )}
          onConfirm={() => {
            setAddNewEntry(index);
            addForm.submit();
          }}
        >
          <CheckOutlined style={{ color: "green", fontSize: 18 }} />
        </Popconfirm>
      );
    }

    if (allocationId && currentQuantity > obj?.balance_quantity) {
      return (
        <Popconfirm
          title={getPopconfirmMessage(
            `You are using more than the issued quantity(${obj?.balance_quantity}). Do you want to continue?`
          )}
          onConfirm={() => {
            setAddNewEntry(index);
            addForm.submit();
          }}
        >
          <CheckOutlined style={{ color: "green", fontSize: 18 }} />
        </Popconfirm>
      );
    }

    return (
      <CheckOutlined
        style={{ color: "green", fontSize: 18 }}
        onClick={() => {
          setAddNewEntry(index);
          addForm.submit();
        }}
      />
    );
  };

  const handleQuantityChangeAddForm = (index, value, cost) => {
    const newValues = [...addForm.getFieldValue("allocations")];

    newValues[index] = {
      ...newValues[index],
      used_quantity: value,
      total_cost: (Number(value) || 0) * Number(cost),
    };

    addForm.setFieldsValue({ allocations: newValues });
  };

  return (
    <Card
      title="Spare Parts Used"
      styles={{
        header: { background: "#ffff" },
        body: { paddingBottom: "55px" },
      }}
    >
      <Row>
        <Col span={24}>
          {
            allocationList?.filter((item) => item?.asset?._id).length == 0 && (
              <Typography.Text style={{ width: "100%", textAlign: "center" }}>
                {" "}
                No spare parts used
              </Typography.Text>
            )}
          <Form
            requiredMark={false}
            form={editForm}
            onFinish={handleEditFinish}
            style={{ width: "100%" }}
          >
            {allocationList &&
              allocationList?.filter((item) => item?.asset?._id).length > 0 && (
                <List
                  bordered
                  loading={false}
                  dataSource={allocationList?.filter(
                    (item) => item?.asset?._id
                  )}
                  header={
                    <Row gutter={[16, 0]} style={{ width: "100%" }}>
                      {plId && <Col span={4}>Asset</Col>}
                      <Col span={5}>Store</Col>
                      <Col span={5}>Spare part</Col>
                      <Col span={4}>Used Quantity</Col>
                      <Col span={3}>Total cost</Col>
                    </Row>
                  }
                  renderItem={(item) => renderListItem(item)}
                />
              )}
          </Form>
        </Col>
      </Row>

      {(addForm.getFieldValue("allocations") || []).length > 0  && (
        <Row gutter={[16, 0]} style={{ width: "100%", marginTop: "20px" }}>
          {plId && <Col span={4}>Asset</Col>}
          <Col span={5}>Store</Col>
          <Col span={5}>Spare part</Col>
          <Col span={4}>Quantity</Col>
          <Col span={3}>Total cost</Col>
        </Row>
      )}
      {(
        <Row style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Form
              form={addForm}
              name="attach_spare_part_form"
              onFinish={handleOnFinish}
              style={{ marginTop: "20px" }}
              autoComplete="off"
            >
              <Form.List name="allocations">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => {
                      const selectedStore = allocations[name]?.store;

                      return (
                        <Row
                          key={key}
                          gutter={[8, 16]}
                          style={{ width: "100%", marginBottom: "40px" }}
                        >
                          {plId && (
                            <Col span={4}>
                              <Form.Item
                                {...restField}
                                name={[name, "asset"]}
                                rules={[
                                  { required: true, message: "Missing asset" },
                                ]}
                              >
                                <Select
                                  placeholder="Asset"
                                  style={{ width: "100%" }}
                                  optionFilterProp="label"
                                  showSearch
                                  options={selectedPlAssets}
                                  onChange={() => {
                                    let allocationId =
                                      invAllocationMap?.[
                                        addForm.getFieldValue("allocations")?.[
                                          index
                                        ]?.inventory
                                      ]?.[
                                        addForm.getFieldValue("allocations")?.[
                                          index
                                        ]?.store
                                      ]?.["alloc_id"];

                                    if (
                                      addForm.getFieldValue("allocations")?.[
                                        index
                                      ]?.type !== "existing" &&
                                      allocationId
                                    ) {
                                      addForm.setFieldsValue({
                                        allocations: [
                                          ...addForm
                                            .getFieldValue("allocations")
                                            .map((item, i) =>
                                              i === index
                                                ? {
                                                    ...item,
                                                    available_quantity:
                                                      undefined,
                                                    inventory: undefined,
                                                    total_cost: undefined,
                                                  }
                                                : item
                                            ),
                                        ],
                                      });
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          )}
                          <Col span={5}>
                            <Form.Item
                              {...restField}
                              name={[name, "store"]}
                              rules={[
                                { required: true, message: "Missing store" },
                              ]}
                            >
                              <Select
                                showSearch
                                placeholder="Store"
                                filterOption={(input, option) => {
                                  return option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }}
                                style={{ width: "100%" }}
                                options={storesList}
                                onChange={(value) =>
                                  handleStoreChange(value, index)
                                }
                                disabled={
                                  addForm.getFieldValue("allocations")?.[index]
                                    ?.type === "existing"
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              {...restField}
                              name={[name, "inventory"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing spare part",
                                },
                              ]}
                            >
                              <Select
                                placeholder="Spare part"
                                showSearch
                                filterOption={(input, option) => {
                                  return option.label
                                    .toLowerCase()
                                    .includes(input.toLowerCase());
                                }}
                                style={{ width: "100%" }}
                                value={
                                  allocations?.[name]?.inventory ?? undefined
                                }
                                options={(() => {
                                  let isExisting =
                                    addForm.getFieldValue("allocations")?.[
                                      index
                                    ]?.type === "existing";

                                  if (isExisting)
                                    return inventoryOptions?.[selectedStore];
                                  const filteredOptions =
                                    inventoryOptions?.[selectedStore]?.filter(
                                      (item) => {
                                        const assetKey = plId
                                          ? addForm.getFieldValue(
                                              "allocations"
                                            )?.[index]?.asset
                                          : assetId;

                                        return !(
                                          assetKey &&
                                          assetMap?.[assetKey]?.[selectedStore]?.includes(item?.value)
                                        );
                                      }
                                    ) || [];

                                  return filteredOptions;
                                })()}
                                disabled={
                                  !inventoryOptions[selectedStore] ||
                                  (addForm.getFieldValue("allocations") || [])[
                                    index
                                  ]?.type === "existing"
                                }
                                onChange={(value) => {
                                  if (!inventoryOptions[selectedStore]) return;

                                  const selectedInventory = (
                                    inventoryOptions[selectedStore] || []
                                  ).find((item) => item.value === value);

                                  const cost = selectedInventory?.cost || 0;
                                  let availableQty =
                                    selectedInventory?.available_quantity || 0;

                                  const selectedasset = plId
                                    ? addForm.getFieldValue([
                                        "allocations",
                                        name,
                                        "asset",
                                      ])
                                    : assetId;
                                  // const allocationId =
                                  //   invAllocationMap?.[value]?.[selectedStore]?.[
                                  //     "alloc_id"
                                  //   ];
                                  // if (
                                  //   !allocationId &&
                                  //   selectedInventory?.high_value
                                  // ) {
                                  //   message.warning(
                                  //     "Warning: You are using a high-value item without issuing it first.",
                                  //     5
                                  //   );
                                  // }

                                  const existing_used =
                                    invAllocationMap?.[value]?.[
                                      selectedStore
                                    ]?.[selectedasset] || null;
                                  const balance_quantity =
                                    invAllocationMap?.[value]?.[selectedStore]
                                      ?.balance_quantity || null;

                                  availableQty =
                                    availableQty + (existing_used || 0);
                                  addForm.setFieldsValue({
                                    allocations: addForm
                                      .getFieldValue("allocations")
                                      .map((item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              inventory: value,
                                              used_quantity: existing_used,
                                              total_cost:
                                                cost * existing_used || null,
                                              available_quantity:
                                                availableQty +
                                                (balance_quantity || 0),
                                            }
                                          : item
                                      ),
                                  });

                                  // handleQuantityChangeAddForm(
                                  //   index,
                                  //   allocations?.[name]?.used_quantity ?? 0,
                                  //   cost
                                  // );
                                }}
                              />

                              {typeof allocations?.[name]
                                ?.available_quantity === "number" && (
                                <Tag
                                  className={tagStyles.smRndTag}
                                  color="geekblue"
                                  style={{ marginTop: "4px" }}
                                >
                                  Available:{" "}
                                  {allocations[name]?.available_quantity}
                                </Tag>
                              )}
                            </Form.Item>
                          </Col>

                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "used_quantity"]}
                              rules={[
                                {
                                  validator: (_, value, callback) => {
                                    const availableQuantity =
                                      addForm.getFieldValue([
                                        "allocations",
                                        name,
                                        "available_quantity",
                                      ]) || 0;

                                    if (!value || value === "") {
                                      callback("Please enter quantity");
                                    } else if (!/^[0-9]+$/.test(value)) {
                                      callback("Please enter a valid number");
                                    } else if (Number(value) <= 0) {
                                      callback(
                                        "Please enter a valid quantity greater than 0"
                                      );
                                    } else if (
                                      Number(value) > Number(availableQuantity)
                                    ) {
                                      callback(
                                        "Used quantity cannot be more than available quantity"
                                      );
                                    } else {
                                      callback();
                                    }
                                  },
                                },
                              ]}
                            >
                              <Input
                                placeholder="Quantity"
                                style={{ width: "100%" }}
                                onChange={(e) => {
                                  const inv = inventoryOptions[
                                    selectedStore
                                  ]?.find(
                                    (item) =>
                                      item.value ===
                                      addForm.getFieldValue([
                                        "allocations",
                                        name,
                                        "inventory",
                                      ])
                                  );

                                  // const selectedasset = plId
                                  //   ? addForm.getFieldValue([
                                  //       "allocations",
                                  //       name,
                                  //       "asset",
                                  //     ])
                                  //   : assetId;
                                  // const obj =
                                  //   invAllocationMap?.[inv?.value]?.[
                                  //     selectedStore
                                  //   ];

                                  // const allocationId = obj?.alloc_id;
                                  // const isExist = plId
                                  //   ? obj?.[selectedasset]
                                  //   : allocationId;
                                  // if (
                                  //   isExist &&
                                  //   e.target.value > obj?.balance_quantity
                                  // ) {
                                  //   message.warning(
                                  //     "Used quantity cannot be more than issued quantity",
                                  //     5
                                  //   );
                                  // }

                                  // if (
                                  //   !allocationId &&
                                  //   selectedInventory?.high_value
                                  // ) {
                                  //   message.warning(
                                  //     "Warning: You are using a high-value item without issuing it first.",5

                                  //   );
                                  // }

                                  handleQuantityChangeAddForm(
                                    index,
                                    e.target.value,
                                    inv?.cost || 0
                                  );
                                }}
                                disabled={!allocations[name]?.inventory}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <Form.Item
                              {...restField}
                              name={[name, "total_cost"]}
                            >
                              <Input
                                placeholder="Total cost"
                                style={{ width: "100%" }}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col span={2}>
                            <Space
                              style={{ gap: 12, marginTop: 3, marginLeft: 5 }}
                            >
                              {renderCheckIcon(name, index, selectedStore)}

                              {addForm.getFieldValue("allocations")?.[index]
                                ?.type !== "existing" && (
                                <MinusCircleOutlined
                                  style={{ color: "red", fontSize: 18 }}
                                  onClick={() => remove(name)}
                                />
                              )}
                            </Space>
                          </Col>
                        </Row>
                      );
                    })}

                    {(addForm.getFieldValue("allocations") || []).filter(
                      (item) => item?.type !== "existing"
                    ).length === 0 &&
                      (
                        <Form.Item style={{ marginBottom: 10 }}>
                          <Button
                            size="middle"
                            type="dashed"
                            style={{
                              width: "100%",
                              borderColor: "#7369F4",
                              color: "#7369F4",
                              marginTop: "12px",
                            }}
                            onClick={() => {
                              add();
                            }}
                            block
                            icon={<PlusOutlined />}
                          >
                            Add spare part
                          </Button>
                        </Form.Item>
                      )}
                  </>
                )}
              </Form.List>
            </Form>
          </Col>
        </Row>
      )}
    </Card>
  );
};

export default SparePartList;
