import { SearchOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Empty,
  Form,
  Image,
  Select,
  Spin,
  Tag,
  Typography,
  message,
  Result,
  Row,
  Flex,
  Space,
} from "antd";
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";

import {
  getStoreByInventoryApi,
  getStoreListApi,
  searchStoresByInventoryApi,
} from "../../../services/inventory.services";
import noStores from "../../../assets/images/inventory/noStores.svg";

const StoresList = ({
  selectedInventory,
  setSelectedInventory,
  storeList,
  setStoreList,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Debounced function to fetch store search results
  const fetchStoreOptions = useCallback(
    debounce(async (query) => {
      if (!query.trim()) return;
      try {
        const res = await searchStoresByInventoryApi({ name: query });
        setOptions(
          res?.data?.map((item) => ({
            label: item?.name,
            value: item?._id,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching search results:", error);
        message.error("Failed to fetch search results");
      }
    }, 500),
    []
  );

  useEffect(() => {
    if (searchValue) fetchStoreOptions(searchValue);
  }, [searchValue, fetchStoreOptions]);

  useEffect(() => {
    if (selectedInventory) fetchStoreList();
  }, [selectedInventory]);

  const fetchStoreList = async () => {
    setLoading(true);
    try {
      const res = await getStoreByInventoryApi(selectedInventory);
      setStoreList(
        res?.data?.map((store) => ({
          store: {
            _id: store.store?._id,
            name: store.store?.name,
            location: store.store?.location?.name || "Unknown Location",
          },
          available_quantity: store?.available_quantity || 0,
        })) || []
      );
    } catch (error) {
      console.error("Error fetching store list:", error);
      message.error("Failed to fetch store list");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card style={{ border: 0 }}>
      {/* Search Select */}
      <Row justify="center">
        <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
          <Select
            allowClear
            size="large"
            style={{ width: "90%" }}
            showSearch
            placeholder="Search inventory"
            value={selectedInventory}
            onSearch={setSearchValue}
            filterOption={false}
            options={options}
            onChange={setSelectedInventory}
            suffixIcon={<SearchOutlined />}
            notFoundContent={searchValue ? "No inventory found" : ""}
            onClear={() => {
              setSelectedInventory(null);
              setStoreList([]);
              setOptions([]);
            }}
          />
        </Col>
      </Row>

      {/* Loader */}
      {loading && (
        <Col
          span={24}
          style={{
            minHeight: 200,
            display: "flex",
            justifyContent: "center",
            marginTop: 20,
          }}
        >
          <Spin size="default" />
        </Col>
      )}

      {/* Store List */}
      <Row justify="start" gutter={[16, 24]} style={{ marginTop: 40 }}>
        {!loading && storeList.length === 0 && (
          <Col
            span={24}
            style={{
              minHeight: 200,
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
            }}
          >
            {selectedInventory ? (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={
                  <Typography>
                    This inventory is not availabe in any stores
                  </Typography>
                }
              />
            ) : (
              <Image
                preview={false}
                src={noStores}
                alt="No Stores"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            )}
          </Col>
        )}

        {storeList.map(({ store, available_quantity }) => (
          <Col
            key={store._id}
            span={8}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Card style={cardStyle}>
              <Typography.Title
                level={5}
                style={{ margin: 0, fontWeight: 500 }}
              >
                {store.name}
              </Typography.Title>

              <div style={infoStyle}>
                <Typography.Text>{store.location}</Typography.Text>
              </div>

              {available_quantity !== null && (
                <div style={infoStyle}>
                  <Typography.Text>Available quantity:</Typography.Text>
                  <Tag color="purple">{available_quantity}</Tag>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
  margin: 10,
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
};

const infoStyle = {
  display: "flex",
  justifyContent: "space-between",
  marginTop: 10,
};

export default StoresList;
