import {
  Button,
  Col,
  Drawer,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  TreeSelect,
  Typography,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import switchStyles from "../../assets/styles/Custom/Switch.module.css";

import {
  addInventory,
  getInventoryCategoriesListApi,
  getInventoryDetails,
  getStoreListApi,
  updateInventory,
  updateInventoryByStore,
} from "../../services/inventory.services";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
const AddEditInventory = ({
  showDrawer,
  setShowDrawer,
  currInventory,
  setRefresh,
  viewType,
  selectedStore,
}) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const formRef = useRef();
  const [form] = Form.useForm();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [fieldCount, setFieldCount] = useState(0);
  const fetchCategories = async () => {
    try {
      const resp = await getInventoryCategoriesListApi();
      if (resp) {
        setCategoryOptions(
          resp?.data?.map((category) => ({
            label: category?.name,
            value: category?._id,
          }))
        );
      }
    } catch (error) {
      setCategoryOptions([]);
      console.error("Error fetching Categories:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
    fetchStores();
    if (currInventory) {
      fetchInventoryData();
    }
  }, [currInventory]);

  const fetchInventoryData = async () => {
    try {
      const resp = await getInventoryDetails(currInventory?._id);
      if (resp) {
        setInventoryDetails(resp?.data);
      }
    } catch (error) {
      setInventoryDetails(null);
      console.error("Error fetching Categories:", error);
    }
  };

  const fetchStores = async () => {
    try {
      const resp = await getStoreListApi();
      if (resp) {
        setAllStores(
          resp?.data?.map((store) => ({
            label: store?.name,
            value: store?._id,
          }))
        );
      }
    } catch (error) {
      setAllStores([]);
      // message.error(error?.response?.data?.message);
      console.error("Error fetching stores:", error);
    }
  };

  useEffect(() => {
    if (inventoryDetails) {
      form.setFieldsValue({
        code: inventoryDetails?.code,
        cost: inventoryDetails?.cost,
        name: inventoryDetails?.name,
        high_value: inventoryDetails?.high_value || false,
        category: inventoryDetails?.category?._id,
        stores: inventoryDetails?.stores?.map((store) => ({
          hidden:
            viewType === "stores" ? selectedStore !== store?.store : false,
          store_inv_id: store?._id,
          store: store?.store,
          available_quantity: store?.available_quantity || 0,
          low_stock_threshold: store?.low_stock_threshold || 0,
        })) || [{}],
      });
    }
  }, [inventoryDetails, form]);

  const onFinish = async (values) => {
    if (viewType === "stores") {
      let selectedStoreDetails = values?.stores?.find(
        (store) => !store?.hidden
      );
      let payload = {
        available_quantity: selectedStoreDetails?.available_quantity || 0,
        low_stock_threshold: selectedStoreDetails?.low_stock_threshold || 0,
      };
      let query = {};
      try {
        const res = await updateInventoryByStore(
          currInventory?._id,
          selectedStore,
          query,
          payload
        );
        if (res?.data) {
          message.success(res?.data?.message);
          setRefresh((prev) => !prev);
          setShowDrawer(false);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
        console.log(error);
      }

      return;
    }
    const storeIds = values.stores?.map((store) => store?.store);
    const duplicateStore = storeIds?.find(
      (id, index) => storeIds.indexOf(id) !== index
    );

    if (duplicateStore) {
      message.error(
        "Duplicate stores are not allowed. Please remove or update."
      );
      return;
    }

    let payload = {
      code: values?.code,
      cost: values?.cost ? Number(values?.cost) : 0,
      name: values?.name,
      high_value: values?.high_value || false,
      category: values?.category || "",
    };

    if (values?.stores && values?.stores.length > 0) {
      if (inventoryDetails) {
        payload["stores"] = values.stores.map((store) => {
          const existingStore = inventoryDetails?.stores?.find(
            (s) => s?.store === store?.store
          );

          return {
            store: existingStore ? existingStore?._id : store?.store,
            available_quantity: store?.available_quantity || 0,
            low_stock_threshold: store?.low_stock_threshold || 0,
            ...(existingStore ? { store_inv_id: store?.store_inv_id } : {}),
          };
        });
      } else {
        payload["stores"] = values.stores.map((store) => ({
          store: store?.store,
          available_quantity: store?.available_quantity || 0,
          low_stock_threshold: store?.low_stock_threshold || 0,
        }));
      }
    }

    setBtnLoading(true);

    const addOrUpdateFunc = inventoryDetails
      ? updateInventory(inventoryDetails?._id, payload)
      : addInventory(payload);

    addOrUpdateFunc
      .then((res) => {
        message.success(res?.data?.message);
        setRefresh((prev) => !prev);
        setShowDrawer(false);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      })
      .finally(() => setBtnLoading(false));
  };

  return (
    <Drawer
      footer={
        <Flex justify="flex-end" gap={24}>
          <Button
            onClick={() => setShowDrawer(false)}
            className={btnStyles.mdBtn}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={btnLoading}
            onClick={() => formRef?.current?.submit()}
            className={btnStyles.mdBtn}
          >
            {inventoryDetails ? "Save" : "Add"}
          </Button>
        </Flex>
      }
      title={
        inventoryDetails
          ? `Inventory-${inventoryDetails?.code}`
          : "Add Inventory"
      }
      width={"36vw"}
      onClose={() => setShowDrawer(false)}
      open={showDrawer}
      maskClosable={false}
    >
      {viewType === "stores" && (
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Flex gap={10} vertical>
              <Typography.Text
                style={{
                  fontSize: 12,
                  maxWidth: "100px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                Inventory
              </Typography.Text>
              <Typography.Text>{currInventory?.name || " - "}</Typography.Text>
            </Flex>
          </Col>

          <Col span={8}>
            <Flex gap={10} vertical>
              <Typography.Text style={{ fontSize: 12 }}>Code</Typography.Text>
              <Tag
                color="blue"
                style={{
                  width: "auto",
                  fontSize: "12px",
                  maxWidth: "60px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {currInventory?.code || " - "}
              </Tag>
            </Flex>
          </Col>
          <Col span={8}>
            <Flex gap={10} vertical>
              {" "}
              <Typography.Text style={{ fontSize: 12 }}>
                Category
              </Typography.Text>
              <Typography.Text>
                {currInventory?.category?.name || " - "}
              </Typography.Text>
            </Flex>
          </Col>

          <Col span={8}>
            <Flex gap={10} vertical>
              <Typography.Text style={{ fontSize: 12 }}>
                High value
              </Typography.Text>
              <Tag
                color={currInventory?.high_value ? "green" : "red"}
                style={{ width: "40px" }}
              >
                {currInventory?.high_value ? "Yes" : "No"}
              </Tag>
            </Flex>
          </Col>

          <Col span={8}>
            <Flex gap={10} vertical>
              <Typography.Text style={{ fontSize: 12 }}>
                Cost per piece
              </Typography.Text>
              <Typography.Text>{currInventory?.cost || " - "}</Typography.Text>
            </Flex>
          </Col>
        </Row>
      )}
      <Form
        ref={formRef}
        form={form}
        name="inventory-form"
        layout="vertical"
        requiredMark={false}
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          high_value: currInventory ? currInventory?.high_value : true,
        }}
      >
        <Form.Item
          label="Spare part name"
          name={"name"}
          rules={[
            { required: true, message: "Please enter spare part name" },
            {
              whitespace: true,
              message: "Spare part name cannot be empty or contain only spaces",
            },
            {
              min: 3,
              message: "Spare part name should contain atleast 3 characters",
            },
            {
              max: 100,
              message: "Spare part name cannot excced 100 characters",
            },
          ]}
          hidden={viewType === "stores"}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter spare part name"
          />
        </Form.Item>

        <div style={{ display: "flex", width: "100%" }}>
          <Form.Item
            rules={[
              {
                whitespace: true,
                message: "Spare code cannot be empty or contain only spaces",
              },
              { required: true, message: "Please enter spare code" },
            ]}
            label="Spare code"
            name="code"
            style={{ width: "60%" }}
            hidden={viewType === "stores"}
          >
            <Input
              className={inputStyles.smInput}
              style={{
                width: "100%",
                borderEndEndRadius: "0px",
                borderStartEndRadius: "0px",
              }}
              placeholder="Enter code"
            />
          </Form.Item>
          <Form.Item
            label=" "
            style={{ width: "40%" }}
            hidden={viewType === "stores"}
          >
            <Button
              onClick={() =>
                form.setFieldsValue({
                  code: Math.floor(10000 + Math.random() * 90000).toString(),
                })
              }
              type="primary"
              style={{
                borderLeft: "0px",
                borderEndStartRadius: "0px",
                borderStartStartRadius: "0px",
                height: "34px",
                width: "100%",
              }}
              className={btnStyles.mdBtn}
            >
              Generate
            </Button>
          </Form.Item>
        </div>
        <Form.Item
          label="Category"
          name={"category"}
          hidden={viewType === "stores"}
        >
          <Select
            placeholder="Select category"
            style={{
              width: "100%",
            }}
            className={inputStyles.smSelect}
            // allowClear={!currInventory ? true : false}
            options={categoryOptions}
            allowClear
          />
        </Form.Item>

        <Form.Item
          label="High value"
          name={"high_value"}
          hidden={viewType === "stores"}
        >
          <Switch className={switchStyles.basic} />
        </Form.Item>

        <Form.Item
          label="Cost per piece"
          name={"cost"}
          rules={[
            {
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (!value) {
                    return resolve();
                  }

                  if (value.length > 15) {
                    return reject(new Error("Please enter a valid cost"));
                  }

                  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
                    return reject(
                      new Error(
                        "Please enter a valid cost with at most 2 decimal places"
                      )
                    );
                  }
                  return resolve();
                });
              },
            },
          ]}
          hidden={viewType === "stores"}
        >
          <Input
            className={inputStyles.smInput}
            style={{ width: "100%" }}
            placeholder="Enter cost"
          />
        </Form.Item>

        <Space direction="vertical" style={{ width: "100%" }}>
          {fieldCount > 0 && (
            <Row gutter={[16, 0]}>
              <Col span={7}>
                <Typography.Text style={{ fontSize: 12 }}>
                  Store
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text style={{ fontSize: 12 }}>
                  Quantity
                </Typography.Text>
              </Col>
              <Col span={7}>
                <Typography.Text style={{ fontSize: 12 }}>
                  Low stock threshold
                </Typography.Text>
              </Col>
            </Row>
          )}
          <Form.List name="stores">
            {(fields, { add, remove }) => (
              setFieldCount(fields.length),
              (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const storeId = form.getFieldValue([
                      "stores",
                      name,
                      "store",
                    ]);
                    const isExistingStore = !!inventoryDetails?.stores?.some(
                      (s) => s?.store === storeId
                    );

                    return (
                      <Row
                        gutter={[16, 0]}
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "store"]}
                            rules={[
                              { required: true, message: "Store is required" },
                            ]}
                            hidden={form.getFieldValue([
                              "stores",
                              name,
                              "hidden",
                            ])}
                          >
                            <Select
                              placeholder="Select store"
                              className={inputStyles.smSelect}
                              options={allStores}
                              disabled={isExistingStore}
                              allowClear={!isExistingStore}
                              showSearch
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              optionFilterProp="label"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "available_quantity"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (/^[0-9]+$/.test(value) === false) {
                                    return Promise.reject(
                                      new Error("Only numbers are allowed")
                                    );
                                  }
                                  if (value.length > 15) {
                                    return Promise.reject(
                                      new Error("Please enter a valid quantity")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                            hidden={form.getFieldValue([
                              "stores",
                              name,
                              "hidden",
                            ])}
                          >
                            <Input
                              style={{ maxWidth: "150px" }}
                              placeholder="Enter quantity"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Form.Item
                            {...restField}
                            name={[name, "low_stock_threshold"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (!value) return Promise.resolve();
                                  if (/^[0-9]+$/.test(value) === false) {
                                    return Promise.reject(
                                      new Error("Only numbers are allowed")
                                    );
                                  }
                                  if (value.length > 15) {
                                    return Promise.reject(
                                      new Error("Please enter a valid quantity")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                            hidden={form.getFieldValue([
                              "stores",
                              name,
                              "hidden",
                            ])}
                          >
                            <Input placeholder="Enter low stock threshold" />
                          </Form.Item>
                        </Col>

                        {!isExistingStore && (
                          <Col span={3}>
                            <MinusCircleOutlined
                              style={{ marginTop: 8 }}
                              onClick={() => remove(name)}
                            />
                          </Col>
                        )}
                      </Row>
                    );
                  })}

                  <Form.Item hidden={viewType === "stores"}>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                      style={{ height: "44px" }}
                    >
                      Attach store
                    </Button>
                  </Form.Item>
                </>
              )
            )}
          </Form.List>
        </Space>
      </Form>
    </Drawer>
  );
};

export default AddEditInventory;
