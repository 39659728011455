import { Select } from "antd";
import React from "react";

const Mapcontrols = ({ onChangeView }) => {
  return (
    <div
      style={{ position: "absolute", top: "10px", right: "10px", zIndex: 1000 }}
    >
      <Select
        defaultValue="satellite"
        style={{ width: 120 }}
        onChange={(value) => onChangeView(value)}
        options={[
          { value: "osm", label: "OpenStreetMap" },
          { value: "satellite", label: "Satellite" },
          { value: "topo", label: "Topographic" },
          { value: "dark", label: "Dark" }
        ]}
      />
    </div>
  );
};

export default Mapcontrols;
