import React, { useEffect, useRef, useState } from "react";
import "ol/ol.css";
import { Feature, Map, View, Overlay } from "ol";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import { fromLonLat } from "ol/proj";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Style, Fill, Stroke, Circle as CircleStyle, Icon } from "ol/style";
import { WKT } from "ol/format";
import Mapcontrols from "./MapControls";
import { Popover, Modal } from "antd";
import { facilities, locations, stores } from "./testMapData";

const MapComponent = () => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [popupContent, setPopupContent] = useState("");
  const [popupPosition, setPopupPosition] = useState(null);
  const popupRef = useRef(null);

  const centerCoords = fromLonLat([80.12, 14.26]);

  const storeIconUrl = "https://cdn-icons-png.flaticon.com/512/869/869636.png";

  const mapBoxAuthKey =
    "pk.eyJ1Ijoic3BhdGlvemVzdCIsImEiOiJjbGh5aTQwcDkwdmF1M2VydXR2cTM0dGdjIn0.7w-fnzMZJ5F0Veo_Cmi_gg";

  // Define different map sources
  const mapSources = {
    osm: new TileLayer({
      source: new OSM(),
    }),
    satellite: new TileLayer({
      source: new XYZ({
        url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      }),
    }),
    topo: new TileLayer({
      source: new XYZ({
        url: "https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png",
      }),
    }),
    dark: new TileLayer({
      source: new XYZ({
        url: `https://api.mapbox.com/styles/v1/mapbox/dark-v11/tiles/256/{z}/{x}/{y}?access_token=${mapBoxAuthKey}`,
        tileSize: 512,
        maxZoom: 22,
      }),
    }),
  };

  // Convert WKT to Features
  const wktFormat = new WKT();
  const facilitiesFeatures = facilities.map(({ wkt, content }) => {
    const feature = new Feature(
      wktFormat.readGeometry(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      })
    );
    feature.set("type", "facilities");
    feature.set("content", content);
    return feature;
  });

  const locationsFeatures = locations.map(({ wkt, content }) => {
    const feature = new Feature(
      wktFormat.readGeometry(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      })
    );
    feature.set("type", "locations");
    feature.set("content", content);
    return feature;
  });

  const storesFeatures = stores.map(({ wkt, content }) => {
    const feature = new Feature(
      wktFormat.readGeometry(wkt, {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      })
    );
    feature.set("type", "stores");
    feature.set("content", content);
    return feature;
  });

  const styles = {
    facilities: new Style({
      stroke: new Stroke({ color: "red", width: 2 }),
    }),
    locations: new Style({
      fill: new Fill({ color: "rgba(135, 206, 235, 0.3)" }),
      stroke: new Stroke({ color: "skyblue", width: 2 }),
    }),
    stores: new Style({
      image: new Icon({
        src: storeIconUrl,
        scale: 0.05,
      }),
    }),
  };

  const getFeatureStyle = (feature) => {
    const featureType = feature.get("type");
    return styles[featureType] || styles.MultiPoint;
  };

  const facilitiesLayer = new VectorLayer({
    source: new VectorSource({
      features: facilitiesFeatures,
    }),
    style: getFeatureStyle,
  });

  const locationsLayer = new VectorLayer({
    source: new VectorSource({
      features: locationsFeatures,
    }),
    style: getFeatureStyle,
  });

  const storesLayer = new VectorLayer({
    source: new VectorSource({
      features: storesFeatures,
    }),
    style: getFeatureStyle,
  });

  useEffect(() => {
    const initialMap = new Map({
      target: mapRef.current,
      layers: [
        mapSources.satellite,
        facilitiesLayer,
        locationsLayer,
        storesLayer,
      ],
      view: new View({
        center: centerCoords,
        zoom: 16,
      }),
    });

    // Create an overlay for the popup
    const popupOverlay = new Overlay({
      element: popupRef.current,
      positioning: "bottom-center",
      offset: [0, -10],
    });
    initialMap.addOverlay(popupOverlay);

    // Add click event listener to show popup for locations and modal for stores
    initialMap.on("click", (event) => {
      const feature = initialMap.forEachFeatureAtPixel(
        event.pixel,
        (feature) => feature
      );
      if (feature) {
        const content = feature.get("content");
        const coordinate = event.coordinate;
        if (feature.getGeometry().getType() === "MultiPoint") {
          setModalContent(content);
          setIsModalVisible(true);
          setPopupContent("");
          popupOverlay.setPosition(undefined);
        } else {
          setPopupContent(content);
          setPopupPosition(coordinate);
          popupOverlay.setPosition(coordinate);
        }
      } else {
        setPopupContent("");
        popupOverlay.setPosition(undefined);
      }
    });

    setMap(initialMap);

    return () => initialMap.setTarget(undefined);
  }, []);

  const handleViewChange = (viewType) => {
    if (map) {
      map.getLayers().clear();
      map.addLayer(mapSources[viewType]);
      map.addLayer(facilitiesLayer);
      map.addLayer(locationsLayer);
      map.addLayer(storesLayer);
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ position: "relative", width: "100%", height: "88vh" }}>
      <div ref={mapRef} style={{ width: "100%", height: "100%" }} />
      <Mapcontrols onChangeView={handleViewChange} />
      <div ref={popupRef} style={{ position: "absolute" }}>
        <Popover content={popupContent} open={!!popupContent}>
          <div></div>
        </Popover>
      </div>
      <Modal
        title="Store Details"
        open={isModalVisible}
        onOk={handleModalClose}
        onCancel={handleModalClose}
      >
        <pre>{modalContent}</pre>
      </Modal>
    </div>
  );
};

export default MapComponent;
