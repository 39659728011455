import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import {
  Button,
  Col,
  Drawer,
  Empty,
  Flex,
  Form,
  Image,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import inputStyles from "../../assets/styles/Custom/Input.module.css";
import UsersTable from "./UsersTable";
import {
  addUserApi,
  getGroupsApi,
  updateUserApi,
} from "../../services/users.services";
import {
  deleteDocs,
  displayFilterTag,
  renameFile,
  userRoles,
} from "../../helpers/utility";
import { readUsers } from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { getFacilitiesApi } from "../../services/location";
import { getDepartments } from "../../services/department.services";
import UserFilters from "./UserFilters";
import OrgSettingsHeader from "../Organisations/OrgSettingsHeader";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import FaceLogin from "./FaceLoginSetting";
import { UploadOutlined } from "@ant-design/icons";

const Users = () => {
  const [open, setOpen] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [currUser, setCurrUser] = useState({});
  const [facilities, setFacilities] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [teamsList, setTeamsList] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [filters, setFilters] = useState({
    teams: [],
    departments: [],
    roles: [],
  });
  const [imageUrl, setImageUrl] = useState(null);
  const [existUrl, setExistUrl] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);

  const [form] = Form.useForm();
  const [disableFacilty, setDisableFacilty] = useState(false);
  const { userDetails } = useContext(UserContext);
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setCurrUser({});
    form.resetFields();
    setFilteredTeams([]);
    setDisableFacilty(false);
    setImageUrl(null);
    setExistUrl(null);
  };

  const onFinish = async (values) => {
    values.face_login_image_url = imageUrl;
    if (!values.email && !values.mobile_number) {
      message.error("Please enter either email or mobile number");
    } else {
      let filteredValues = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) =>
            value !== "" && value !== null && value !== undefined
        )
      );
      filteredValues = {
        ...filteredValues,
        face_login_image_url: values?.face_login_image_url
          ? renameFile(values.face_login_image_url)
          : undefined,
      };

      try {
        const resp = await addUserApi(filteredValues);
        if (resp) {
          onClose();
          message.success(resp?.data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(currUser)?.length > 0) {
      const data = {
        ...currUser,
        department: currUser?.department?._id,
        facilities:
          currUser?.role === "admin"
            ? facilities?.map((each) => each?.value)
            : currUser?.facilities?.map((each) => each?._id),
        groups: currUser?.groups?.map((each) => each?._id),
      };

      setImageUrl(currUser?.face_login_image_url || null);
      setExistUrl(currUser?.face_login_image_url || null);

      showDrawer();
      setDisableFacilty(currUser?.role == "admin" ? true : false);
      setSelectedFacilities(data?.facilities);
      setFilteredTeams(
        teamsList?.filter((i) => data?.facilities?.includes(i?.facility))
      );
      form.setFieldsValue(data);
    }
  }, [currUser]);

  useEffect(() => {
    getFacilities();
    fetchDepartments();
    fetchTeams();
  }, []);

  const updateUser = async (values) => {
    values.face_login_image_url = imageUrl;
    if (!values.email && !values.mobile_number) {
      message.error("Please enter either email or mobile number");
    } else {
      Object.keys(values)?.map((entry) => {
        if (values[entry] === null || values[entry] === undefined) {
          values[entry] = "";
        }
      });
      if (values["facilities"] === "") {
        values["facilities"] = [];
      }
      values["face_login_image_url"] = values?.face_login_image_url
        ? renameFile(values.face_login_image_url)
        : undefined;
      try {
        const resp = await updateUserApi(currUser?._id, values);
        if (resp) {
          if (existUrl && existUrl !== imageUrl) {
            deleteDocs(true, existUrl, "cloud");
          }
        }
        onClose();
        message.success(resp?.data?.message);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
  };

  const getFacilities = async () => {
    try {
      const data = await getFacilitiesApi(true);
      setFacilities(
        data?.data?.map((eachFac) => ({
          label: eachFac?.name,
          value: eachFac?._id,
        }))
      );
    } catch (error) {}
  };

  const fetchDepartments = async () => {
    try {
      const res = await getDepartments();
      setDepartments(
        res?.data?.map((d) => ({
          title: d?.name,
          label: d?.name,
          value: d?._id,
        }))
      );
    } catch (error) {
      setDepartments([]);
    }
  };

  const fetchTeams = async () => {
    try {
      const resp = await getGroupsApi();
      let data = resp?.data?.groups?.map((g) => ({
        title: g?.name,
        label: g?.name,
        value: g?._id,
        facility: g?.facility?._id,
      }));
      setTeamsList(data);
    } catch (error) {
      setTeamsList([]);
    }
  };

  return (
    <CustomLayout header={<OrgSettingsHeader count={usersCount} />}>
      <Row gutter={[0, 16]}>
        <Col span={10}>
          <Space
            style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}
          >
            <Button
              style={{ color: "#6941C6" }}
              className={btnStyles.smBtn}
              type="default"
              icon={
                <Image
                  src={filterActiveIcon}
                  width={18}
                  height={18}
                  alt="filter"
                  preview={false}
                />
              }
              onClick={() => setShowFilterModal(true)}
            >
              Filter
            </Button>
          </Space>
          {showFilterModal && (
            <UserFilters
              showFilterModal={showFilterModal}
              setShowFilterModal={setShowFilterModal}
              teamsData={teamsList}
              departmentsData={departments}
              filters={filters}
              setFilters={setFilters}
            />
          )}
        </Col>
        {(filters?.teams?.length > 0 ||
          filters?.departments?.length > 0 ||
          filters?.roles?.length > 0) && (
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              gap: 6,
              flexWrap: "wrap",
            }}
          >
            {filters?.teams?.length > 0 &&
              displayFilterTag("Teams", "purple", filters?.teams?.length)}
            {filters?.departments?.length > 0 &&
              displayFilterTag(
                "Department",
                "blue",
                filters?.departments?.length
              )}
            {filters?.roles?.length > 0 &&
              displayFilterTag("Role", "volcano", filters?.roles?.length)}
            <Typography
              style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
              onClick={() =>
                setFilters({
                  teams: [],
                  departments: [],
                  roles: [],
                })
              }
            >
              Clear All
            </Typography>
          </Col>
        )}
        {readUsers.includes(userDetails?.activeOrg?.role) ? (
          <UsersTable
            setCurrUser={setCurrUser}
            currUser={currUser}
            setOpen={setOpen}
            setUsersCount={setUsersCount}
            filters={filters}
          />
        ) : (
          <Col span={24}>
            <div className="flex-center" style={{ minHeight: "50vh" }}>
              <Empty description="You are not authorised to view users and team" />
            </div>
          </Col>
        )}
      </Row>
      <Drawer
        maskClosable={false}
        footer={
          <Flex justify="flex-end" gap={12}>
            <Button
              onClick={() => {
                if (imageUrl && imageUrl !== existUrl) {
                  deleteDocs(true, imageUrl, "cloud");
                }
                onClose();
              }}
              className={btnStyles.mdBtn}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => form.submit()}
              className={btnStyles.mdBtn}
            >
              {Object.keys(currUser)?.length > 0 ? "Save" : "Add"}
            </Button>
          </Flex>
        }
        title={Object.keys(currUser)?.length > 0 ? "Edit User" : "Add User"}
        width={"55vw"}
        styles={{body:{padding:"12px 30px"}}}
        onClose={() => {
          if (imageUrl && imageUrl !== existUrl) {
            deleteDocs(true, imageUrl, "cloud");
          }
          onClose();
        }}
        open={open}
      >
        <Form
          onFinish={Object.keys(currUser)?.length > 0 ? updateUser : onFinish}
          layout="vertical"
          requiredMark={false}
          form={form}
        >
          <Row gutter={[32, 8]}>
          <Col span={12}>
          <Form.Item
            label="First name"
            name={"first_name"}
            rules={[
              { required: true, message: "Please enter first name" },
              {
                whitespace: true,
                message: "First name cannot be empty or contain only spaces",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter first name"
            />
          </Form.Item>
          </Col>
          <Col span={12}> 
          <Form.Item label="Last name" name={"last_name"}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter last name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email"
                name={"email"}
            rules={[
              {
                pattern: emailRegex,
                message: "Please enter a valid email",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter email"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            label="Phone number"
            name={"mobile_number"}
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please enter a valid 10-digit phone number",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter phone number"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            label="Employee ID"
            name={"employee_id"}
            rules={[
              {
                whitespace: true,
                message: "Employee ID cannot be empty or contain only spaces",
              },
            ]}
          >
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter employee ID"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label="Designation" name={"designation"}>
            <Input
              className={inputStyles.smInput}
              style={{ width: "100%" }}
              placeholder="Enter designation"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            rules={[{ required: true, message: "Please select role" }]}
            label="Role"
            name={"role"}
          >
            <Select
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              onChange={(e) => {
                setDisableFacilty(e == "admin");
                if (e == "admin") {
                  let facilityIds = facilities?.map((f) => f?.value);
                  setFilteredTeams(teamsList);
                  setSelectedFacilities(facilityIds);
                  form.setFieldsValue({
                    facilities: facilityIds,
                    groups: teamsList?.map((i) => i?.value),
                  });
                } else {
                  setSelectedFacilities([]);
                  setFilteredTeams([]);
                  form.setFieldsValue({
                    facilities: [],
                    groups: [],
                  });
                }
              }}
              options={
                userDetails?.activeOrg?.role !== "admin"
                  ? userRoles?.map((eachRole) =>
                      eachRole?.value == "admin"
                        ? { ...eachRole, disabled: true }
                        : eachRole
                    )
                  : userRoles
              }
              placeholder="Select role"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
            label="Facilities"
            name={"facilities"}
            rules={[
              {
                required: !disableFacilty,
                message: "Please select facilty",
              },
            ]}
          >
            <Select
              mode="multiple"
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              options={facilities}
              disabled={disableFacilty}
              placeholder="Select facilties"
              onChange={(e) => {
                let currTeams = form.getFieldValue("groups");
                let filteredTeamsList = teamsList?.filter((i) =>
                  e?.includes(i?.facility)
                );
                let updatedTeams = filteredTeamsList
                  ?.filter((f) => currTeams?.includes(f?.value))
                  ?.map((i) => i?.value);
                setSelectedFacilities(e);
                setFilteredTeams(filteredTeamsList);
                form.setFieldValue("groups", updatedTeams);
              }}
            />
          </Form.Item> 
          </Col>
          <Col span={12}>
          <Form.Item label="Teams" name={"groups"}>
            <Select
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              mode="multiple"
              options={filteredTeams}
              notFoundContent={
                <Space
                  direction="vertical"
                  align="center"
                  style={{ width: "100%" }}
                >
                  <Button
                    style={{ border: 0, pointerEvents: "none" }}
                    icon={Empty.PRESENTED_IMAGE_SIMPLE}
                  />
                  <Typography style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                    {selectedFacilities?.length === 0
                      ? "Please select facility"
                      : "No teams in selected facilities"}
                  </Typography>
                </Space>
              }
              placeholder="Select teams"
            />
          </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item label="Department" name={"department"}>
            <Select
              className={inputStyles.smSelect}
              style={{ width: "100%" }}
              placeholder="Select department"
              options={departments}
              allowClear
            />
          </Form.Item>
          </Col>
          {/* <Col span={12}>
          <Form.Item label="Employee Type" name={"employee_type"} initialValue={"full-time"}>
          <Radio.Group options={[
            {label:"Full-time Employee", value:"full-time"},
            {label:"Contract Employee", value:"contract"},
          ]}  />
          </Form.Item>
          </Col> */}
          <Col span={12}>
          {userDetails?.activeOrg?.organization?.settings?.face_login && (
            <Form.Item label="Face login" name={"face_login_image_url"}>
              {imageUrl ? (
                <div style={{ textAlign: "center" }}>
                  <Image
                    style={{
                      borderRadius: "50%",
                      width: "250px",
                      height: "300px",
                      objectFit: "cover",
                      marginBottom: "15px",
                    }}
                    src={imageUrl}
                    alt="face"
                    preview={false}
                  />
                  <div
                    style={{
                      gap: "10px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="primary"
                      onClick={() => {
                        setShowUpload(true);
                      }}
                    >
                      Retake
                    </Button>
                    <Button
                      type="primary"
                      onClick={async () => {
                        if (
                          (!existUrl && imageUrl) ||
                          (imageUrl && existUrl && imageUrl !== existUrl)
                        ) {
                          await deleteDocs(true, imageUrl, "cloud");
                        }
                        setImageUrl(null);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : (
                <Button type="primary" onClick={() => setShowUpload(true)} icon={<UploadOutlined />}>
                 Capture Face Image{" "}
                </Button>
              )}
              {showUpload && (
                <Modal
                  title="Upload face"
                  open={showUpload}
                  onCancel={() => setShowUpload(false)}
                  footer={null}
                  maskClosable={false}
                >
                  <FaceLogin
                    setImageUrl={setImageUrl}
                    imageUrl={imageUrl}
                    setShowUpload={setShowUpload}
                    form={form}
                    existUrl={existUrl}
                  />
                </Modal>
              )}
            </Form.Item>
          )}
          </Col>

          </Row>
        </Form>
      </Drawer>
    </CustomLayout>
  );
};

export default Users;
