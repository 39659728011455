import {
  Avatar,
  Card,
  Col,
  Empty,
  Flex,
  Image,
  List,
  Row,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { displayTitleValue } from "./helpers";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import icon1 from "../../assets/images/inventory/Icon1.png";
import icon2 from "../../assets/images/inventory/Icon2.png";
import icon3 from "../../assets/images/inventory/Icon3.png";
import icon4 from "../../assets/images/inventory/Icon4.png";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import {
  getInventoryCategoryStats,
  getInventoryConsumptionStats,
  getInventoryStats,
} from "../../services/dashboard.services";
import { getStoreListApi } from "../../services/inventory.services";
import { formatNumber } from "../../helpers/utility";
const SparePartConsumption = () => {
  const navigate = useNavigate();
  const [inventoryStats, setInventoryStats] = useState(null);
  const [categoryStats, setCategoryStats] = useState(null);
  const [storeChartData, setStoreChartData] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState("6-months");
  const [consumptionData, setConsumptionData] = useState(null);
  useEffect(() => {
    fetchInvenroryStats();
    fetchInventoryCategoryStats();
    fetchStoreData();
    getAllStoreList();
  }, []);

  useEffect(() => {
    if (selectedStore && selectedDateRange) {
      fetchInventoryConsumptionStats();
    }
  }, [selectedStore, selectedDateRange]);

  const fetchInvenroryStats = async () => {
    try {
      const res = await getInventoryStats();
      if (res && res.data) {
        setInventoryStats(res.data);
      }
    } catch (error) {
      console.log("Error in fetchInventoryCategoryStats", error);
    }
  };

  const getAllStoreList = async () => {
    try {
      const res = await getStoreListApi();
      if (res && res.data) {
        setStoreList(
          res?.data?.map((each) => ({ label: each?.name, value: each?._id }))
        );
        setSelectedStore(res?.data[0]?._id);
      }
    } catch (error) {
      console.log("Error in getAllStore List", error);
    }
  };

  const fetchInventoryConsumptionStats = async () => {
    try {
      let query = { dateRange: selectedDateRange };
      const res = await getInventoryConsumptionStats(selectedStore, query);
      if (res && res.data) {
        setConsumptionData(constructGraphData(res.data));
      }
    } catch (error) {
      console.log("Error in fetchInventoryConsumptionStats", error);
    }
  };

  const fetchStoreData = async () => {
    try {
      let query = { viewType: "chart" };
      const res = await getStoreListApi(query);
      if (res && res.data) {
        const pieChartColors = [
          "#8864d9",
          "#9e77ed",
          "#b692f6",
          "#d6bbfb",
          "#e9d7fe",
          "#eedfff",
        ];

        setStoreChartData(
          res?.data.map((each, index) => ({
            name: each?.name,
            value: each?.totalInventoryCount,
            cost: each?.totalInventoryCost,
            color: pieChartColors[index],
          }))
        );
      }
    } catch (error) {
      console.log("Error in fetchStoreData", error);
    }
  };

  const fetchInventoryCategoryStats = async () => {
    try {
      const res = await getInventoryCategoryStats();
      if (res && res.data) {
        setCategoryStats(res.data);
      }
    } catch (error) {
      console.log("Error in fetchInventoryCategoryStats", error);
    }
  };

  const constructGraphData = (data) => {
    try {
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const graphData = [];

      const currentDate = new Date();
      const range = selectedDateRange === "6-months" ? 5 : 11;
      for (let i = range; i >= 0; i--) {
        const date = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - i,
          1
        );
        const monthIndex = date.getMonth() + 1;
        const year = date.getFullYear();
        const monthName = months[date.getMonth()];

        const record = data.find(
          (each) => each?.month === monthIndex && each?.year === year
        );
        graphData.push({
          month: monthName,
          total_used_cost: record ? record?.totalUsedCost : 0,
        });
      }
      return graphData;
    } catch (error) {
      console.log(error);
    }
  };

  const listData = [
    {
      title: "Stockout Risk Alert",
      description:
        "Critical spare part (ABC123) may run out in 7 days based on usage trends. Reorder now to prevent downtime.",
    },
    {
      title:
        "Predictive Spare Parts Demand",
      description:
        "Historical failure patterns indicate a 20% rise in demand for Motor Couplings next quarter. Adjust stock levels accordingly",
    },
    {
      title:
        "Slow-Moving & Dead Stock",
      description:
        "25% of your inventory has remained unused for 6+ months, tying up $50,000 in idle stock. Consider liquidation or redistribution.",
    },
    {
      title: "Work Order Delays Due to Inventory Gaps",
      description:
        "5 maintenance tasks are delayed due to missing spare parts. Align procurement with work order schedules to minimize downtime.",
    },
  ];

  const formatCategoryName = (name) => {
    return name.length > 15 ? `${name.slice(0, 12)}...` : name;
  };

  const cardsData = [
    {
      title: "Total Inventory",
      value: formatNumber(Number(inventoryStats?.total_inventory_count) || 0),
      color: "#766BFF",
      icon: icon1,
      cost: formatNumber(Number(inventoryStats?.total_inventory_cost) || 0),
      tagColor: "purple",
    },
    {
      title: "Obsolete",
      value: formatNumber(
        Number(inventoryStats?.total_obselete_inv_count) || 0
      ),
      color: "#067647",
      icon: icon2,
      cost: formatNumber(Number(inventoryStats?.total_obselete_inv_cost) || 0),
      tagColor: "green",
    },
    {
      title: "Low Stock",
      value: formatNumber(
        Number(inventoryStats?.total_low_stock_inv_count) || 0
      ),
      color: "#FB8457",
      icon: icon3,
      cost: formatNumber(Number(inventoryStats?.total_low_stock_inv_cost) || 0),
      tagColor: "volcano",
    },
    {
      title: "Not Returned",
      value: formatNumber(
        Number(inventoryStats?.total_not_returned_inv_count) || 0
      ),
      color: "#F79009",
      icon: icon4,
      cost: formatNumber(
        Number(inventoryStats?.total_not_returned_inv_cost) || 0
      ),
      tagColor: "gold",
    },
  ];

  const renderTooltipWithLabel = (props) => {
    const { active, payload } = props;
    if (active && payload && payload.length) {
      return (
        <Card
          title={null}
          styles={{ body: { padding: "10px 15px" } }}
          style={{
            padding: "0px",
            background: "white",
            border: "1px solid #f0f0f0",
            boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.2)",
          }}
        >
          <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
            {payload[0]?.payload?.name} :{" "}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14, fontWeight: 500 }}>
            {formatNumber(payload[0]?.payload?.value)}
          </Typography.Text>
        </Card>
      );
    }
    return null;
  };
  const RenderCards = ({ title, value, color, icon, cost, tagColor }) => {
    return (
      <Col span={6} style={{ height: "100%" }} key={title}>
        <Card
          className="mainCards1"
          style={{ height: "100%" }}
          onClick={() => {
            if (title === "Total Inventory") {
              navigate("/inventory");
            } else if (title === "Low Stock") {
              navigate("/dashboard?section=low-stock");
            } else if (title === "Not Returned") {
              navigate("/dashboard?section=not-returned");
            } else if (title === "Obsolete") {
              navigate("/dashboard?section=obsolete");
            }
          }}
        >
          <Row>
            <Col span={14}>
              <Space direction="vertical">
                <Typography.Text
                  style={{ fontSize: 18, color: "#787878", fontWeight: 400 }}
                >
                  {title}
                </Typography.Text>
                <Typography.Title
                  style={{ color: color, fontSize: 30, margin: 0 }}
                >
                  {value}
                </Typography.Title>
                <Tag color={tagColor} style={{ fontSize: 14 }}>
                  ₹ {cost}
                </Tag>
              </Space>
            </Col>
            <Col span={10} style={{ display: "flex", justifyContent: "end" }}>
              <Image src={icon} preview={false} width={70} />
            </Col>
          </Row>
        </Card>
      </Col>
    );
  };
  return (
    <>
      <Row gutter={[18, 18]} style={{ width: "100%", padding: "0px 15px" }}>
        {cardsData &&
          cardsData.map((item, index) => (
            <RenderCards
              key={index}
              title={item?.title}
              value={item?.value}
              color={item?.color}
              icon={item?.icon} // Increase width to prevent line breaks
              cost={item?.cost}
              tagColor={item?.tagColor}
            />
          ))}
      </Row>
      <Row
        gutter={[18, 18]}
        style={{ width: "100%", padding: "0px 15px", marginTop: "6px" }}
      >
        <Col span={12}>
          <Card
            className="mainCards"
            title="Store wise Inventory"
            style={{ height: "350px" }}
            styles={{
              body: { padding: "0 20px", height: "280px" },
              header: { border: 0 },
            }}
          >
            <Row
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              {storeChartData?.length === 0 && (
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Data"
                  />
                </Col>
              )}
              <Col
                span={10}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart width={400} height={400}>
                    <Pie
                      data={storeChartData}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      outerRadius={100}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {storeChartData?.map((entry, index) => (
                        <Cell
                          className="pie-cell"
                          key={`cell-${index}`}
                          fill={entry?.color}
                        />
                      ))}
                    </Pie>
                    <Tooltip content={renderTooltipWithLabel} />
                  </PieChart>
                </ResponsiveContainer>
              </Col>
              <Col
                span={14}
                style={{
                  // height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: "20px",
                }}
              >
                <Space
                  direction="vertical"
                  size={10}
                  style={{ width: "100%", padding: "0 20px" }}
                >
                  {storeChartData &&
                    storeChartData.map((each, index) => (
                      <>
                        {displayTitleValue({
                          title: (
                            <Space
                              style={{
                                gap: 5,
                                alignItems: "flex-start",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  maxWidth: "150px", // Adjust width as needed
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                }}
                                title={each?.name} // Show full text on hover
                              >
                                {each?.name}({each?.value})
                              </div>
                              {/* <Tag color="purple" style={{ fontSize: "12px" }}>
                              {each?.value}
                            </Tag> */}
                            </Space>
                          ),
                          value: `₹ ${formatNumber(each?.cost)}`,
                          dotColor: each?.color,
                          valueFontSize: "subtext2",
                        })}
                      </>
                    ))}
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={12}>
          <Card
            className="mainCards"
            styles={{ header: { border: 0 } }}
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>Consumption Trend</div>
                <div>
                  <Space>
                    <Select
                      value={selectedStore}
                      placeholder="Select Store"
                      style={{ width: 200 }}
                      options={storeList}
                      onChange={(value) => setSelectedStore(value)}
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                      showSearch
                    />
                    <Select
                      defaultValue="6-months"
                      style={{ width: 150 }}
                      options={[
                        { value: "6-months", label: "Last 6 months" },
                        { value: "1-year", label: "Last 12 months" },
                      ]}
                      onChange={(value) => setSelectedDateRange(value)}
                    />
                  </Space>
                </div>
              </div>
            }
            // style={{ height: "350px" }}
          >
            {storeList?.length === 0 ? (
              <Col
                span={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: 245,
                  justifyContent: "center",
                }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Data"
                />
              </Col>
            ) : (
              <ResponsiveContainer width="100%" height={245}>
                <BarChart
                  data={consumptionData}
                  margin={{ top: 25, right: 25, left: 25, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" axisLine={false} />
                  <YAxis
                    axisLine={false}
                    tickFormatter={(value) => [`₹${formatNumber(value)}`]}
                  />
                  <Bar
                    dataKey="total_used_cost"
                    fill="#8381FF"
                    barSize={24}
                    radius={[4, 4, 0, 0]}
                  />

                  <Tooltip
                    contentStyle={{
                      background: "#fff",
                      border: "1px solid #f0f0f0",
                    }}
                    cursor={{ fill: "transparent" }}
                    formatter={(value) => [`₹ ${formatNumber(value)}`]}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card>
        </Col>
      </Row>
      <Row
        gutter={[18, 18]}
        style={{ width: "100%", padding: "0px 15px", marginTop: "6px" }}
      >
        
        <Col span={12}>
          <Card
            className="mainCards"
            title={"Categories"}
            styles={{
              header: { border: 0 },
              body: { 
                paddingTop: 0, 
                paddingLeft: 10,
                height: '375px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              },
            }}
          >
            {categoryStats?.length === 0 ? (
              <Col
                span={24}
                style={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  justifyContent: "center",
                }}
              >
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No Data"
                />
              </Col>
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  layout="vertical"
                  data={categoryStats}
                  margin={{ top: 25, right: 5, left: 5, bottom: 15 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis
                    type="category"
                    dataKey="name"
                    axisLine={false}
                    tickFormatter={formatCategoryName}
                    width={120}
                  />
                  <XAxis
                    type="number"
                    axisLine={false}
                    label={{
                      value: "Total Inventory Count",
                      position: "insideBottom",
                      offset: -5,
                    }}
                    allowDecimals={false}
                  />
                  <Bar
                    dataKey="totalInventoryCount"
                    fill="#8884D8"
                    barSize={24}
                    radius={[0, 4, 4, 0]}
                  />
                  <Tooltip
                    contentStyle={{
                      background: "#fff",
                      border: "1px solid #f0f0f0",
                    }}
                    cursor={{ fill: "transparent" }}
                    formatter={(value) => [formatNumber(Number(value))]}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            className="mainCards"
            title={"Insights"}
            styles={{ 
              header: { border: 0 }, 
              body: { paddingTop: 5, height: '375px', overflow: 'auto' }
            }}
          >{
            console.log("process.env.REACT_APP_ENV_TYPE",process.env.REACT_APP_ENV_TYPE)
          }
            <List
              size="small"
              itemLayout="horizontal"
              dataSource={process.env.REACT_APP_ENV_TYPE === "DEVELOPMENT" ? listData : []}
              renderItem={(item, index) => (
                <List.Item style={{ border: 0 }}>
                  <List.Item.Meta
                    avatar={
                      <div
                        style={{
                          color: "#766BFF",
                          background: "#766BFF4A",
                          width: "30px",
                          height: "30px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "50%",
                        }}
                      >
                        {index + 1}
                      </div>
                    }
                    title={
                      <Typography.Text style={{ fontWeight: "normal" }}>
                        {item?.title}
                      </Typography.Text>
                    }
                    description={item?.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SparePartConsumption;
