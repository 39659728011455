import {
  Button,
  Col,
  DatePicker,
  Empty,
  Result,
  Table,
  Tag,
  Typography,
  Popover,
  Input,
  Skeleton,
  Pagination,
  Space,
  Image,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import "./Maintanance.css";
import {
  capitalizeFirstLetter,
  priorityOptions,
  debounce,
  convertUTCToLocalFormat,
  displayFilterTag,
  convertToUTCFormat,
  tableComponents,
  scrollToTopTable,
  displayTaskType,
  getSearchDefaultValue,
  displayScheduleType,
} from "../../helpers/utility";
import { getMaintanancesApi } from "../../services/maintenance.services";
import tagStyles from "../../assets/styles/Custom/Tag.module.css";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import {
  INSPECTION,
  PAGESIZE,
  createPM,
  frequencyOptions,
} from "../../constants/defaultKeys";
import { UserContext } from "../../context/UserProvider";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import {
  getAllAssetsApi,
  getProductionLines,
} from "../../services/assets.services";
import PmFilters from "./PmFilters";
import filterActiveIcon from "../../assets/images/filterActiveIcon.svg";
import { getItem, removeItem, setItem } from "../../helpers/localStorage";

const { RangePicker } = DatePicker;

const MaintenanceTable = ({ viewType, setViewType, type }) => {
  const tableRef = useRef(null);

  const navigate = useNavigate();
  const [maintanaces, setMaintanances] = useState([]);
  const { userDetails } = useContext(UserContext);
  const [dateFilter, setDateFilter] = useState({
    start_date: dayjs().subtract(2, "month"),
    end_date: dayjs(),
  });
  const [search, setSearch] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState({
    asset: undefined,
    production_line: undefined,
    subTypeObj: [],
    priorities: [],
  });
  const [assets, setAssets] = useState([]);
  const [productionLines, setProductionLines] = useState([]);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [selectedFreq, setSelectedFreq] = useState(null);

  const fetchAssets = async () => {
    try {
      const resp = await getAllAssetsApi();
      setAssets(
        resp?.data?.assets?.map((asset) => ({
          label: (
            <div
              style={{
                gap: 8,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Tag className={tagStyles.smTag} color="blue">
                {asset?.code}
              </Tag>
              <span
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {asset?.name}
              </span>
            </div>
          ),
          name: asset?.name,
          value: asset?._id,
        }))
      );
    } catch (error) {
      setAssets([]);
    }
  };

  const fetchProductionLines = async () => {
    try {
      const res = await getProductionLines();
      setProductionLines(
        res?.data?.assets?.map((item) => ({
          ...item,
          label: item?.name,
          value: item?._id,
        }))
      );
    } catch (error) {
      setProductionLines([]);
    }
  };

  useEffect(() => {
    fetchAssets();
    fetchProductionLines();

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      setDateFilter({
        start_date: locStorageData?.dateFilter?.start_date
          ? dayjs(locStorageData?.dateFilter?.start_date)
          : null,
        end_date: locStorageData?.dateFilter?.end_date
          ? dayjs(locStorageData?.dateFilter?.end_date)
          : null,
      });
      setFilters({
        asset: locStorageData?.filters?.asset || undefined,
        production_line: locStorageData?.filters?.production_line || undefined,
        subTypeObj: locStorageData?.filters?.subTypeObj || [],
        priorities: locStorageData?.filters?.priorities || [],
      });
      locStorageData?.selectedFreq &&
        setSelectedFreq(locStorageData?.selectedFreq);
      locStorageData?.search && setSearch(locStorageData?.search);
      locStorageData?.pageNumber && setPageNumber(locStorageData?.pageNumber);
    }
  }, []);

  function generateScheduleString(
    startDate,
    recurrenceType,
    dayOfWeek,
    dayOfMonth,
    monthOption,
    month,
    every
  ) {
    let dayOfWeekStr = dayOfWeek?.join(", ") || "";
    let scheduleString = "Occurs ";
    switch (recurrenceType) {
      case "daily":
        scheduleString += every > 1 ? `every ${every} days` : "every day";
        break;
      case "weekly":
        const weeklyString =
          every > 1
            ? `every ${every} weeks on ${dayOfWeekStr}`
            : `every ${dayOfWeekStr}`;
        scheduleString += weeklyString;
        break;
      case "monthly":
        scheduleString += "";
        if (dayOfMonth) {
          scheduleString +=
            every > 1
              ? `every ${every} months on day ${dayOfMonth}`
              : `	
              every month on day ${dayOfMonth}`;
        } else {
          scheduleString +=
            every > 1
              ? `every ${every} months on ${monthOption} ${dayOfWeekStr}`
              : `every month on ${monthOption} ${dayOfWeekStr}`;
        }
        break;
      case "yearly":
        scheduleString += "every year on ";
        if (dayOfMonth) {
          scheduleString += `day ${dayOfMonth} of ${month}`;
        } else {
          scheduleString += `${monthOption} ${dayOfWeekStr} of ${month}`;
        }
        break;
      default:
        return "Invalid recurrence type";
    }
    if (startDate) {
      scheduleString += ` at ${dayjs(convertUTCToLocalFormat(startDate)).format(
        "hh:mm A"
      )}`;
    }

    return startDate ? scheduleString : "";
  }

  const columns = [
    {
      title: "Scheduled at",
      dataIndex: "Scheduled at",
      key: "Scheduled at",
      width: 250,
      fixed: "left",
      render: (val, record) => (
        <>
          {generateScheduleString(
            record["start_date"],
            record["repeat"],
            record["week_days"],
            record["day_of_month"],
            record["month_option"],
            record["month"],
            record["every"]
          ) || "-"}
        </>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 140,
      fixed: "left",
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Type",
      dataIndex: "subTypeObj",
      key: "subTypeObj",
      width: 140,
      render: (val) => (val?.name ? type == INSPECTION ? displayScheduleType(val?.name) : displayTaskType(val?.name) : "-"),
    },
    {
      title: "Asset/ Production line",
      dataIndex: "asset",
      key: "asset",
      width: 250,
      render: (val, record) => (
        <>{record?.asset?.name || record?.production_line?.name || "-"}</>
      ),
    },
    {
      title: "Location",
      dataIndex: "site",
      key: "site",
      width: 200,
      render: (val, record) => (
        <>
          {type == INSPECTION
            ? record?.asset?.location?.name || "-"
            : record?.asset
            ? record?.asset?.location?.name || "-"
            : record?.production_line?.location?.name || "-"}
        </>
      ),
    },
    {
      title: "Assigned to",
      dataIndex: "assigned_users",
      key: "assigned_users",
      width: 200,
      render: (val, record) => {
        const list = [
          ...(record?.assigned_users || []),
          ...(record?.assigned_groups || []),
        ];

        return (
          <>
            {list?.length > 0 ? (
              <div
                className="flex-center"
                style={{
                  alignItems: "flex-start",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                {list?.slice(0, 2)?.map((group) => (
                  <Typography>
                    {group?.first_name || group?.name}
                    {list?.length > 1 ? "," : ""}
                  </Typography>
                ))}
                {list?.length > 2 && (
                  <Popover
                    content={
                      <div
                        className="flex-center"
                        style={{
                          flexDirection: "column",
                          gap: "6px",
                          alignItems: "flex-start",
                        }}
                      >
                        {list?.map((group) => (
                          <Typography>
                            {group?.first_name || group?.name}
                          </Typography>
                        ))}
                      </div>
                    }
                  >
                    <Tag>+{list?.length - 2}</Tag>
                  </Popover>
                )}
              </div>
            ) : (
              <>-</>
            )}
          </>
        );
      },
    },
    {
      title: "SOP",
      dataIndex: "sop",
      key: "sop",
      width: 140,
      render: (val, record) => (
        <>{record?.sop?._id ? record?.sop?.code : "-"}</>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      width: 140,
      render: (val, record) => {
        const color =
          priorityOptions.filter((pri) => pri.value === val)[0]?.color ||
          "blue";
        return (
          <Tag
            style={{ minWidth: "80px", border: "0px" }}
            className={tagStyles.smTag}
            color={color}
          >
            {capitalizeFirstLetter(val) || "-"}
          </Tag>
        );
      },
    },
    {
      title: "Last completion date",
      width: 180,
      dataIndex: "last_completion_date",
      key: "last_completion_date",
      render: (val, record) => (
        <>
          {val
            ? dayjs(convertUTCToLocalFormat(val)).format("DD MMM YYYY hh:mm A")
            : "-"}
        </>
      ),
    },
  ];

  const getMaintanaces = async (data, clearLocStorage) => {
    let params = { page: data?.pageNumber };
    if (data?.search) {
      params.name = data?.search;
    }
    if (type) {
      params.type = type;
    }
    if (data?.dateFilter?.start_date) {
      params.start_date = convertToUTCFormat(
        data?.dateFilter.start_date.startOf("day")
      );
    }
    if (data?.dateFilter?.end_date) {
      params.end_date = convertToUTCFormat(
        data?.dateFilter.end_date.endOf("day")
      );
    }
    data?.filters?.asset && (params.asset = data?.filters?.asset);
    data?.filters?.production_line &&
      (params.production_line = data?.filters?.production_line);
    data?.filters?.subTypeObj?.length > 0 &&
      (params.subTypeObj = data?.filters?.subTypeObj);
    data?.filters?.priorities?.length > 0 &&
      (params.priority = data?.filters?.priorities);
    data?.selectedFreq && (params.repeat = data?.selectedFreq);

    try {
      setLoading(true);
      const resp = await getMaintanancesApi(params);
      setMaintanances(resp?.data);
    } catch (error) {
      setMaintanances([]);
    } finally {
      setLoading(false);
      clearLocStorage && removeItem("applied_filters");
    }
  };

  useEffect(() => {
    const fetchData = async (data, status = false) => {
      await getMaintanaces(data, status);
      scrollToTopTable(tableRef);
    };

    let locStorageData = getItem("applied_filters", true);
    if (locStorageData) {
      fetchData(
        {
          ...locStorageData,
          dateFilter: {
            start_date: locStorageData?.dateFilter?.start_date
              ? dayjs(locStorageData?.dateFilter?.start_date)
              : null,
            end_date: locStorageData?.dateFilter?.end_date
              ? dayjs(locStorageData?.dateFilter?.end_date)
              : null,
          },
        },
        true
      );
    } else {
      fetchData({
        dateFilter: dateFilter,
        filters: filters,
        selectedFreq: selectedFreq,
        search: search,
        pageNumber: pageNumber,
      });
    }

    // fetchData();
  }, [
    userDetails?.currFacility,
    // dateFilter,
    // search,
    // selectedAsset,
    type,
    pageNumber,
    refresh,
  ]);

  useEffect(() => {
    let locStorageData = getItem("applied_filters", true);
    if (!locStorageData) {
      if (pageNumber === 1) {
        setRefresh((prev) => !prev);
      } else {
        setPageNumber(1);
      }
    }
  }, [search, dateFilter, filters, selectedFreq]);

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  const savePmInsConfigFilters = () => {
    let dataObj = {
      dateFilter: dateFilter,
      filters: filters,
      selectedFreq: selectedFreq,
      search: search,
      pageNumber: pageNumber,
    };
    setItem("applied_filters", dataObj, true);
  };

  return (
    <>
      <Col span={10}>
        <Space style={{ width: "100%", justifyContent: "flex-start", gap: 12 }}>
          <Button
            style={{ color: "#6941C6" }}
            className={btnStyles.smBtn}
            type="default"
            icon={
              <Image
                src={filterActiveIcon}
                width={18}
                height={18}
                alt="filter"
                preview={false}
              />
            }
            onClick={() => setShowFilterModal(true)}
          >
            Filter
          </Button>
        </Space>
        {showFilterModal && (
          <PmFilters
            type={type === INSPECTION ? "Inspection" : "PM"}
            viewType={"conf"}
            showFilterModal={showFilterModal}
            setShowFilterModal={setShowFilterModal}
            assets={assets}
            productionLines={productionLines}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </Col>
      <Col offset={4} span={10} style={{ textAlign: "right" }}>
        <RangePicker
          id="pm-conf-filter"
          format="DD-MMM-YYYY"
          // allowClear={false}
          value={[dateFilter.start_date, dateFilter.end_date]}
          onChange={(dates, dateString) => {
            setDateFilter({
              start_date: dates?.[0],
              end_date: dates?.[1],
            });
          }}
        />
      </Col>
      {(filters?.asset ||
        filters?.production_line ||
        filters?.subTypeObj?.length > 0 ||
        filters?.priorities?.length > 0) && (
        <Col
          span={24}
          style={{
            display: "flex",
            alignItems: "center",
            gap: 6,
            flexWrap: "wrap",
          }}
        >
          {filters?.asset && displayFilterTag("Asset", "purple")}
          {filters?.production_line &&
            displayFilterTag("Production Line", "purple")}
          {filters?.subTypeObj?.length > 0 &&
            displayFilterTag(
              `${type === INSPECTION ? "Inspection" : "PM"} Type`,
              "volcano",
              filters?.subTypeObj?.length
            )}
          {filters?.priorities?.length > 0 &&
            displayFilterTag(
              "Priority",
              "magenta",
              filters?.priorities?.length
            )}
          <Typography
            style={{ color: "#7369F4", cursor: "pointer", fontSize: 12 }}
            onClick={() =>
              setFilters({
                asset: undefined,
                production_line: undefined,
                subTypeObj: [],
                priorities: [],
              })
            }
          >
            Clear All
          </Typography>
        </Col>
      )}
      <Col span={24}>
        <Table
          ref={tableRef}
          title={() => (
            <Space
              align="center"
              style={{
                width: "100%",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <Space align="center" style={{ gap: 0 }}>
                <Typography style={{ marginRight: 8 }}>Frequency</Typography>
                {frequencyOptions?.map((item) => (
                  <Tag
                    style={{
                      cursor:
                        item?.value === selectedFreq ? "default" : "pointer",
                      backgroundColor:
                        item?.value === selectedFreq ? "#f9f0ff" : "#f0f9ff",
                      borderRadius: 4,
                    }}
                    className={tagStyles.mdTag}
                    color={item?.value === selectedFreq ? "purple" : "default"}
                    onClick={() => setSelectedFreq(item?.value)}
                  >
                    {item?.label}
                    {item?.value === selectedFreq && (
                      <CloseOutlined
                        style={{ fontSize: 12 }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedFreq(null);
                        }}
                      />
                    )}
                  </Tag>
                ))}
              </Space>
              <Space
                align="center"
                style={{ justifyContent: "flex-end", gap: 16 }}
              >
                <Input.Search
                  size="large"
                  defaultValue={getSearchDefaultValue()}
                  onChange={debounce(handleSearch, 500)}
                  placeholder={`Search by ${
                    type == INSPECTION ? "Schedule" : "PM"
                  } name or code`}
                  prefix={
                    <SearchOutlined
                      style={{ fontSize: 16, color: "#667085" }}
                    />
                  }
                  style={{ width: "300px" }}
                  className="custom-input-search"
                />
                {createPM.includes(userDetails?.activeOrg?.role) && (
                  <Button
                    type="primary"
                    id="create-pm"
                    className={btnStyles.mdBtn}
                    onClick={() =>
                      navigate(
                        `/${
                          type == INSPECTION ? "inspections" : "maintenances"
                        }/create`
                      )
                    }
                  >
                    + Create {type == INSPECTION ? "Schedule" : "PM"}
                  </Button>
                )}
              </Space>
            </Space>
          )}
          key={`${type}_config`}
          onRow={(item) => {
            return {
              onClick: (e) => {
                // !item?.children?.length > 0 &&
                //   navigate(
                //     `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                //       item?._id
                //     }`
                //   );
                if (!item?.children?.length > 0) {
                  navigate(
                    `/${type == INSPECTION ? "inspections" : "maintenances"}/${
                      item?._id
                    }`
                  );
                  savePmInsConfigFilters();
                }
              },
            };
          }}
          scroll={{
            x: 1200,
            y: "55vh",
          }}
          loading={
            loading
              ? {
                  spinning: false,
                  indicator: null,
                }
              : false
          }
          locale={{
            emptyText: loading ? (
              <Skeleton active />
            ) : (
              <Result
                icon={Empty.PRESENTED_IMAGE_SIMPLE}
                title={
                  <Typography>{`No ${
                    type == INSPECTION ? "Inspections" : "PM's"
                  } found`}</Typography>
                }
              />
            ),
          }}
          bordered
          rowClassName={"rowClassName"}
          columns={columns}
          dataSource={maintanaces?.maintenances || []}
          pagination={false}
          components={tableComponents}
        />
      </Col>
      {maintanaces?.totalMaintenances > PAGESIZE && (
        <Col span={24} className="text-center">
          <Pagination
            current={pageNumber}
            pageSize={PAGESIZE}
            total={maintanaces?.totalMaintenances}
            onChange={(e) => setPageNumber(e)}
            showSizeChanger={false}
          />
        </Col>
      )}
    </>
  );
};

export default MaintenanceTable;
