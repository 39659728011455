import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Flex, Row, Table, Tag, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { getStoreMapData } from "../../../services/dashboard.services";
import { tableComponents } from "../../../helpers/utility";
import dayjs from "dayjs";
import TableFilters from "../TableFilters";

const LowStockList = ({ type }) => {
  const [lowStockData, setLowStockData] = useState([]);
  const [totalLowStock, setTotalLowStock] = useState(0);
  const [page, setPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  useEffect(() => {
    fetchLowStock();
  }, [selectedFilters]);
  const fetchLowStock = async () => {
    try {
      let query = { page: page, filterType: type,
        store_id: selectedFilters?.store,
        inventory_id: selectedFilters?.inventory,
       };
      const resp = await getStoreMapData(query);
      setLowStockData(resp?.data?.storeInvList || []);
      setTotalLowStock(resp?.data?.totalStoreInvCount || 0);
    } catch (error) {
      console.log(error);
    }
  };
   const handleDownload = useCallback(async () => {
      try {
        let query = {
          download: true,
          filterType: type,
          store_id: selectedFilters?.store,
          inventory_id: selectedFilters?.inventory,
        };
  
        const resp = await getStoreMapData(query,true);
        const url = window.URL.createObjectURL(new Blob([resp.data]));
  
        const a = document.createElement("a");
        a.href = url;
        a.download = type === "low-stock" ? "LowStockInventory.xlsx" : "ObsoleteInventory.xlsx";
        document.body.appendChild(a);
        a.click();
  
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
      }
    }, [selectedFilters]);

  const tableColumns = [
    {
      title :"Item code",
      dataIndex :"inventory",
      key :"inventory",
      width :"100px",
      render : (value) => {
        return <Typography>{value?.code || "-"}</Typography>
      }
    },
    {
      title: "Item",
      dataIndex: "inventory",
      key: "inventory",
      width: "400px",
      render: (value) => {
        return (
          <Flex gap={10}>
            <Typography>{value?.name || "-"}</Typography>
            {/* <Tag color="blue">{value?.code || "-"}</Tag> */}
          </Flex>
        );
      },
    },
    {
      title: "Store",
      dataIndex: "store",
      key: "store",
      width: "350px",
      render: (value) => {
        return <Typography>{value?.name || "-"}</Typography>;
      },
    },
    {
      title: "Available Quantity",
      dataIndex: "available_quantity",
      key: "available_quantity",
      render: (value) => {
        return <Typography>{value || 0} </Typography>;
      },
    },
    ...(type === "low-stock"
      ? [
          {
            title: "Low Stock Threshold",
            dataIndex: "low_stock_threshold",
            key: "low_stock_threshold",
            render: (value) => <Typography>{value}</Typography>,
          },
        ]
      : [
          {
            title: "Last Used",
            dataIndex: "last_used_date",
            key: "last_used_date",
            render: (value) => (
              <Typography>
                {dayjs(formatUTCToLocal(value)).fromNow()}
              </Typography>
            ),
          },
        ]),

    {
      title: "Total Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      width: "250px",
      render: (value, record) => (
        <Typography>
          ₹ {record?.inventory?.cost * record?.available_quantity || 0}
        </Typography>
      ),
    },
  ];
  return (
    <Row gutter={[24, 12]}>
      <Col span={24} style={{ display: "flex", justifyContent: "space-between" }}>
        <Flex gap={10} align="center">
          <Button type="text" onClick={() => window.history.back()}>
            <LeftOutlined />
          </Button>
          <Typography>
            {" "}
            {type == "low-stock" ? "Low Stock" : "Obsolete"}
          </Typography>
        </Flex>
        <TableFilters
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          handleDownload={handleDownload}
        />
      </Col>
      <Col span={24}>
        <Table
          columns={tableColumns}
          components={tableComponents}
          dataSource={lowStockData}
          pagination={false}
          scroll={{ x: 768 }}
          bordered
        />
      </Col>
    </Row>
  );
};

export default LowStockList;
