import { Button, Col, Select, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  getInventoryList,
  getStoreListApi,
} from "../../services/inventory.services";
import { debounce } from "../../helpers/utility";

const TableFilters = ({
  selectedFilters,
  setSelectedFilters,
  handleDownload,
}) => {
  const [allStores, setAllStores] = useState([]);
  const [allInventories, setAllInventories] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedStore, setSelectedStore] = useState("");
  useEffect(() => {
    fetchAllStores();
  }, []);

  const fetchAllStores = async () => {
    try {
      const res = await getStoreListApi();
      setAllStores(
        res?.data?.map((store) => ({ label: store.name, value: store._id }))
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!searchText) {
      fetchAllInventories({ page: 1 });
    } else {
      fetchAllInventories({ name: searchText });
    }
  }, [searchText]);

  const fetchAllInventories = useCallback(
    debounce(async (query) => {
      if (!query) return;

      try {
        const res = await getInventoryList(query);
        setAllInventories(
          res?.data?.inventoryItems.map((item) => ({
            label: item?.name,
            value: item?._id,
          })) || []
        );
      } catch (e) {
        console.error(e);
        message.error("Failed to fetch search results");
      }
    }, 500),
    []
  );

  return (
    <>
      <Space>
        <Select
          style={{ width: 200 }}
          placeholder="Select Store"
          options={allStores}
          filterOption={(input, option) =>
            option.children.toLowerCase().includes(input.toLowerCase())
          }
          showSearch
          onChange={(value) => {
            setSelectedFilters({ ...selectedFilters, store: value });
          }}
          allowClear
          onClear={() => {
            setSelectedFilters({ ...selectedFilters, inventory: null });
          }}
        />
        <Select
          style={{ width: 200 }}
          value={selectedFilters?.inventory}
          onSearch={setSearchText}
          placeholder="Select Inventory"
          options={allInventories}
          filterOption={false}
          showSearch
          onChange={(value) =>
            setSelectedFilters({
              ...selectedFilters,
              inventory: value,
            })
          }
          allowClear
          onClear={() => {
            setSelectedFilters({
              ...selectedFilters,
              inventory: null,
            });
            setSearchText("");
          }}
        />
        <Button type="primary" onClick={handleDownload}>
          Download report
        </Button>
      </Space>
    </>
  );
};

export default TableFilters;
