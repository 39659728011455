import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Typography, Tabs, ConfigProvider } from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import "./Locations.css";
import CustomLayout from "../Layout";
import TreeLocations from "./TreeLocatios";
import FacilityTable from "./FacilityTable";
import { useNavigate, useSearchParams } from "react-router-dom";
import MapComponent from "./Map";

const { TabPane } = Tabs;

const Locations = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [type, setType] = useState("all");

  useEffect(() => {
    const currentType = searchParams.get("type");
    setType(currentType === "facility" || currentType === "map" ? currentType : "all");
  }, [searchParams]);

  const handleTabChange = useCallback(
    (key) => {
      navigate(`/locations?type=${key}`);
    },
    [navigate]
  );

  const tabItems = useMemo(
    () => [
      {
        key: "all",
        label: "All locations",
      },
      {
        key: "facility",
        label: "Facilities",
      },
      {
        key: "map",
        label: "Map",
      },
    ],
    []
  );

  const renderContent = useMemo(() => {
    switch (type) {
      case "all":
        return <TreeLocations />;
      case "facility":
        return <FacilityTable />;
      case "map":
        return <MapComponent />;
      default:
        return null;
    }
  }, [type]);

  return (
    <CustomLayout
      header={
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", gap: 35 }}>
          <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
            Locations
          </Typography>
          <div style={{ height: 36 }}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    colorBorderSecondary: "#d0d5dd",
                  },
                },
              }}
            >
              <Tabs
                className="schedulerTabs"
                activeKey={type}
                onChange={handleTabChange}
                moreIcon={null}
              >
                {tabItems.map((item) => (
                  <TabPane key={item.key} tab={item.label} />
                ))}
              </Tabs>
            </ConfigProvider>
          </div>
        </div>
      }
    >
      {renderContent}
    </CustomLayout>
  );
};

export default React.memo(Locations);