import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Input,
  Typography,
  Flex,
  Upload,
  Divider,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getInventoryList,
  getStoreListApi,
} from "../../../../services/inventory.services";

import {
  DeleteOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

const GeneralDetails = ({
  form,
  edit,
  setRefresh,
  requestDetails,
  handleBeforePopup,
  allInventories,
}) => {
  const params = useParams();
  const [allStores, setAllStores] = useState([]);

  useEffect(() => {
    fetchAllStores();
  }, []);

  const fetchAllStores = async () => {
    try {
      const res = await getStoreListApi();
      setAllStores(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  // const props = {
  //   name: "file",
  //   action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
  //   headers: {
  //     authorization: "authorization-text",
  //   },
  //   onChange(info) {
  //     if (info.file.status !== "uploading") {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === "done") {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === "error") {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  return (
    <Card
      title="Request Details"
      styles={{
        header: { backgroundColor: "#fff" },
      }}
    >
      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Flex justify="space-between" style={{ width: "100%" }} gap={12}>
            <Form.Item
              style={{ width: "50%" }}
              label="Store"
              name={"store"}
              rules={[{ required: true, message: "Please select store" }]}
            >
              <Select
                disabled={!edit}
                style={{ width: "100%" }}
                placeholder="Select store"
                options={allStores?.map((item) => ({
                  label: item?.name,
                  value: item?._id,
                }))}
              />
            </Form.Item>
          </Flex>
          <Form.Item name="status" hidden>
            <Input />
          </Form.Item>

          <Row gutter={[16, 6]} style={{ marginBottom: 6, marginTop: 10 }}>
            <Col span={params?.id ? 7 : 8}>
              <Typography.Text style={{ fontSize: 13 }}>
                Inventory
              </Typography.Text>
            </Col>
            <Col span={params?.id ? 6 : 8}>
              <Typography.Text style={{ fontSize: 13 }}>
                Description
              </Typography.Text>
            </Col>
            <Col span={params?.id ? 5 : 7}>
              <Typography.Text style={{ fontSize: 13 }}>
                Requested quantity
              </Typography.Text>
            </Col>

            {params?.id && !edit && (
              <Col span={5}>
                <Typography.Text style={{ fontSize: 13 }}>
                  Approved quantity
                </Typography.Text>
              </Col>
            )}
          </Row>
          <Form.List
            name="inventory_list"
            initialValue={[{ type: "existing" }]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  const type = form.getFieldValue([
                    "inventory_list",
                    name,
                    "type",
                  ]);

                  return (
                    <Row key={key} gutter={[16, 6]} align="baseline">
                      <Form.Item {...restField} name={[name, "type"]} hidden>
                        <Input />
                      </Form.Item>
                      <Col span={params?.id ? 7 : 8}>
                        {type === "new" ? (
                          <Form.Item
                            {...restField}
                            // label={index === 0 ? "Inventory" : ""}
                            name={[name, "inventory_text"]}
                            style={{ flex: 2 }}
                            rules={[
                              {
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.reject(
                                      new Error("Please enter inventory name")
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={!edit}
                              placeholder="Enter inventory name"
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            {...restField}
                            // label={index === 0 ? "Inventory" : ""}
                            name={[name, "inventory_select"]}
                            style={{ flex: 2 }}
                            rules={[
                              {
                                validator(_, value) {
                                  if (!value) {
                                    return Promise.reject(
                                      new Error("Please select an inventory")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Select
                              disabled={!edit}
                              allowClear
                              showSearch
                              virtual
                              placeholder="Select inventory"
                              onSelect={(value, option) => {
                                form.setFieldsValue({
                                  inventory_list: form
                                    .getFieldValue("inventory_list")
                                    .map((item, idx) =>
                                      idx === name
                                        ? {
                                            ...item,
                                            inventory_name:
                                              option?.label || undefined,
                                          }
                                        : item
                                    ),
                                });
                              }}
                              onClear={() => {
                                form.setFieldsValue({
                                  inventory_list: form
                                    .getFieldValue("inventory_list")
                                    .map((item, idx) =>
                                      idx === name
                                        ? {
                                            ...item,
                                            inventory_name: "",
                                          }
                                        : item
                                    ),
                                });
                              }}
                              options={allInventories?.map((item) => ({
                                label: item?.name,
                                value: item?._id,
                              }))}
                              filterOption={(input, option) =>
                                option?.label
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              // onChange={(value, options) => {
                              //   form.setFieldsValue({
                              //     inventory_list: form
                              //       .getFieldValue("inventory_list")
                              //       .map((item, idx) =>
                              //         idx === name
                              //           ? {
                              //               ...item,
                              //               inventory: options?.value || "",
                              //             }
                              //           : item
                              //       ),
                              //   });
                              // }}
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider style={{ margin: "8px 0" }} />
                                  <Button
                                    type="text"
                                    style={{ width: "100%" }}
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      const currentList =
                                        form.getFieldValue("inventory_list") ||
                                        [];
                                      currentList[name].type = "new";

                                      form.setFieldsValue({
                                        inventory_list: [...currentList],
                                      });

                                      form.setFieldsValue({
                                        inventory_list: form
                                          .getFieldValue("inventory_list")
                                          .map((item, idx) =>
                                            idx === name
                                              ? {
                                                  ...item,
                                                  inventory_name: undefined,
                                                  inventory_select: undefined,
                                                }
                                              : item
                                          ),
                                      });
                                    }}
                                  >
                                    Create Inventory
                                  </Button>
                                </>
                              )}
                            />
                          </Form.Item>
                        )}
                      </Col>
                      <Form.Item name={[name, "inventory_name"]}></Form.Item>
                      <Col span={params?.id ? 6 : 8}>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          // label={index === 0 ? "Description" : ""}
                          style={{ flex: 2 }}
                        >
                          <Input disabled={!edit} placeholder="Description" />
                        </Form.Item>
                      </Col>

                      <Col span={params?.id ? 5 : 7}>
                        <Form.Item
                          {...restField}
                          name={[name, "requested_quantity"]}
                          // label={index === 0 ? "Requested Quantity" : ""}
                          style={{ flex: 1 }}
                          rules={[
                            {
                              validator(_, value) {
                                if (!value) {
                                  return Promise.reject(
                                    new Error("Please enter a valid quantity")
                                  );
                                }
                                if (value.length > 16) {
                                  return Promise.reject(
                                    new Error("Please enter a valid quantity")
                                  );
                                }
                                if (!/^[0-9]+$/.test(value)) {
                                  return Promise.reject(
                                    new Error("Only numbers are allowed")
                                  );
                                }
                                if (Number(value) === 0) {
                                  return Promise.reject(
                                    new Error("Value must be greater than zero")
                                  );
                                }
                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input
                            disabled={!edit}
                            placeholder="Requested Quantity"
                          />
                        </Form.Item>
                      </Col>

                      {params?.id && !edit && (
                        <Col span={5}>
                          <Form.Item
                            {...restField}
                            name={[name, "approved_quantity"]}
                            style={{ flex: 1 }}
                            rules={[
                              {
                                validator(_, value) {
                                  if (
                                    value === null ||
                                    value === undefined ||
                                    value === ""
                                  ) {
                                    return Promise.reject(
                                      new Error("Missing approved quantity")
                                    );
                                  }
                                  if (value.toString().length > 16) {
                                    return Promise.reject(
                                      new Error("Please enter a valid quantity")
                                    );
                                  }
                                  if (!/^\d+$/.test(value.toString())) {
                                    return Promise.reject(
                                      new Error("Only numbers are allowed")
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={requestDetails?.status !== "open"}
                              placeholder="Approved Quantity"
                              onChange={(e) => {
                                handleBeforePopup();
                              }}
                            />
                          </Form.Item>
                        </Col>
                      )}

                      <Col span={1} style={{ paddingTop: 5 }}>
                        {fields.length > 1 && edit && (
                          <DeleteOutlined
                            style={{ fontSize: 15 }}
                            onClick={() => remove(name)}
                          />
                        )}
                      </Col>
                      {/* <Form.Item noStyle shouldUpdate>
                        {() => (
                          <Typography>
                            <pre>
                              {JSON.stringify(form.getFieldsValue(), null, 2)}
                            </pre>
                          </Typography>
                        )}
                      </Form.Item> */}
                    </Row>
                  );
                })}

                {edit && (
                  <Flex gap={12}>
                    <Form.Item style={{ width: "100%" }}>
                      <Button
                        type="dashed"
                        onClick={() => add({ type: "existing" })}
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Inventory
                      </Button>
                    </Form.Item>
                  </Flex>
                )}
              </>
            )}
          </Form.List>

          {/* <Form.Item name="file" label="File">
            <Upload {...props}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item> */}
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralDetails;
