import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import InventoryList from "./Inventory";
import CustomLayout from "../Layout";
import {
  Button,
  Card,
  Col,
  ConfigProvider,
  Flex,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import typoStyles from "../../assets/styles/Custom/Typography.module.css";
import CategoryList from "./categoryList";
import {
  getInventoryCategoriesListApi,
  getStoreListApi,
} from "../../services/inventory.services";
import {
  ArrowRightOutlined,
  InfoCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import StoresList from "./stores/storesList";

const Inventory = () => {
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const [viewType, setViewType] = useState("items");
  const [stores, setStores] = useState([]);
  const [selectedTab, setSelectedTab] = useState(
    stores.length > 0 ? stores[0]._id : null
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [storeList, setStoreList] = useState([]);
  const [totalInventories, setTotalInventories] = useState(0);
  const [totalStores, setTotalStores] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedInventory(null);
    setStoreList([]);
  };

  useEffect(() => {
    fetchStores();
  }, [selectedTab, refresh]);
  const fetchStores = async () => {
    try {
      const resp = await getStoreListApi();
      const storeData = resp?.data || [];
      setStores(storeData);
      setTotalStores(storeData.length);

      if (!selectedTab && storeData.length > 0) {
        setSelectedTab(storeData[0]._id);
      }
    } catch (error) {
      console.error("Error fetching stores:", error);
      setStores([]);
    }
  };
  useEffect(() => {
    if (searchParams.get("type") == "items" || !searchParams.get("type")) {
      setViewType("items");
    } else if (searchParams.get("type") == "stores") {
      setViewType("stores");
    } else {
      setViewType("categories");
    }
  }, [searchParams, setSelectedTab]);

  const tabItems = [
    {
      key: "items",
      label: `Items${
        viewType === "items" || !viewType ? ` (${totalInventories})` : ""
      }`,
    },
    {
      key: "stores",
      label: `Stores${viewType === "stores" ? ` (${totalStores})` : ""}`,
    },
    {
      key: "categories",
      label: `Categories${
        viewType === "categories" ? ` (${totalCategories})` : ""
      }`,
    },
  ];
  const tabsInventory = stores.map((store) => ({
    key: store._id,
    label:
      store.name.length > 20 ? store.name.substring(0, 20) + "..." : store.name,
    children: (
      <InventoryList
        key={store._id}
        viewType={viewType}
        allStores={stores}
        selectedStore={selectedTab}
        setTotalInventories={setTotalInventories}
        refresh={refresh}
        setRefresh={setRefresh}
      />
    ),
  }));

  return (
    <CustomLayout
      header={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 35,
            paddingRight: "30px",
          }}
        >
          <Flex gap={30}>
            <Typography className={typoStyles.h6} style={{ marginTop: 6 }}>
              Inventory
            </Typography>
            <div style={{ height: 36 }}>
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      colorBorderSecondary: "#d0d5dd",
                    },
                  },
                }}
              >
                <Tabs
                  className="schedulerTabs"
                  activeKey={viewType}
                  onChange={(e) => navigate(`/inventory?type=${e}`)}
                  moreIcon={null}
                  style={{ width: 400 }}
                >
                  {tabItems?.map((item) => (
                    <Tabs.TabPane key={item?.key} tab={item?.label} />
                  ))}
                </Tabs>
              </ConfigProvider>
            </div>
          </Flex>
          <Button
            style={{ marginLeft: 10 }}
            size="middle"
            onClick={showModal}
            icon={<SearchOutlined />}
          >
            Check Inventory Availability in Store
          </Button>
          <div></div>
          <Modal
            open={isModalOpen}
            footer={null}
            onCancel={handleCancel}
            width={1000}
            maskClosable={false}
          >
            <StoresList
              selectedInventory={selectedInventory}
              setSelectedInventory={setSelectedInventory}
              storeList={storeList}
              setStoreList={setStoreList}
            />
          </Modal>
        </div>
      }
    >
      <>
        {viewType == "categories" ? (
          <Row gutter={[24, 18]}>
            <Col span={24}>
              <CategoryList setTotalCategories={setTotalCategories} />
            </Col>
          </Row>
        ) : viewType == "stores" ? (
          <>
            {viewType == "stores" && stores?.length == 0 && (
              <Col
                span={24}
                style={{
                  width: "100%",
                  height: "95%",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Flex gap={10}>
                  <InfoCircleOutlined style={{ color: "" }} /> {""}You need to
                  create store in Organization Settings
                </Flex>
                <Button
                  type="primary"
                  style={{ marginTop: 20 }}
                  onClick={() => navigate("/org/settings")}
                >
                  Go to Settings <ArrowRightOutlined />
                </Button>
              </Col>
              // <Card>

              // </Card>
            )}

            <Col span={24}>
              <Tabs
                activeKey={selectedTab}
                onChange={(key) => setSelectedTab(key)}
                type="card"
                size="middle"
                style={{
                  marginBottom: 32,
                }}
                tabBarStyle={{
                  marginBottom: 0,
                }}
              >
                {tabsInventory.map((tab) => (
                  <Tabs.TabPane key={tab.key} tab={tab.label}>
                    {tab.children}
                  </Tabs.TabPane>
                ))}
              </Tabs>
            </Col>
          </>
        ) : (
          <InventoryList
            key={"inventory-registry"}
            viewType={viewType}
            allStores={stores}
            selectedStore={selectedTab}
            setTotalInventories={setTotalInventories}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </>
    </CustomLayout>
  );
};

export default Inventory;
