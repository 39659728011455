import { Button, Col, Form, Input, message, Row, Select, TreeSelect } from "antd";
import React, { useEffect } from "react";


import inputStyles from "../../assets/styles/Custom/Input.module.css";

const AddEditStoreModal = ({
  form,
  edit,
  selectedStore,
  setEdit,
  setIsModalOpen,
  onFinish,
  locations,
}) => {
  const handleCancel = () => {
    setEdit(false);
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
      required
        label="Store Name"
        name="name"
        rules={[
          {
            validator: (_, value, callback) => {
              if (!value) callback("Please provide store name!");
              else if (value?.trim()?.length < 3)
                return callback("Provide a name of at least 3 alphabets");
              else if (value?.trim()?.length > 100)
                return callback("Provide a name of less than 100 alphabets");
              else if (value?.trim()?.length === 0)
                return callback("Please provide store name!");
              else callback();
            },
         
          },
        ]}
      >
        <Input style={{ width: "100%" }} placeholder="Store Name" />
      </Form.Item>
      <Form.Item
        label="Location"
        name="location"
        rules={[
          {
            required: true,
            message: "Please select store location!",
          },
        ]}
      >
         <TreeSelect
            placeholder="Location"
            style={{
              width: "100%",
            }}
            showSearch
            className={inputStyles.smSelect}
            treeData={Object.keys(locations)?.length > 0 && [locations]}
          />
      </Form.Item>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          type="default"
          htmlType="button"
          style={{ marginRight: 10 }}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default AddEditStoreModal;
