import React, { useContext, useEffect, useState } from "react";
import CustomLayout from "../Layout";
import OrgSettingsHeader from "./OrgSettingsHeader";
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
  message,
  Table,
  Tag,
  Input,
  Drawer,
  List,
} from "antd";
import btnStyles from "../../assets/styles/Custom/Button.module.css";
import { UserContext } from "../../context/UserProvider";
import {
  getOrganisationApi,
  getTaskTypeApi,
  createTaskTypeApi,
  updateTaskTypeApi,
  deleteTaskTypeApi,
  updateOrganisationApi,
} from "../../services/organisation.services";

import switchStyles from "../../assets/styles/Custom/Switch.module.css";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { getMetaDataApi } from "../../services/users.services";
import { getCookie } from "../../helpers/localStorage";
import { ORGANISATION } from "../../constants/defaultKeys";
import { customInputValidator, tableComponents } from "../../helpers/utility";
import {
  addStoreApi,
  getStoreListApi,
  updateStoreApi,
} from "../../services/inventory.services";
import AddEditStoreModal from "./addEditStoreModal";
import { getAllAssetsWithLocsApi } from "../../services/assets.services";
const OrgSettings = () => {
  const [updating, setUpdating] = useState(false);
  const [details, setDetails] = useState({});
  const [editTaskType, setEditTaskType] = useState({
    name: "",
    issueCodes: [],
  });
  const [showDrawer, setShowDrawer] = useState(false);
  const { userDetails, setUserDetails } = useContext(UserContext);
  const [form] = Form.useForm();
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
  const [taskTypeDetails, setTaskTypeDetails] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [locations, setLocations] = useState([]);
  const [scheduleTypes, setScheduleTypes] = useState([]);
  const [editScheduleType, setEditScheduleType] = useState(null);
  useEffect(() => {
    fetchStoreList();
  }, []);

  const fetchStoreList = async () => {
    try {
      const response = await getStoreListApi();
      if (response) {
        setStoreList(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedKeys((prev) => [...prev, record._id]);
    } else {
      setExpandedKeys((prev) => prev.filter((_id) => _id !== record._id));
    }
  };

  const getUserData = async () => {
    try {
      let resp = await getMetaDataApi();
      const orgId = getCookie(ORGANISATION);
      const activeOrg = orgId
        ? resp?.data?.organizations?.filter(
            (each) => each?.organization?._id == orgId
          )
        : {};
      setUserDetails((prev) => ({
        ...prev,
        activeOrg: activeOrg[0],
        organizations: resp?.data?.organizations,
      }));
    } catch (error) {}
  };
  const onClose = () => {
    setShowDrawer(false);
  };

  const onFinishTaskType = async (values) => {
    try {
      const res = await createTaskTypeApi({
        ...values,
        name: values?.name?.trim(),
      });
      message.success(res?.data?.message);
      getTaskType();
    } catch (error) {
      message.error(error?.response?.data?.message);
      setModalVisible(false);
    } finally {
      form1.resetFields();
      setModalVisible(false);
    }
  };

  const onFinishScheduleType = async (values) => {
    try {
      if (!editScheduleType) {
        const res = await createTaskTypeApi({
          ...values,
          category: "schedule",
          name: values?.name?.trim(),
        });
        message.success(res?.data?.message);
        getScheduleTypes();
      } else {
        const res = await updateTaskTypeApi(editScheduleType?._id, {
          ...values,
          name: values?.name?.trim(),
        });
        message.success(res?.data?.message);
        getScheduleTypes();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      form1.resetFields();
      setScheduleModalVisible(false);
    }
  };
  const handleDeleteTaskType = async (id) => {
    try {
      const res = await deleteTaskTypeApi(id);
      getTaskType();
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const handleDeleteIssueCode = async (id) => {
    try {
      const payload = {
        name: editTaskType?.name,
        issueCodes: editTaskType?.issueCodes
          ?.filter((item) => item?._id == id)
          .map(({ _id, ...rest }) => ({
            issueCodeId: _id,
            ...rest,
            status: "remove",
          })),
      };
      const res = await updateTaskTypeApi(editTaskType?._id, payload);
      getTaskType();
      message.success(res?.data?.message);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const getDetails = async () => {
    try {
      const resp = await getOrganisationApi();
      setDetails(resp?.data?.settings);
      form.setFieldsValue({
        inventory_module: resp?.data?.settings?.inventory_module ? true : false,
        // track_spare_parts: resp?.data?.settings?.track_spare_parts
        //   ? true
        //   : false,
        qc_verification: resp?.data?.settings?.qc_verification ? true : false,
        face_login: resp?.data?.settings?.face_login ? true : false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getTaskType = async () => {
    try {
      let query = { category: "all" };
      const resp = await getTaskTypeApi(query);
      setTaskTypeDetails(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getScheduleTypes = async () => {
    try {
      let query = { category: "schedule" };
      const resp = await getTaskTypeApi(query);
      setScheduleTypes(resp?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTaskType();
    getScheduleTypes();
    getDetails();
  }, []);

  const handleOnFinish = async (values) => {
    try {
      const existingIssueCodes = editTaskType?.issueCodes || [];

      const issueCodesPayload = values?.issueCodes.map((issue) => {
        const existingIssue = existingIssueCodes.find(
          (e) => e._id === issue._id
        );

        if (!existingIssue) {
          return { ...issue, status: "add" };
        } else if (
          existingIssue.code !== issue.code ||
          existingIssue.description !== issue.description
        ) {
          return { issueCodeId: issue._id, ...issue, status: "update" };
        }
        return issue;
      });

      const issueCodes = issueCodesPayload
        .filter((item) => item?.status)
        .map(({ _id, ...rest }) => rest);

      const payload = {
        name: editTaskType?.name,
        issueCodes: issueCodes,
      };

      // Send API request
      const res = await updateTaskTypeApi(editTaskType._id, payload);
      message.success(res?.data?.message);
      getTaskType();
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      form.resetFields();
      setShowDrawer(false);
    }
  };

  const onFinish = async (values) => {
    let payload = {
      settings: {
        inventory_module: values?.inventory_module,
        // track_spare_parts: values?.track_spare_parts,
        qc_verification: values?.qc_verification,
        face_login: values?.face_login,
      },
    };
    try {
      const resp = await updateOrganisationApi(payload);
      if (resp) {
        message.success(resp?.data?.message);
        await getUserData();
        await getDetails();
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    } finally {
      setUpdating(false);
    }
  };

  const onFinishStore = async (values) => {
    try {
      if (editStore && selectedStore) {
        await updateStoreApi(selectedStore?._id, values);
        message.success("Store updated successfully");
      } else {
        await addStoreApi(values);
        message.success("Store added successfully");
      }
      fetchStoreList();
    } catch (error) {
      console.log(error);
      message.error(error?.response?.data?.message);
    } finally {
      setIsModalOpen(false);
      setEditStore(false);
      form2.resetFields();
    }
  };
  const transformData = (data) => {
    if (!data) return {};

    const transformNode = (node) => ({
      value: node._id,
      title: node.name,
      disabled: node?.disabled,
      children: node.children?.map(transformNode) || [],
    });

    return transformNode(data);
  };

  const fetchLocations = async () => {
    try {
      const resp = await getAllAssetsWithLocsApi();
      setLocations(transformData(resp?.data?.locations?.[0]));
    } catch (error) {
      setLocations({});
    }
  };

  const showModal = (store = null) => {
    setSelectedStore(store);
    setEditStore(!!store);

    if (store) {
      form2.setFieldsValue({
        name: store?.name,
        location: store?.location?._id,
      });
    } else {
      form2.resetFields();
    }

    fetchLocations();
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setEditStore(false);
    setIsModalOpen(false);
    form2.resetFields();
  };

  const scheduleTableColumns = [
    {
      title: "Schedule type",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    ...(userDetails?.activeOrg?.role === "admin"
      ? [
          {
            title: <Typography className={"tableHeader"}>Actions</Typography>,
            dataIndex: "action",
            key: "action",

            render: (_, record) => (
              <Flex gap={12} align="center" justify="start">
                {/* <Popconfirm
                  icon={false}
                  description="Are you sure to delete this task type?"
                  onConfirm={(e) => {
                    handleDeleteTaskType(record?._id);
                    e.stopPropagation();
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm> */}
                <EditOutlined
                  onClick={() => {
                    setEditScheduleType(record);
                    form1.setFieldsValue({
                      name: record?.name,
                    });
                    setScheduleModalVisible(true);
                  }}
                />
              </Flex>
            ),
          },
        ]
      : []),
  ];
  const tableColumns = [
    {
      title: "WO type",
      dataIndex: "name",
      key: "name",
      fixed: "left",
    },
    {
      title:"Issue Codes",
      dataIndex:"issueCodes",
      key:"issueCodes",
      width:400,
      render: (val) => {
        return val?.map((item) => {
          return( 
            <Flex style={{marginTop:10}}> 
              <Tag  color="blue">{item?.code}</Tag>
              <Typography>{item?.description}</Typography>
            </Flex>
          )
        })
      },
    },

    ...(userDetails?.activeOrg?.role === "admin"
      ? [
          {
            title: <Typography className={"tableHeader"}>Actions</Typography>,
            dataIndex: "action",
            key: "action",

            render: (_, record) => (
              <Flex gap={12} align="center" justify="start">
                {/* <Popconfirm
                  icon={false}
                  description="Are you sure to delete this task type?"
                  onConfirm={(e) => {
                    handleDeleteTaskType(record?._id);
                    e.stopPropagation();
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm> */}
                <EditOutlined
                  onClick={() => {
                    setEditTaskType(record);
                    setShowDrawer(true);
                  }}
                />
              </Flex>
            ),
          },
        ]
      : []),
  ];

  const storeTableColumns = [
    {
      title: "Store name",
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (val) => {
        if (!val) return "-";
        return (
          <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
            {val}
          </div>
        );
      },
    },

    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 180,
      render: (val) => {
        return val ? val?.name : "-";
      },
    },
    // {
    //   title : "Inventory count",
    //   children : [
    //     {
    //       title:"High value",
    //       dataIndex:"highValuedInventoryCount",
    //       key:"highValuedInventoryCount",
    //       width:110,
    //       render: (val) => {
    //         return val ? val : 0;
    //       },
    //     },
    //     {
    //       title:"Low value",
    //       dataIndex:"lowValuedInventoryCount",
    //       key:"lowValuedInventoryCount",
    //       width:110,

    //       render: (val) => {
    //         return val ? val : 0;
    //       },
    //     }
    //   ]
    // },

    // {
    //   title: "Total inventory cost",
    //   dataIndex: "totalInventoryCost",
    //   key: "totalInventoryCost",
    //   width:160,
    //   render: (val) => {
    //     return val ? `₹ ${new Intl.NumberFormat("en-IN", { maximumFractionDigits: 2 }).format(val)}` : "₹ 0";
    //   },
    // },

    ...(userDetails?.activeOrg?.role === "admin"
      ? [
          {
            title: <Typography className={"tableHeader"}>Actions</Typography>,
            dataIndex: "action",
            key: "action",
            fixed: "right",
            width: 100,

            render: (_, record) => (
              <Flex gap={12} align="center" justify="start">
                {/* <Popconfirm
                  icon={false}
                  description="Are you sure to delete this task type?"
                  onConfirm={(e) => {
                    handleDeleteTaskType(record?._id);
                    e.stopPropagation();
                  }}
                  onCancel={(e) => e.stopPropagation()}
                >
                  <DeleteOutlined />
                </Popconfirm> */}
                <EditOutlined onClick={() => showModal(record)} />
              </Flex>
            ),
          },
        ]
      : []),
  ];

  return (
    <CustomLayout
      header={<OrgSettingsHeader />}
     
    >
      <Row gutter={[16, 32]}>
        <Col span={14}>
          <Table
            rowKey="_id"
            title={() => (
              <Space
                align="center"
                style={{ width: "100%", justifyContent: "space-between", gap: 16,  }}
              >
                <Typography.Title level={5} style={{fontWeight:500}}>
                  WO Types
                </Typography.Title>
                {userDetails?.activeOrg?.role === "admin" && (
                  <Button
                    id="create-task-type"
                    type="primary"
                    className={btnStyles.mdBtn}
                    onClick={() => setModalVisible(true)}
                  >
                    + Add Task Type
                  </Button>
                )}
              </Space>
            )}
            pagination={false}
            bordered
            // scroll={{
            //   y: 55 * 5,
            // }}
            columns={tableColumns}
            dataSource={taskTypeDetails}
            // expandable={{
            //   expandedRowRender: (record) => (
            //     <List
            //       size="default"
            //       dataSource={record?.issueCodes || []}
            //       renderItem={(item) => (
            //         <List.Item style={{ paddingLeft: 50, textAlign: "left" }}>
            //           <Space>
            //             <Tag color="blue">{item?.code}</Tag>
            //             <Typography>{item?.description}</Typography>
            //           </Space>
            //         </List.Item>
            //       )}
            //     />
            //   ),
            //   rowExpandable: (record) => Boolean(record?.issueCodes?.length),
            //   expandedRowKeys: expandedKeys,
            //   onExpand: handleExpand,
            // }}
            components={tableComponents}
            style={{ marginBottom: 20 }}
          />
          <Table
            rowKey="_id"
            title={() => (
              <Space
                align="center"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  gap: 16,
                  
                }}
              >
                <Typography.Title level={5} style={{fontWeight:500
                }}>
                  Schedule Types
                </Typography.Title>
                {userDetails?.activeOrg?.role === "admin" && (
                  <Button
                    id="create-task-type"
                    type="primary"
                    className={btnStyles.mdBtn}
                    onClick={() => {
                      setScheduleModalVisible(true);
                    }}
                  >
                    + Add Schedule Type
                  </Button>
                )}
              </Space>
            )}
            columns={scheduleTableColumns}
            dataSource={scheduleTypes}
            pagination={false}
            components={tableComponents}
            bordered
          />
        </Col>

        <Col span={10}>
          <Card
            title="Advanced Settings"
            styles={{
              header: { backgroundColor: "#fff" },
            }}
            style={{marginBottom: 20}}
            extra={
              updating && (
                <Flex justify="flex-end" gap={12}>
                  <Button
                    className={btnStyles.mdBtn}
                    onClick={() => {
                      form.resetFields();
                      setUpdating(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={btnStyles.mdBtn}
                    type="primary"
                    onClick={form.submit}
                  >
                    Update
                  </Button>
                </Flex>
              )
            }
            
          >
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              disabled={userDetails?.activeOrg?.role != "admin"}
              initialValues={details}
              onValuesChange={() => setUpdating(true)}
              onFinish={onFinish}
              style={{
                pointerEvents:
                  userDetails?.activeOrg?.role == "admin" ? "auto" : "none",
              }}
            >
              <Space style={{ width: "100%" }}>
                <Form.Item
                  name="qc_verification"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch className={switchStyles.basic} />
                </Form.Item>
                <Typography>Enable QC Verification</Typography>
              </Space>
              <Space style={{ width: "100%", marginTop: 12 }}>
                <Form.Item
                  name="face_login"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch className={switchStyles.basic} />
                </Form.Item>
                <Typography>Face Login</Typography>
              </Space>
              <Space style={{ width: "100%", marginTop: 12 }}>
              <Form.Item
                name="inventory_module"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Switch className={switchStyles.basic} />
              </Form.Item>
              <Typography>Enable Inventory Module</Typography>
            </Space>
            </Form>
          </Card>

       
            
            {/* <Space style={{ width: "100%", marginTop: 12 }}>
                <Form.Item
                  name="track_spare_parts"
                  valuePropName="checked"
                  style={{ marginBottom: 0 }}
                >
                  <Switch className={switchStyles.basic} />
                </Form.Item>
                <Typography>Enable Tracking of Spare Parts</Typography>
              </Space> */}

            {details?.inventory_module && (
              <Table
                bordered
                pagination={false}
                // scroll={{
                //   y: 55 * 5,
                // }}
                style={{ marginTop: 15 }}
                title={() => (
                  <Space
                    align="center"
                    style={{
                      width: "100%",  
                      justifyContent: "space-between",
                      gap: 16,
                      
                    }}
                  >
                    <Typography.Title level={5} style={{fontWeight:500}}>Inventory Stores</Typography.Title>
                    {userDetails?.activeOrg?.role === "admin" && (
                      <Button
                        size="small"
                        id="create-task-type"
                        type="primary"
                        className={btnStyles.mdBtn}
                        onClick={() => {
                          showModal();
                        }}
                      >
                        + Add Store
                      </Button>
                    )}
                  </Space>
                )}
                dataSource={storeList}
                columns={storeTableColumns}
                components={tableComponents}
              />
            )}
            <Modal
              open={isModalOpen}
              title={editStore ? "Edit store" : "Add store"}
              onCancel={handleCancel}
              footer={null}
            >
              <AddEditStoreModal
                form={form2}
                edit={editStore}
                selectedStore={selectedStore}
                setEdit={setEditStore}
                setIsModalOpen={setIsModalOpen}
                onFinish={onFinishStore}
                locations={locations}
              />
            </Modal>

        </Col>


      </Row>

      {scheduleModalVisible && (
        <Modal
          title={`${editScheduleType ? "Edit" : "Add"} Schedule Type`}
          open={scheduleModalVisible}
          onCancel={() => {
            setScheduleModalVisible(false);
            form1.resetFields();
            setEditScheduleType(null);
          }}
          footer={null}
        >
          <Form
            form={form1}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinishScheduleType}
          >
            <Form.Item
              name={"name"}
              style={{ marginBottom: "0px" }}
              rules={customInputValidator("Schedule type", 3, 40, true)}
              // rules={[
              //   {
              //     required: true,
              //     message: "Task type is required!",
              //   },
              //   {
              //     min: 3,
              //     message: "Task type must be at least 3 characters long!",
              //   },
              //   {
              //     max: 30,
              //     message: "Task type cannot exceed 30 characters!",
              //   },
              // ]}
            >
              <Input size="medium" placeholder="Enter schedule type" />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "right", marginBottom: "0px", marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {modalVisible && (
        <Modal
          title={`Add Task Type`}
          open={modalVisible}
          onCancel={() => {
            setModalVisible(false);
            form1.resetFields();
          }}
          footer={null}
        >
          <Form
            form={form1}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinishTaskType}
          >
            <Form.Item
              name={"name"}
              style={{ marginBottom: "0px" }}
              rules={customInputValidator("Task type", 3, 30, true)}
              // rules={[
              //   {
              //     required: true,
              //     message: "Task type is required!",
              //   },
              //   {
              //     min: 3,
              //     message: "Task type must be at least 3 characters long!",
              //   },
              //   {
              //     max: 30,
              //     message: "Task type cannot exceed 30 characters!",
              //   },
              // ]}
            >
              <Input size="medium" placeholder="Enter task type" />
            </Form.Item>
            <Form.Item
              style={{ textAlign: "right", marginBottom: "0px", marginTop: 20 }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {showDrawer && (
        <Drawer
          title="Edit Task Type"
          placement="right"
          width={500}
          onClose={onClose}
          open={showDrawer}
          footer={
            <div style={{ textAlign: "right" }}>
              <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button type="primary" htmlType="submit" form="editTaskForm">
                  Submit
                </Button>
              </Space>
            </div>
          }
          footerStyle={{ padding: "10px 24px" }}
        >
          <Form
            id="editTaskForm"
            name="dynamic_form_nest_item"
            onFinish={handleOnFinish}
            style={{ maxWidth: 600, padding: "0 20px" }}
            autoComplete="off"
            initialValues={{ issueCodes: editTaskType?.issueCodes || [] }}
          >
            <Form.Item
              name={"name"}
              initialValue={editTaskType?.name}
              rules={[
                {
                  required: true,
                  message: "Task type is required!",
                },
                {
                  min: 3,
                  message: "Task type must be at least 3 characters long!",
                },
                {
                  max: 30,
                  message: "Task type cannot exceed 30 characters!",
                },
              ]}
            >
              <Input
                size="large"
                label="Task Type"
                name="name"
                placeholder="Enter task type"
                onChange={(e) =>
                  setEditTaskType({ ...editTaskType, name: e.target.value })
                }
              />
            </Form.Item>
            <Space style={{ marginBottom: 10 }}>
              <Typography style={{ fontWeight: "bold" }}>
                Issue codes
              </Typography>
            </Space>
            <Form.List name="issueCodes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    const issueCodeId = editTaskType?.issueCodes[key]?._id;

                    return (
                      <Space
                        key={key}
                        style={{ display: "flex", marginBottom: 8 }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "code"]}
                          rules={[
                            {
                              required: true,
                              message: "Please provide issue code!",
                            },
                            {
                              min: 3,
                              message:
                                "Issue code must be at least 3 characters long!",
                            },
                            {
                              max: 100,
                              message:
                                "Issue code cannot exceed 100 characters!",
                            },
                          ]}
                        >
                          <Input placeholder="Issue code" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "description"]}
                          rules={[
                            {
                              max: 500,
                              message:
                                "Description cannot exceed 500 characters!",
                            },
                          ]}
                        >
                          <Input placeholder="Description" />
                        </Form.Item>
                        <DeleteOutlined
                          onClick={() => {
                            if (issueCodeId) {
                              handleDeleteIssueCode(issueCodeId);
                            }
                            remove(key);
                          }}
                        />
                      </Space>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add issue codes
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form>
        </Drawer>
      )}
    </CustomLayout>
  );
};

export default OrgSettings;
